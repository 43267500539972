import React, { useContext, useEffect } from 'react';
import utils from '../../../utils/utils.module.scss';
import cx from '../../../utils/classnames';
import { TranslationContext } from '../../../context/translations-context';
import { useAmplitude } from '../../../hooks/useAmplitude';

interface Props {
  title: string;
  text?: string;
  width?: string;
  height?: string;
  children: any;
  doClose?(): void;
  className?: string;
}
interface ChildProps {
  children: React.ReactElement[] | React.ReactElement;
}
export const Body = ({ children }: ChildProps) => <>{children}</>;
export const Buttons = ({ children }: ChildProps) => (
  <div className={utils.modalButtons}>
    <div style={{ order: 0 }} />
    {children}
  </div>
);

export const ModalDialog = ({ title, width, height, doClose, children, className }: Props) => {

const [logAmplitudeEvent] = useAmplitude();
const [translation] = useContext(TranslationContext);

useEffect(() => {
  logAmplitudeEvent('modal viewed', translation,{
    modalName: title,
  });
}, [ title]);

  return (
    <>
      <div className={utils.overlay}></div>
      <div className={utils.overlayPopupContainer}>
        <div className={cx(utils.overlayPopup, className)} style={{ width: width, height: height }}>
            <div className={utils.overlayTitle}>
              {title}
              {doClose && <span onClick={doClose}>x</span>}
              </div>
            <div className={utils.overlayCopy}>
            <div className={utils.modalBody}>{children}</div>
          </div>
        </div>
      </div>
    </>
  );
};
