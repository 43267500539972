import { SEARCH_STATE } from '../../components/sections/member-search/member-search';
import { APIHelper } from '../../models/api-helper';
import { post } from '../generic-service';

export default class SearchService {
  public async request(searchTerm: string, searchOption: string, apiHelper: APIHelper) {
    const data = { userName: '', memberId: '', phoneNumber: '', vin: '' };

    if (searchOption == SEARCH_STATE.PHONE) data.phoneNumber = searchTerm;
    if (searchOption == SEARCH_STATE.EMAIL) data.userName = searchTerm;
    if (searchOption == SEARCH_STATE.VIN) data.vin = searchTerm;
    if (searchOption == SEARCH_STATE.MEMBER) data.memberId = searchTerm;

    return await post('SEARCH', null, {}, data, null, apiHelper);
  }
}
