import React, { useContext, useEffect, useState } from 'react';
import { TranslationContext } from '../../../context/translations-context';
import styles from './connected-services-link.module.scss';

import { ModalDialog, Body } from '../../common/modal-dialog/modal-dialog';
import { QRCodeComponent } from '../../common/qr-code/qr-code';
import { ConnectedServicesContext } from '../../../context/connected-services-context';
import { SiteContext } from '../../../context/site-context';
import { InputWithLabel } from '../../common/Input-with-label/input-with-label';
import { CtaButton } from '../../common/cta/cta';
import SmsService from '../../../services/sms-service/sms-service';
import { APIHelperContext } from '../../../context/api-helper-context';
import { CustomerContext } from '../../../context/customer-context';
import { AlertDialogContext } from '../../../context/alert-dialog-context';
import { AuthContext } from '../../../context/auth-context';
import amplitude from 'amplitude-js';

interface Props {
  doClose?(): void;
  offer: any;
}

export const ConnectedServiceLinkDialog = ({ offer, doClose }: Props) => {
  const [{ t }] = useContext(TranslationContext);
  const [{ vehicle, selectedStarsId }] = useContext(ConnectedServicesContext);
  const [sms, setSms] = useState('');
  const [apiHelper] = useContext(APIHelperContext);
  const [{ userProfile }] = useContext(CustomerContext);
  const [{ setShowAlert }] = useContext(AlertDialogContext);
  const [{ isCanada, siteCode }] = useContext(SiteContext);
  const [{ language }] = useContext(TranslationContext);
  const { isLocal, isQA } = useContext(AuthContext);

  useEffect(() => {
    if (userProfile.phoneNumber) {
      setSms(userProfile.phoneNumber);
    }
  }, [userProfile]);

  const formatPhone = (phoneNumber: string) =>
    phoneNumber ? (phoneNumber.slice(0, 3) + '-' + phoneNumber.slice(3, 6) + '-' + phoneNumber.slice(6)).replace(/-+$/, '') : '';

  //en-US
  // https://www.ford.com/support/fordpass/app/connected-services?vin={VIN}&classification={Classification}

  // en-CA
  // https://www.ford.ca/myaccount/connected-services?vin={VIN}&classification={Classification}

  // fr-CA
  // https://fr.ford.ca/myaccount/connected-services?vin={VIN}&classification={Classification}

  // en-US
  // https://www.lincoln.com/myaccount/connected-services?vin={VIN}&classification={Classification}

  // en-CA
  // https://www.lincolncanada.com/myaccount/connected-services?vin={VIN}&classification={Classification}

  // fr-CA
  // https://fr.lincolncanada.com/myaccount/connected-services?vin={VIN}&classification={Classification}

  // QA
  // en-US
  // https://wwwperf.lincoln.com/myaccount/connected-services?vin={VIN}&classification={Classification}

  // en-CA
  // https://wwwperf.lincolncanada.com/myaccount/connected-services?vin={VIN}&classification={Classification}

  // fr-CA
  // https://frperf.lincolncanada.com/myaccount/connected-services?vin={VIN}&classification={Classification}


  let make;
  if (isCanada()) {
    make = vehicle.make == 'Lincoln' ? 'lincolncanada.com' : 'ford.ca';
  } else {
    make = vehicle.make.toLowerCase() + '.com';
  }

  let url;
  if (vehicle.make == 'Ford' && !isCanada() && language == 'en') {
    url = `https://www${isLocal || isQA ? 'qa' : ''}.ford.com/support/fordpass/app/connected-services?vin=${vehicle.vin}&classification=${offer.classification}&paCode=${siteCode}&starsId=${selectedStarsId}`
  } else {
    url = `https://${language == 'fr' ? 'fr' : 'www'}${isLocal || isQA ? 'qa' : ''}.${make}/myaccount/connected-services?vin=${
    vehicle.vin}&classification=${offer.classification}&paCode=${siteCode}&starsId=${selectedStarsId}`;
  }


  const smsDisclaimer = t('SMS_DISCLAIMER');
  const sendSMS = () => {
    amplitude.getInstance().logEvent('pfs SSP sms', {
      vin: vehicle.vin,
      classification: offer.classification,
      paCode: siteCode,
      starsId: selectedStarsId,
      productSku: offer.productSku,
    });

    const smsService = new SmsService();
    smsService
      .request(
        {
          text: `${t('SSPactivate').replace('make', vehicle.make)} ${offer.name}: ${url.replace('make', vehicle.make)} ${t('MSG_DATA_DSCLMR').replace(
            '{make}',
            vehicle.make
          )}`,
          toNumber: sms,
        },
        apiHelper
      )
      .then(response => {
        doClose();
        if (response.status !== 'queued') {
          setShowAlert({ title: t('SMS_FAIL'), message: t('MSG_NOT_SENT') });
        } else {
          setShowAlert({ title: t('SUCCESS'), message: t('MSG_SENT') });
        }
      });
  };

  return (
    <ModalDialog title={t('Customer Activation')} doClose={doClose} className={styles.modal}>
      <Body>
        {!isCanada() && (
          <>
            <div className={styles.subtext}>{t('SMS_CONFIRM').replace('{name}', offer.name)}</div>
            <div className={styles.smsContainer}>
              <InputWithLabel
                label={t('Send via SMS')}
                id='sms'
                onChange={(e: any) => setSms(e.target.value.replace(/\D/g, '').substr(0, 10))}
                maxLength={12}
                minLength={12}
                value={formatPhone(sms)}
              />
              <CtaButton className={styles.smsBtn} label={t('Send Link')} onClick={sendSMS} disabled={sms.length != 10} />
            </div>
            <div className={styles.disclaimer} dangerouslySetInnerHTML={{ __html: smsDisclaimer }} />
            <div className={styles.or}>Or</div>
          </>
        )}
        <div className={styles.subtext}>{t('SSP_LINK').replace('{name}', offer.name)}</div>
        <div className={styles.QR}>
          <QRCodeComponent name={url} />
        </div>
      </Body>
    </ModalDialog>
  );
};
