import React from "react";
import QRCode from "react-qr-code";
import styles from './qr-code.module.scss'


export const QRCodeComponent = ({qrSize = 200, qrBg = "white", qrFg = "black", name = "www.ford.com", title = ""}) => {
    return(
        <div>
            <div className={styles.title}>{title}</div>
            <QRCode
                size={qrSize}
                bgColor={qrBg}
                fgColor={qrFg}
                value={name}
                />
        </div>
    )
}
