import React, { useState, useContext, useEffect } from 'react';
import cx from '../../../utils/classnames';
import { CustomerContext } from '../../../context/customer-context';
import utils from '../../../utils/utils.module.scss';
import styles from './member-search.module.scss';
import { ActivityIndicatorSmall } from '../../common/activity-indicator/activity-indicator';
import { ErrorText } from '../../common/error-text/error-text';
import { FontAwesome } from '../../common/font-awesome/font-awesome';
import { InputWithLabel } from '../../common/Input-with-label/input-with-label';
import { ShoppingCartContext } from '../../../context/shopping-cart-context';
import { TranslationContext } from '../../../context/translations-context';
import { SiteContext } from '../../../context/site-context';
import { ChooseCustomerDialog } from '../../dialogs/choose-customer-dialog/choose-customer-dialog';
import { ConnectedServicesContext } from '../../../context/connected-services-context';
import { VinContext } from '../../../context/vin-lookup-context';

interface Props {
  className?: string;
  doSearch(term: string, spinner: boolean, searchOption: string): boolean;
  searchType?: string;
  updateVinSearch: () => void;
}

export enum SEARCH_STATE {
  MEMBER = 'MEMBER_ID',
  VIN = 'VIN',
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
}

export const formatPhone = (phoneNumber: string) => {
  const num = phoneNumber?.replace(/\D/g, '') || '';

  // eslint-disable-next-line no-useless-escape
  return ('(' + num.slice(0, 3) + ') ' + num.slice(3, 6) + '-' + num.slice(6))
    .replace(/-$/, '')
    .replace(/ $/, '')
    .replace(/\)$/, '')
    .replace(/\($/, '')
    .substr(0, 14);
};

export const MemberSearch = ({ className, doSearch, updateVinSearch }: Props) => {
  const [{ t, language }] = useContext(TranslationContext);
  const [searchTerm, setSearchTerm] = useState('');
  const [showOptions, setShowOptions] = useState(false);
  const [{ clearConnectedServices }] = useContext(ConnectedServicesContext);
  const [{ clearVinConnectedServices }] = useContext(VinContext);
  const [{ loading, notFound, searchOption }, { clearSearch, setNotFound, setSearchOption }] = useContext(CustomerContext);

  // eslint-disable-next-line no-empty-pattern
  const [{}, { clearCart }] = useContext(ShoppingCartContext);
  const [{ siteCode }] = useContext(SiteContext);

  const searchOptionDefs = [
    { id: SEARCH_STATE.MEMBER, label: t('MEMBERID_EMAIL_USERNAME'), btn: t('Search') },
    { id: SEARCH_STATE.PHONE, label: t('PHONE_NUMBER'), btn: t('Search') },
  ];

  useEffect(() => {
    function handleClickOutside() {
      setShowOptions(false);
    }
    if (showOptions) {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [showOptions]);

  useEffect(() => {
    if (searchTerm == '') {
      clearSearch();
    }
  }, [searchTerm]);

  const currentOption = searchOptionDefs.find(o => o.id == searchOption) || searchOptionDefs[0];

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        clearSearch();
        clearConnectedServices();
        clearVinConnectedServices();
        clearCart();
        doSearch((searchOption == SEARCH_STATE.PHONE ? searchTerm?.trim().replace(/\D/g, '') : searchTerm?.trim()), true, searchOption);
        updateVinSearch();
      }}
    >
      <FontAwesome icon='magnifying-glass' className={styles.searchIcon} />

      <InputWithLabel
        label={currentOption.label}
        id='membersearch'
        value={searchOption == SEARCH_STATE.PHONE ? formatPhone(searchTerm) : searchTerm}
        onChange={(e: any) => setSearchTerm(e.target.value.trim())}
        className={cx(utils.searchInput, className, { [utils.inputError]: notFound, [styles.french]: language == 'fr-CA' })}
      />

      {searchTerm && <FontAwesome icon='circle-xmark' className={styles.searchCancelIcon} onClick={() => setSearchTerm('')} />}

      {notFound == true && <ErrorText text={t("The {{label}} entered doesn't exist").replace('{{label}}', t(searchOption))} />}

      {Array.isArray(notFound) && <ChooseCustomerDialog customers={notFound} doClose={() => setNotFound(false)} />}

      {loading ? (
        <div className={styles.activityIndicator}>
          <ActivityIndicatorSmall />
        </div>
      ) : (
        <>
          <div style={{ display: 'flex' }}>
            <div style={{ flexGrow: 7 }}>
              <button type='submit' className={cx(utils.buttonPrimary, { [utils.buttonDisabled]: !siteCode }, styles.searchBtn)} disabled={!siteCode}>
                {currentOption.btn || currentOption.label}
              </button>
            </div>

            <div style={{ flexGrow: 0 }}>
              <div
                onClick={e => {
                  e.preventDefault();
                  setShowOptions(true);
                }}
                className={cx(styles.menuWrapper, utils.menuWrapper)}
              >
                <button className={cx(utils.buttonPrimary, styles.hamburger)}>
                  <FontAwesome icon='angle-down' />
                </button>
                {showOptions && (
                  <div className={cx(styles.option, utils.menu)}>
                    {searchOptionDefs.map((option: any) => (
                      <div
                        key={option.id}
                        onMouseDown={() => {
                          clearSearch();
                          clearConnectedServices();
                          setSearchTerm('');
                          setSearchOption(option.id);
                        }}
                      >
                        {searchOption == option.id ? <FontAwesome icon='check' /> : <b />}
                        {option.label}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </form>
  );
};
