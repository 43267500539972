import React, { useContext, useState } from 'react';
import { doValidateVin } from '../components/common/vin-input/vin-input';
import { REWARD_CHOICE } from '../components/sections/choice-purchase-reward/choice-purchase-reward';
import { TranslationContext } from './translations-context';

export const InputsContext = React.createContext<any>({
  rewardProgram: null,
});

export enum REWARD_CHOICE_DEFAULTED {
  UNKNOWN = 'unknown',
  BLANK = 'blank',
  SETBYDEALER = 'dealer',
  SWITCH_PMP = 'pmp',
  DEFAULTPOINTS = 'default',
  LOADING = 'loading',
}

interface Props {
  children: React.ReactNode;
}
export const InputsContextProvider = ({ children }: Props) => {
  const [{ t }] = useContext(TranslationContext);

  const [rewardChoice, setRewardChoice] = useState('points');
  const [rewardChoiceDefault, setRewardChoiceDefault] = useState(REWARD_CHOICE_DEFAULTED.BLANK);
  const [rewardChoiceDefaultPoints, setRewardChoiceDefaultPoints] = useState(0);
  const [nonFordVin, setNonFordVin] = useState(false);
  const [saleType, setSaleType] = useState('VehicleNew');
  const [invoiceAmount, setInvoiceAmount] = useState(0);
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [saleDate, setSaleDate] = useState<Date>();
  const [vin, setVin] = useState('');

  const validateInvoice = () => (invoiceNumber == '' || invoiceNumber?.length >= 5 ? null : t('5 to 8 characters required.'));
  const validateVin = () => (invoiceNumber == '' || doValidateVin(vin) ? null : t('Valid 17 character VIN required.'));

  const clearInputs = () => {
    setInvoiceNumber('');
    setInvoiceAmount(0);
    setVin('');
    setNonFordVin(false);
    setRewardChoiceDefault(REWARD_CHOICE_DEFAULTED.UNKNOWN);
    setRewardChoice(REWARD_CHOICE.POINTS);
    setSaleDate(null);
  };

  return (
    <InputsContext.Provider
      value={[
        {
          invoiceNumber,
          invoiceAmount,
          vin,
          rewardChoice,
          rewardChoiceDefault,
          rewardChoiceDefaultPoints,
          nonFordVin,
          saleType,
          saleDate,
        },
        {
          setInvoiceNumber,
          setInvoiceAmount,
          setVin,
          setRewardChoice,
          setRewardChoiceDefault,
          setRewardChoiceDefaultPoints,
          setNonFordVin,
          clearInputs,
          setSaleType,
          setSaleDate,
        },
        { validateInvoice, validateVin },
      ]}
    >
      {children}
    </InputsContext.Provider>
  );
};
