import React, { useContext } from 'react';
import { DealerContext } from '../../context/dealer-context';
import { TranslationContext } from '../../context/translations-context';

import styles from './not-authorized-view.module.scss';

export const NotAuthorizedView = () => {
  const [{ t }] = useContext(TranslationContext);

  const [{ dealerDetails }] = useContext(DealerContext);

  return (
    <>
      <div style={{ float: 'right', padding: '30px' }}>
        <a href='/logout'>logout</a>
      </div>
      <div className={styles.error}>{dealerDetails?.error.message == 'Dealer is not authorized' ? t('NOT_AUTHORIZED') : t('NOT_ENROLLED')}</div>
    </>
  );
};
