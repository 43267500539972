import React, { useContext, useEffect, useState } from 'react';
import cx from '../../utils/classnames';
import utils from '../../utils/utils.module.scss';
import styles from './financing-form-view.module.scss';
import { FinancingPrefillContext } from '../../context/financing-prefill-context';
import { CustomerContext } from '../../context/customer-context';
import { TranslationContext } from '../../context/translations-context';
import { SiteContext } from '../../context/site-context';
import FinancingService from '../../services/financing-service/financing-service'; 
import { APIHelperContext } from '../../context/api-helper-context';
import { ConnectedServicesContext } from '../../context/connected-services-context';
import { DealerContext } from '../../context/dealer-context';
import { DocuSignPassDialog } from '../../components/dialogs/docusign/docusign-success-dialog';
import { DocuSignFailDialog } from '../../components/dialogs/docusign/docusign-fail-dialog';
import { ActivityIndicator } from '../../components/common/activity-indicator/activity-indicator';
import { ModalDialog } from '../../components/common/modal-dialog/modal-dialog';
import ServiceHandler from '../../services/service-handler';

export const DealerFormView = () => {
  const { vin, setVin, modelYear, modelName, trim, purchaseDate, firstName, lastName, email, phoneNumber, zipCode, paymentSource, connectedService, productDetails, ratePlan, salePrice, dealershipName, setDealershipName, dealershipPACode, setDealershipPACode, dealershipState, employeeName, employeeTitle, employeePhoneNumber, employeeEmail, customerId, customerCity, customerState, starsId } = useContext(FinancingPrefillContext);
  const [{ t, languageCountry }] = useContext(TranslationContext);
  const [{ dealerDetails }] = useContext(DealerContext)
  const [{ rewardProgram }] = useContext(CustomerContext);
  const [{ garage }] = useContext(ConnectedServicesContext);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [showDocusignPass, setShowDocusignPass] = useState(false);
  const [showDocusignFail, setShowDocusignFail] = useState(false);
  const [{ isCanada }] = useContext(SiteContext);
  const [apiHelper] = useContext(APIHelperContext);
  const [checkbox1, setCheckbox1] = useState(false);
  const [checkbox2, setCheckbox2] = useState(false);
  const [checkbox3, setCheckbox3] = useState(false);
  const [checkbox4, setCheckbox4] = useState(false);
  const [showMenu, setShowMenu] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const country = isCanada() ? 'ca' : 'us';
  const isLAR = () => rewardProgram == 'L';


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (showMenu) {
      document.addEventListener('mousedown', () => setShowMenu(true));
    }
    return () => document.removeEventListener('mousedown', () => setShowMenu(false));
  }, [showMenu]);

  const docuSign = () => {
    const cleanSalePrice = salePrice.replace(/^\$/, '');
    const plan = productDetails?.availablePlans?.find(plan => {
      return plan.planName === ratePlan;
    });    
      const data = {
        customerId: customerId,
        language: apiHelper.languageCountry(),
        customerDealerData: {
            vin: vin,
            modelYear: parseInt(modelYear),
            vehicleLine: modelName,
            trimPEP: trim,
            purchaseDate: purchaseDate,
            custFirstName: firstName,
            custLastName: lastName,
            custEmail: email,
            custPhoneNumber: phoneNumber,
            paymentSource: paymentSource,
            dealerSellingPrice: parseFloat(cleanSalePrice),
            dealershipName: dealershipName,
            dealerPACode: dealershipPACode,
            dealerState: dealershipState,
            dealerEmployeeFullName: employeeName,
            dealerEmployeeTitle: employeeTitle,
            dealerEmployeePhoneNumber: employeePhoneNumber,
            dealerEmployeeEmail: employeeEmail,
            city: customerCity || dealerDetails.dealer.address.city,
            state: customerState || dealershipState,
            zipCode: zipCode || dealerDetails.dealer.address.postalCode,
            countryCode: garage?.userProfileCountry || dealerDetails.dealer.countryCode,
            starsId: starsId,
            dealerId: dealershipPACode,
            isFinanceable: true
        },
        products: [
          {
            name: connectedService,
            ratePlanName: ratePlan,
            price: plan?.price ? parseFloat(plan?.price) : null,
            dealerSellingPrice: parseFloat(cleanSalePrice),
            expiryDate: plan?.endDate,
            startDate: plan?.startDate,
            productSku: productDetails.productSku,
            productRatePlanId: plan?.ratePlanId,
            subscriptionType: plan?.subscriptionType,
          }
        ]
        };
    const financingService = new FinancingService();
    return financingService.request(apiHelper, data);
  }

  const plan = productDetails?.availablePlans?.find(plan => {
    return plan.planName === ratePlan;
  }); 
  const ratePlanName = ratePlan;
  const MSRP = parseFloat(plan?.price.replace(/^\$/, ''));
  const detailsConversion = t('DEALER_PRODUCT_DETAILS');
  const detailsSubheader = detailsConversion
    .replace('{Connected Service}', connectedService)
    .replace('{Rate Plan}', ratePlanName)
    .replace('{Connected Service}', connectedService)
    .replace('{Connected Service}', connectedService)
  const headerConversion = t('DEALER_AUTH_CHECKBOX_HEADER');
  const connectedServiceHeader = headerConversion
    .replace('{Connected Service}', connectedService)
    .replace('{Rate Plan}', ratePlanName);
  const checkbox1Conversion = t('CHECKBOX1');
  const checkedbox1 = checkbox1Conversion.replace('{Rate Plan}', ratePlanName).replace('{Connected Service}', connectedService);
  const disclaimerConversion = t('DISCLAIMER');
  const disclaimer = disclaimerConversion.replace('{Connected Service}', connectedService);
  const checkbox4Conversion = t('CHECKBOX4');
  const checkedbox4 = checkbox4Conversion.replace('{MSRP}', MSRP).replace('{Connected Service}', connectedService).replace('{Rate Plan}', ratePlanName);

  const handleDocusignClick = async () => {
    setIsLoading(true);
    setIsButtonDisabled(true);
    const response = await docuSign();
        if (response.message === ('DocuSign envelope sent successfully')) {
            setShowDocusignPass(true);
            sessionStorage.clear();
          } else {
            setShowDocusignFail(true);
        }
    setIsLoading(false);
    setIsButtonDisabled(false);
  };


  return (
    <>
    {isLoading && (<ModalDialog title={t('DOCUSIGN_LOADING')} width='33vw' height='15vw'><ActivityIndicator /></ModalDialog>)}
    {showDocusignPass && <DocuSignPassDialog /> }
    {showDocusignFail && <DocuSignFailDialog doClose={() => setShowDocusignFail(null)} />}

    <div className={styles.page}>
      <div className={styles.container}>
        <div className={styles.logo}>
          <img src={`${ServiceHandler.ConfigService.AEM_BASE_URL}/content/dam/loyalty/common/images/ford-logo.png`}/>
        </div>
        <div className={styles.title}>{t('Dealer Authorization Form - Connected Services')}</div>
      </div>
      <div className={styles.container}>
        <div className={styles.header}>{t('Product Details')}</div>
          <div className={styles.body}>{detailsSubheader}</div>
      </div>
      <div className={styles.lineBreak}></div>
      <div className={styles.container}>
        <div className={styles.header}>{t('Authorization Information')}</div>
        <div className={styles.columns}>
          <div className={styles.inputField}>
              {t('Primary P&A Code')}
              <input
                className={styles.readOnly}
                type='text'
                name='dealershipPACode'
                value={dealershipPACode}
                onChange={(e: any) => setDealershipPACode(e.target.value)}
                required
              />
            </div>
            <div className={styles.inputField}>
              {t('Dealership Name')}
              <input
                className={styles.readOnly}
                type='text'
                name='dealershipName'
                value={dealershipName}
                onChange={(e: any) => setDealershipName(e.target.value)}
                required
              />
            </div>
            <div className={styles.inputField}>
              {t('VIN')}
              <input
                className={styles.readOnly}
                type='text'
                name='vin'
                value={vin}
                onChange={(e: any) => setVin(e.target.value)}
                required
              />
            </div>
        </div>
        </div>
        <div className={styles.lineBreak} />
        <div className={styles.container}>
        <div className={styles.header}>{t('Agreements')}</div>
          <div className={styles.subheader}>{connectedServiceHeader}</div>
            <div className={styles.body}><input type="checkbox"  className={styles.checkbox} onClick={() => setCheckbox1(!checkbox1)} />{checkedbox1}</div>
              <div className={styles.subBody}>{disclaimer}</div>
              <div className={styles.body}><input type="checkbox" className={styles.innerCheckbox} onClick={() => setCheckbox2(!checkbox2)} /><span className={styles.innerText}>{t('CHECKBOX2')}</span></div>
              <div className={styles.body}><input type="checkbox" className={styles.innerCheckbox} onClick={() => setCheckbox3(!checkbox3)} /><span className={styles.innerText}>{t('CHECKBOX3')}</span></div>
              <div className={styles.body}><input type="checkbox" className={styles.innerCheckbox} onClick={() => setCheckbox4(!checkbox4)} /><span className={styles.innerText}>{checkedbox4}</span></div>
        </div>
      <div className={styles.lineBreak} />
      <div className={styles.buttonBar}>
      <button
          disabled={!(dealershipPACode && dealershipName && vin && checkbox1 && checkbox2 && checkbox3 && checkbox4) || isButtonDisabled}
          className={cx(utils.buttonPrimary, styles.disabledButton) || cx(isButtonDisabled ? styles.disabledButton : utils.buttonPrimary)}
          onClick={handleDocusignClick }
        >
          {t('Continue to DocuSign for Signatures')}
        </button>
      </div>
    </div>
    </>
  );
};
