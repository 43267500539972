import React, { useContext } from 'react';
import cx from '../../../utils/classnames';

import styles from './tabbar.module.scss';
import { useAmplitude } from '../../../hooks/useAmplitude';
import { TranslationContext } from '../../../context/translations-context';


interface Props {
  children: React.ReactNode;
  id?: string;
  active?: boolean;
  setTab?(id: string): void;
  className?: string;
}

function Tab({ children, active, setTab, id, className }: Props) {
  const [logAmplitudeEvent] = useAmplitude();
  const [translation] = useContext(TranslationContext);
  const handleClick = () => {
    (document.activeElement as HTMLElement).blur();
    if (setTab && id) {
      setTab(id);
    }
    logAmplitudeEvent('tab tapped', translation, { tabLabel: id});
  };
  return (
    <button
      role='tab'
      className={cx(styles.tab, { [styles.active]: active }, className)}
      onClick={handleClick}
      aria-selected={active}
      //aria-controls={`tab-panel-${id}`} -- Ask matt to review - removed because it doesn't control anything specific
      aria-label={`tab panel ${children}`}
    >
      {active ? <h1>{children}</h1> : children}
    </button>
  );
}

export default Tab;
