import React, { useContext } from 'react';
import { TranslationContext } from '../../../context/translations-context';
import { CtaButton } from '../../common/cta/cta';
import { DataGrid } from '../../common/data-grid/data-grid';
import { ModalDialog, Body, Buttons } from '../../common/modal-dialog/modal-dialog';
import { SiteContext } from '../../../context/site-context';

import styles from './pmp-contracts.module.scss';

interface Props {
  onClose?(): void;
}

export const PmpContractsDialog = ({ onClose }: Props) => {
  const [{ t, language }] = useContext(TranslationContext);
  const [{ shortCountryCode }] = useContext(SiteContext);

  const contracts = require('./contracts.json');

  const columns = [
    { id: 'code', title: t('Maintenance Plan Code') },
    { id: 'desc', title: t('Plan Description') },
  ];

  const sections = contracts[`${language}-${shortCountryCode()}`];

  return (
    <ModalDialog title={t('Eligible Maintenance Plans')} width={'1300px'}>
      <Body>
        {sections.map(section => (
          <>
            <div className={styles.section}>
              <div>{section.label}</div>
              <DataGrid data={section.codes} columns={columns} />
            </div>
          </>
        ))}
      </Body>
      <Buttons>
        <CtaButton label={t('Close')} onClick={onClose} />
      </Buttons>
    </ModalDialog>
  );
};
