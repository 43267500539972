import { ADMIN_API_PATH } from '../../constants';
import Axios from 'axios';
import ServiceHandler from '../service-handler';

const axios = Axios.create();

export default class AdminService {
  public async validateAdminUser() {
    const cfg = ServiceHandler.ConfigService;
    const url = `${cfg.LPS_BASE_URL}${ADMIN_API_PATH.ADMIN_VALIDATION}`;

    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: ServiceHandler.AuthenticationService.getIdToken(),
        },
      });

      return response.data;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error, 'Admin Service');
      return { status: 'error', error };
    }
  }
}
