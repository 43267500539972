import React, { useState, useContext } from 'react';
import { ShoppingCartContext } from '../../../context/shopping-cart-context';
import utils from '../../../utils/utils.module.scss';
import { CtaButton, CtaLink } from '../cta/cta';
import { ErrorText } from '../error-text/error-text';

interface Props {
  title: string;
  children: React.ReactElement;
  button?: boolean;
  disabled?: boolean;
  validators?: any;
}
export function ModalButton(props: Props) {
  return <ModalLink {...props} button={true} />;
}

export function ModalLink({ title, children, button = false, disabled = false, validators }: Props) {
  const [showModal, setShowModal] = useState(false);
  const [{ showValidationErrors }, { setShowValidationErrors }] = useContext(ShoppingCartContext);
  const errors = validators?.length ? validators.map((v: any) => v()).filter((v: any) => v != null) : [null];

  const Btn = button ? CtaButton : CtaLink;
  return (
    <>
      <Btn label={title} onClick={() => (!errors[0] ? setShowModal(true) : setShowValidationErrors(true))} disabled={disabled || errors[0]} />
      {showModal && !errors[0] && React.cloneElement(children, { onClose: () => setShowModal(false) })}
      {showValidationErrors && errors[0] && errors.map((err: string) => <ErrorText key={err} text={err} className={utils.marginTop20} />)}
    </>
  );
}
