import React, { useContext, useState } from 'react';
import { TranslationContext } from '../../../context/translations-context';
import cx from '../../../utils/classnames';

import styles from './terms-not-accepted.module.scss';
import { CtaLink } from '../../common/cta/cta';
import { MemberNotFoundDialog } from '../../dialogs/member-not-found/member-not-found';

interface Props {
  program: string;
}

export const TermsNotAccepted = ({ program }: Props) => {
  const [{ t }] = useContext(TranslationContext);
  const [showMemberNotFound, setShowMemberNotFound] = useState<null | string>(null);

  return (
    <>
    <div>
      {/* {showActivationDialog && <MemberNotFoundDialog program={showActivationDialog} doClose={() => setShowActivationDialog(null)} make={''} />} */}
      {showMemberNotFound && <MemberNotFoundDialog doClose={() => setShowMemberNotFound(null)} />}


      <div className={styles.wrapper}>
        <div>
          <i className={cx(`fas fa-circle-info`)}></i>
          {t('NOT_MEMBER').replace('{program}', t(program))}
        </div>
        <CtaLink onClick={setShowMemberNotFound}>{t('HELP_ENROLL')}</CtaLink>
      </div>
    </div>
    </>
  );
};
