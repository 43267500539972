import React, { useContext, useState } from 'react';
import utils from '../../utils/utils.module.scss';
import cx from '../../utils/classnames';
import { Column, TwoColumnRow } from '../../components/common/column/column';
import { InputWithLabel } from '../../components/common/Input-with-label/input-with-label';
import { TranslationContext } from '../../context/translations-context';
import { SiteContext } from '../../context/site-context';
import { ErrorText } from '../../components/common/error-text/error-text';
import { useNavigate } from 'react-router';

const SiteCodeForm = () => {
  const [{ t }] = useContext(TranslationContext);
  const [{ setSiteCode, setCountryCode }] = useContext(SiteContext);
  const [pnaCode, setPnaCode] = useState('');
  const [error] = useState(false);
  const navigate = useNavigate();
  const onChange = (value: string) => {
    setPnaCode((value[0]?.toUpperCase()?.replace(/[^A-C0-9]/, '') || '') + value.substr(1)?.replace(/[^0-9]/g, ''));
  };

  return (
    <>
      <div style={{ float: 'right', padding: '30px' }}>
        <a href='/logout'>logout</a>
      </div>
      <div className={cx(utils.centerCenter, utils.windowHeight)}>
        <div className={cx(utils.textAlignCenter, utils.textPrimary)}>
          <h2>{t('Welcome to the Dealer Member Portal')}</h2>
        </div>
        <form
          onSubmit={e => {
            e.preventDefault();
            const country = isNaN(+pnaCode[0]) ? 'CAN' : 'USA';
            setCountryCode(country); //TODO:  get rid of hack
            window.sessionStorage.setItem('userCountry', country);
            setSiteCode(pnaCode.toUpperCase());
            if (window.location.pathname === '/change-site-code') {
              navigate('/');
            }
          }}
        >
          <TwoColumnRow className={utils.centerCenter}>
            <Column className={utils.noPadding}>
              <InputWithLabel
                label={t('Enter PA Code')}
                value={pnaCode}
                onChange={e => onChange(e.target.value || '')}
                maxLength={5}
                className={utils.noPadding}
              />
            </Column>
            <Column>
              <button type='submit' className={cx(utils.buttonPrimary)} disabled={pnaCode.length !== 5} style={{ marginTop: '-16px' }}>
                {t('NEXT')}
              </button>
            </Column>
          </TwoColumnRow>
          {error && <ErrorText className={utils.textPrimary} text={t('Please make sure valid site code is entered')} />}
        </form>
      </div>
    </>
  );
};

export default SiteCodeForm;
