import React, { useContext } from 'react';
import { SiteContext } from '../../../context/site-context';
import { TranslationContext } from '../../../context/translations-context';
import { CtaLink } from '../../common/cta/cta';
import styles from './privacy-policy.module.scss';



export const PrivacyPolicy = () => {
  const [{ t, language }] = useContext(TranslationContext);
  const [{ isCanada }] = useContext(SiteContext);

  // fpr-en-US
  // https://www.ford.com/help/privacy/

  // fpr-en-CA
  // https://www.ford.ca/help/privacy/

  // fpr-fr-CA
  // https://fr.ford.ca/help/privacy/


  const url = `https://${language == 'fr' ? 'fr' : 'www'}.ford.${isCanada() ? 'ca' : 'com'}/help/privacy/`


  return (
    <CtaLink className={styles.privacy} onClick={() => window.open(url, '_blank')}>{t('Privacy Policy')}</CtaLink>
  );
};

