import React, { useContext, useEffect, useState } from 'react';
import utils from '../../../utils/utils.module.scss';
import { Header } from '../../common/header/header';
import { Column } from '../../common/column/column';
import { ShoppingCartContext } from '../../../context/shopping-cart-context';
import { CurrencyNoDollar } from '../../common/currency/currency';

import styles from './shopping-cart-section.module.scss';
import { CustomerContext } from '../../../context/customer-context';
import { ModalButton } from '../../common/modal-link/modal-link';
import cx from '../../../utils/classnames';
import { ShoppingCartSummaryDialog } from '../../dialogs/shopping-cart-summary-dialog/shopping-cart-summary-dialog';
import { Odometer } from '../../common/odometer/odometer';
import { TranslationContext } from '../../../context/translations-context';
import { InputsContext, REWARD_CHOICE_DEFAULTED } from '../../../context/inputs-context';

import { ActivityIndicatorSmall } from '../../common/activity-indicator/activity-indicator';
import { CartError, CartItem, CartMessage } from './shopping-cart-item';
import { SubmitInvoiceContext } from '../../../context/submit-invoice-context';
import { AuthContext } from '../../../context/auth-context';
import { UnusedOffersDialog } from '../../dialogs/unused-offers/unused-offers';

interface Props {
  title?: string;
  amount?: number;
  validators?: any;
  submitTransaction?(formdata: FormData, onSuccess): void;
}

export const ShoppingCartSection = ({ title, amount, validators, submitTransaction }: Props) => {
  const [{ t, formatCurrency }] = useContext(TranslationContext);
  const { isOk2Submit } = useContext(AuthContext);
  const [
    { redeemPoints, redeemDollars, discount, selectedOffers, selectedRewards, salesTab, redeemFetching, couponPointsTotal },
    { setRedeemDollars, setRedeemPoints, setDiscount, setSelectedOffers, setSelectedRewards, setCouponPointsTotal },
  ] = useContext(ShoppingCartContext);
  const [{ rewardChoice, rewardChoiceDefault, rewardChoiceDefaultPoints, invoiceNumber }] = useContext(InputsContext);
  const [{ availablePoints, points, rewardProgram }] = useContext(CustomerContext);
  const [{ doSubmit }] = useContext(SubmitInvoiceContext);
  const [unusedOffers, setUnusedOffers] = useState(true);

  useEffect(() => {
    const offerTotal = selectedOffers?.reduce((x: any, y: any) => x + y.memberDiscount, 0) || 0;
    const dollar = redeemPoints <= availablePoints() ? +redeemDollars : 0;

    const offerPointsTotal = selectedOffers?.reduce((x: any, y: any) => x + +y.pricePlan?.[0].points, 0) || 0;

    setCouponPointsTotal(offerPointsTotal);
    setDiscount(dollar + +offerTotal);
  }, [redeemDollars, amount, selectedOffers, selectedRewards]);

  const validatePointTotal = () =>
    (redeemPoints > 0 && redeemPoints > availablePoints()) || couponPointsTotal + redeemPoints > points?.pointsTotals?.[rewardProgram]?.points
      ? t('EXCEEDED_POINTS')
      : null;

  let total = Math.round(100 * (amount - discount)) / 100;

  if (salesTab) {
    total = validators.map((v: any) => v()).filter((v: any) => v != null).length == 0 ? 0 : null;
  }

  const allowSubmit = isOk2Submit && rewardChoiceDefault !== REWARD_CHOICE_DEFAULTED.SETBYDEALER;

  return (
    <Column className={utils.greyBackground}>
      <Header title={t('Total')}>
        <table className={styles.table}>
          <tbody>
            {title && (
              <CartItem desc={title} dollar='$'>
                <CurrencyNoDollar data={amount} />
              </CartItem>
            )}

            {salesTab &&
              total !== null &&
              (rewardChoiceDefault === REWARD_CHOICE_DEFAULTED.SWITCH_PMP ? (
                <>
                  <CartItem icon='check' desc={t('Switch to Discounted Maintenance Plan')} />
                  <CartItem
                    icon='*'
                    className={styles.error}
                    desc={` ( -${Math.min(rewardChoiceDefaultPoints, rewardChoiceDefaultPoints + availablePoints()).toLocaleString()} ${t(
                      'Points'
                    )} )`}
                  />
                </>
              ) : (
                <CartItem icon='check' desc={rewardChoice == 'points' ? t('SALE_AWARD') : t('Discounted Maintenance Plan')} />
              ))}

            {redeemDollars > 0 && (
              <CartItem
                className={cx({ [styles.strikeOut]: redeemPoints > availablePoints() })}
                icon='times'
                remove={() => {
                  setRedeemDollars(0);
                  setRedeemPoints(0);
                }}
                dollar={'-$'}
                desc={redeemPoints ? `${redeemPoints} Points` : ''}
              >
                <CurrencyNoDollar data={redeemDollars} />
              </CartItem>
            )}

            {selectedOffers?.length > 0 &&
              selectedOffers.map((offer: any) => (
                <CartItem
                  key={offer.barcode}
                  icon='times'
                  desc={offer.couponTypeName}
                  dollar={'-$'}
                  remove={() => {
                    setSelectedOffers(selectedOffers.filter((item: any) => item != offer));
                  }}
                >
                  <CurrencyNoDollar data={offer.memberDiscount} />
                </CartItem>
              ))}

            {!redeemDollars && !selectedOffers.length && !salesTab && (
              <CartMessage>
                <>
                  <br />
                  {t('No offers or Points have been applied.')}
                  <br />
                </>
              </CartMessage>
            )}

            {+discount > 0 && (
              <CartItem desc={t('Rewards Discount')} dollar='$'>
                <Odometer data={discount} steps={4} currency={true} dollarSign={false} />
              </CartItem>
            )}

            <CartMessage> </CartMessage>

            {title && (
              <CartItem desc={t('Total')} dollar='$'>
                <Odometer data={total} currency={true} dollarSign={false} steps={4} />
              </CartItem>
            )}

            {total < 0 && (
              <CartError
                err={`${t('Rewards Discount of ')} ${formatCurrency(discount)} ${t('cannot exceed {{title}} of ').replace(
                  '{{title}}',
                  title
                )} ${formatCurrency(amount)}`}
              />
            )}

            {rewardChoiceDefault === REWARD_CHOICE_DEFAULTED.SETBYDEALER && <CartError err={t('TRN_TRANSACTION_ALREADY_EXISTS')} />}
          </tbody>
        </table>

        <br />
        <br />
        {redeemFetching ? (
          <div>
            <ActivityIndicatorSmall />
          </div>
        ) : (
          <ModalButton
            title={allowSubmit ? t('Apply') : 'ADMIN DISABLED'}
            disabled={!allowSubmit || !invoiceNumber || rewardChoiceDefault == REWARD_CHOICE_DEFAULTED.SETBYDEALER || total == null || total < 0}
            validators={[...validators, validatePointTotal]}
          >
            {unusedOffers ? (
              <UnusedOffersDialog setUnusedOffers={setUnusedOffers} />
            ) : (
              <ShoppingCartSummaryDialog
                data={{
                  discount,
                  offers: selectedOffers,
                  redeemPoints,
                }}
                submitTransaction={() => {
                  doSubmit(submitTransaction);
                }}
                setUnusedOffers={setUnusedOffers}
              />
            )}
          </ModalButton>
        )}
      </Header>
    </Column>
  );
};
