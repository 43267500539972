import React, { useContext, useState } from 'react';
import { TranslationContext } from '../../../context/translations-context';
import { InputWithLabel } from '../Input-with-label/input-with-label';

interface Props {
  id: string;
  label: string;
  className?: string;
  value: string;

  setValue(val: string | number): void;
  validator?(): string;
  onChange?(e: any): void;
  format?(v: string): string;
  allow?(v: string): string;
}

export function PointsInput(props: Props) {
  const [{ formatPoints }] = useContext(TranslationContext);
  const allow = value =>
    value
      .trim()
      .replace(/[^0-9]/g, '')
      .replace(/^0+/, '');

  return <FormattedInput {...props} allow={allow} format={formatPoints} />;
}

export function CurrencyInput(props: Props) {
  const [{ formatCurrency }] = useContext(TranslationContext);

  const allow = value =>
    value
      .trim()
      .match(/\d{0,6}(\.\d{0,2})?/)[0]
      .replace(/^0+/, '');
  return <FormattedInput {...props} allow={allow} format={formatCurrency} />;
}

export function FormattedInput({ id, label, className, value, setValue, validator, allow, format }: Props) {
  const [active, setActive] = useState(false);
  const [activeValue, setActiveValue] = useState('');
  return (
    <InputWithLabel
      id={id}
      label={label}
      className={className}
      value={active ? activeValue : +value > 0 ? format(value) : ''}
      onChange={e => {
        setValue(+allow(e.target.value));
        setActiveValue(allow(e.target.value));
      }}
      onFocus={() => {
        setActiveValue(+value > 0 ? value : '');
        setActive(true);
      }}
      onBlur={() => setActive(false)}
      validator={validator}
    />
  );
}
