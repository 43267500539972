import React, { useContext } from 'react';
import { TranslationContext } from '../../../context/translations-context';
import styles from './connected-services-details.module.scss';

import { ModalDialog, Body } from '../../common/modal-dialog/modal-dialog';
interface Props {
  doClose?(): void;
  offer: any;
}

export const ConnectedServiceDetailsDialog = ({ offer, doClose }: Props) => {
  const [{ t }] = useContext(TranslationContext);
  const [{ formatCurrency }] = useContext(TranslationContext);

  return (
    <ModalDialog title={offer.name} doClose={doClose} className={styles.modal} width={'60vw'}>
      <Body>
        <div
          dangerouslySetInnerHTML={{
            __html: offer.longDescription ? offer.longDescription.replace(/&lt;/g, '<').replace(/&gt;/g, '>') : 'MISSING DESCRIPTION',
          }}
        />

        <hr />
        <div className={styles.header}>{t('Plan(s)')}</div>

        {offer.availablePlans &&
          offer.availablePlans.map((plan, i) => (
            <React.Fragment key={`kf${i}`}>
              <div className={styles.table}>
                <div> {plan.planName} </div>
                <div className={styles.right}>
                  {plan.price && formatCurrency(plan.price)}
                  {plan.billingPeriod == 'Annual' && t('perYear')}
                  {plan.billingPeriod == 'Month' && t('perMonth')}
                </div>
              </div>
              <br />
            </React.Fragment>
          ))}
        {offer.availablePlans && <hr />}

        {offer.keyFeatures && offer.keyFeatures.length ? (
          <>
            <div className={styles.header}>{t('Key Features')}</div>

            {offer.keyFeatures.map((feature, i) => (
              <React.Fragment key={`kf${i}`}>
                <div className={styles.table2}>
                  <div>
                    <img src={feature.image} />
                  </div>
                  <div>
                    <div className={styles.keyFeatureHeading}>{feature.title}</div>
                    <div dangerouslySetInnerHTML={{ __html: feature.description }} />
                  </div>
                </div>
                <hr />
              </React.Fragment>
            ))}
          </>
        ) : (
          <></>
        )}

        {offer.howToUrl && (
          <>
            <div>
              {t('HowItWorksPrompt')}&nbsp;&nbsp;&nbsp;
              <a href={offer.howToUrl}>{t('HowItWorksLink')}</a>
            </div>
            <hr />
          </>
        )}

        {offer.additionalInfo && (
          <>
            <div className={styles.header}>{t('Additional Information')}</div>
            <div className={styles.addInfo} dangerouslySetInnerHTML={{ __html: offer.additionalInfo }} />
          </>
        )}
      </Body>
    </ModalDialog>
  );
};
