import React, { useContext, useEffect, useState } from 'react';
import { TranslationContext } from '../../../context/translations-context';
import styles from './add-to-garage.module.scss';

import { ModalDialog, Body } from '../../common/modal-dialog/modal-dialog';
import { QRCodeComponent } from '../../common/qr-code/qr-code';
import { SiteContext } from '../../../context/site-context';
import { InputWithLabel } from '../../common/Input-with-label/input-with-label';
import { CtaButton } from '../../common/cta/cta';
import SmsService from '../../../services/sms-service/sms-service';
import { APIHelperContext } from '../../../context/api-helper-context';
import { CustomerContext } from '../../../context/customer-context';
import { AlertDialogContext } from '../../../context/alert-dialog-context';

interface Props {
  make: string;
  doClose?(): void;
}

export const AddToGarageDialog = ({ make, doClose }: Props) => {
  const [{ t }] = useContext(TranslationContext);
  const [sms, setSms] = useState('');
  const [apiHelper] = useContext(APIHelperContext);
  const [{ userProfile }] = useContext(CustomerContext);
  const [{ setShowAlert }] = useContext(AlertDialogContext);
  const [{ isCanada }] = useContext(SiteContext);
  const [{ language }] = useContext(TranslationContext);

  useEffect(() => {
    if (userProfile.phoneNumber) {
      setSms(userProfile.phoneNumber);
    }
  }, [userProfile]);

  const formatPhone = (phoneNumber: string) =>
    phoneNumber ? (phoneNumber.slice(0, 3) + '-' + phoneNumber.slice(3, 6) + '-' + phoneNumber.slice(6)).replace(/-+$/, '') : '';

  // fpr-en-US
  // https://www.ford.com/support/how-tos/fordpass/manage-my-fordpass-account/how-do-i-add-a-vehicle-to-the-fordpass-app/

  // fpr-en-CA
  // https://www.ford.ca/support/how-tos/fordpass/manage-my-fordpass-account/how-do-i-add-a-vehicle-to-the-fordpass-app/

  // fpr-fr-CA
  // https://fr.ford.ca/support/how-tos/fordpass/gerer-mon-compte-fordpass/comment-puis-je-ajouter-un-vehicule-a-lapplication-fordpass/

  // lar-en-US
  // https://www.lincoln.com/support/how-tos/lincoln-way-app/manage-my-lincoln-way-account/how-do-i-add-a-vehicle-to-the-lincoln-way-app/

  // lar-en-CA
  // https://www.lincolncanada.com/support/how-tos/lincoln-way-app/manage-my-lincoln-way-account/how-do-i-add-a-vehicle-to-the-lincoln-way-app/

  // lar-fr-CA
  // https://fr.lincolncanada.com/support/how-tos/application-lincoln-way/gestion-de-mon-compte-lincoln-way/comment-puis-je-ajouter-un-vehicule-a-lapplication-lincoln-way/

  let url;

  if (language == 'fr') {
    if (make == 'L')
      url =
        'https://fr.lincolncanada.com/support/how-tos/application-lincoln-way/gestion-de-mon-compte-lincoln-way/comment-puis-je-ajouter-un-vehicule-a-lapplication-lincoln-way/';
    else url = 'https://fr.ford.ca/support/how-tos/fordpass/gerer-mon-compte-fordpass/comment-puis-je-ajouter-un-vehicule-a-lapplication-fordpass/';
  } else {
    if (make == 'L') {
      url = `www.${
        isCanada() ? 'lincolncanada' : 'lincoln'
      }.com/support/how-tos/lincoln-way-app/manage-my-lincoln-way-account/how-do-i-add-a-vehicle-to-the-lincoln-way-app/`;
    } else {
      url = `www.ford.${isCanada() ? 'ca' : 'com'}/support/how-tos/fordpass/manage-my-fordpass-account/how-do-i-add-a-vehicle-to-the-fordpass-app/`;
    }
  }

  const smsDisclaimer = t('SMS_DISCLAIMER');
  const sendSMS = () => {
    const smsService = new SmsService();
    const baseText = t('howToAuthModem_SMS');
    const makeText = isCanada() ? (make === 'L' ? t('Lincoln Canada') : t('Ford of Canada')) : (make === 'L' ? 'Lincoln' : 'Ford');
    const text = baseText.replace('{make}', makeText) + `: ${url} ${t('MSG_DATA_DSCLMR').replace('{make}', makeText)}`;

    smsService
      .request(
        {
          text: text,
          toNumber: sms,
        },
        apiHelper
      )
      .then((response) => {
        doClose();
        if (response.status !== 'queued') {setShowAlert({ title: t('SMS_FAIL'), message: t('MSG_NOT_SENT')}) 
        } else { 
          setShowAlert({ title: t('SUCCESS'), message: t('MSG_SENT') }); }
      });
  };

  return (
    <ModalDialog title={t('How to add a vehicle to your garage')} doClose={doClose} className={styles.modal}>
      <Body>
        <div className={styles.smsContainer}>
          <InputWithLabel
            label={t('Send via SMS')}
            id='sms'
            onChange={(e: any) => setSms(e.target.value.replace(/\D/g, '').substr(0, 10))}
            maxLength={12}
            minLength={12}
            value={formatPhone(sms)}
          />
          <CtaButton className={styles.smsBtn} label={t('Send Link')} onClick={sendSMS} disabled={sms.length != 10} />
        </div>
        <div className={styles.disclaimer} dangerouslySetInnerHTML={{ __html: smsDisclaimer }} />
        <div className={styles.or}>Or</div>
        <div className={styles.QR}>
          <QRCodeComponent name={url} />
        </div>
      </Body>
    </ModalDialog>
  );
};
