import React, { useState, useContext, useEffect } from 'react';
import utils from '../../utils/utils.module.scss';
import { HeaderView, HEADER_TAB_STATES } from '../../components/sections/header-bar/header-bar';
import TabBar from '../../components/common/tabbar/tabbar';
import Tab from '../../components/common/tabbar/tab';
import { DataGrid } from '../../components/common/data-grid/data-grid';

import { APIHelperContext } from '../../context/api-helper-context';

import styles from './transactions-view.module.scss';
import { InputWithLabel } from '../../components/common/Input-with-label/input-with-label';
import cx from '../../utils/classnames';
import { FontAwesome } from '../../components/common/font-awesome/font-awesome';
import { TranslationContext } from '../../context/translations-context';

import { SiteContext } from '../../context/site-context';

import { ActivityIndicator } from '../../components/common/activity-indicator/activity-indicator';

import { TransactionViewFooter } from './transaction-view-footer';
import DealerTransactionsService from '../../services/dealer-transactions-service/dealer-transactions-service';
import { CtaButton } from '../../components/common/cta/cta';

enum TAB_STATES {
  TAB_ALL = 'All',
  TAB_SALES = 'Sales',
  TAB_SERVICE = 'Service',
  TAB_GOODWILL = 'Goodwill',
  TAB_OTHER = 'OTHER',
}
let oneTime = false;
const pageSize = 50;

export const TransactionsView = () => {
  const [{ t, formatDate, formatCurrency, language }] = useContext(TranslationContext);
  const [searchTerm, setSearchTerm] = useState('');
  const [apiHelper] = useContext(APIHelperContext); // eslint-disable-line @typescript-eslint/no-unused-vars
  const [activeTabId, setActiveTabId] = useState(TAB_STATES.TAB_ALL as string);
  const [startDate, setStartDate] = useState<Date>(new Date(new Date().setMonth(new Date().getMonth() - 1)));
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [twisties, setTwisties] = useState([]);
  const [{ siteCode }] = useContext(SiteContext);
  const [transactions, setTransactions] = useState(null);
  const [filteredPageSize, setFilteredPageSize] = useState(0);
  const [loading, setLoading] = useState(false);
  const [editingCalendar, setEditingCalendar] = useState(false);

  const [totalSize, setTotalSize] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const renderCurrency = (value: string) => (value ? formatCurrency(value) : '');
  const renderPoints = (points: string) =>
    +points ? (+points > 0 ? `${(+points).toLocaleString(language)}` : `(${(+points).toLocaleString(language)})`) : '';
  //const renderDesc = (desc: string) => desc.replace('SERVICE', t('SERVICE')).replace('SALES', t('SALES'));
  const renderDesc = (theType: string, dataItem: any) => {
    switch (theType.toUpperCase()) {
      case 'V':
        return t('SALES');
        break;
      case 'S':
        return t('SERVICE');
        break;
      case 'G':
      case 'X':
        return dataItem?.description || t('OTHER');
        break;
      default:
        return t('OTHER');
    }
  };
  const renderDate = (dt: string) => formatDate(new Date(dt.replace(/-/g, '/')));
  const renderUserName = (userName: string) => userName.toLowerCase();
  const renderSubDesc = (type: string) => type;

  const columns = [
    { id: 'invoiceNumber', title: t('INVOICE_#') },
    { id: 'memberId', title: t('MEMBER_ID'), render: renderUserName },
    { id: 'pfsTransactionType', title: t('DESCRIPTION'), render: renderDesc },

    { id: 'dateCreated', title: t('DATE'), render: renderDate },
    { id: 'totalPoints', title: t('Points'), render: renderPoints, color: true },
    { id: 'totalDiscount', title: t('RO Savings'), render: renderCurrency },
    { id: 'totalGross', title: t('AMOUNT'), render: renderCurrency },
    { id: 'partnerUserId', title: t('DEALER_ID') },
  ];
  const subCols = [
    null,
    null,
    { id: 'description', render: renderSubDesc },
    null,
    { id: 'points', render: renderPoints, color: true },
    { id: 'discount', render: renderCurrency },
  ];

  useEffect(() => {
    if (oneTime) fetchTransactions({});
    oneTime = true;
  }, [currentPage]);

  useEffect(() => {
    if (!editingCalendar) {
      setCurrentPage(0);
      fetchTransactions({});
    }
  }, [siteCode, startDate, endDate, editingCalendar]);

  const doSearch = () => {
    setCurrentPage(0);
    if (searchTerm.length == 0) {
      fetchTransactions({});
    } else if (searchTerm.length == 17) {
      fetchTransactions({ vin: searchTerm });
    } else if (searchTerm.length == 10) {
      fetchTransactions({ memberId: searchTerm });
    } else {
      fetchTransactions({ invoiceNumber: searchTerm });
    }
  };

  const fetchTransactions = params => {
    if (siteCode && !loading && !editingCalendar) {
      setLoading(true);
      const transactionService = new DealerTransactionsService();
      transactionService
        .request(
          {
            startDate: startDate?.toISOString().split('T')[0],
            endDate: endDate?.toISOString().split('T')[0],
            pageNumber: currentPage,
            pageSize,
            siteCode,
            ...params,
          },
          apiHelper
        )
        .then((response: any) => {
          response.transactions.find(trans => {
            if (trans.pfsTransactionType == 'X' || trans.pfsTransactionType == 'G') {
              trans.description = trans.transactionItems[0].description;
              trans.totalDiscount = trans.transactionItems[0].discount;
              trans.transactionItems.length = 0;
            }
          });

          setTotalSize(response.totalSize);
          setTransactions(currentPage > 0 ? [...transactions, ...response.transactions] : response.transactions);
          setLoading(false);
        })
        .catch(() => {
          setTotalSize(0);
          setTransactions([]);
          setLoading(false);
        });
    }
  };

  const filter = data => {
    if (activeTabId == TAB_STATES.TAB_ALL) return data;
    if (activeTabId == TAB_STATES.TAB_SALES) return data.filter((t: any) => t.pfsTransactionType == 'V');
    if (activeTabId == TAB_STATES.TAB_SERVICE) return data.filter((t: any) => t.pfsTransactionType == 'S');
    if (activeTabId == TAB_STATES.TAB_GOODWILL) return data.filter((t: any) => t.pfsTransactionType == 'G');
    return data.filter((t: any) => !['S', 'V', 'G'].includes(t.pfsTransactionType));
  };
  const onTwist = (o, all = null) => {
    if (all > 0) return setTwisties([]);
    if (all < 0) return setTwisties(transactions.map(t => t.trnExtNo));
    if (twisties.includes(o.trnExtNo)) {
      const _twisties = twisties.filter(item => item !== o.trnExtNo);
      setTwisties(_twisties);
    } else {
      setTwisties([...twisties, o.trnExtNo]);
    }
  };
  const twistState = o => {
    if (!(o.transactionItems?.length || o.vin)) return 0;
    return twisties.includes(o.trnExtNo) ? -1 : 1;
  };

  const renderTwist = (dataItem, getDataGridValue, className) => {
    let rows = [];
    if (dataItem.rewardChoice) {
      rows = [renderSubItem(`rewardChoice-${dataItem.invoiceNumber}`, className, t(dataItem.rewardChoice), null, null)];
    }
    if (dataItem.vin) {
      rows = [renderSubItem(`vin-${dataItem.invoiceNumber}`, className, `VIN: ${dataItem.vin}`, null, null)];
    }

    rows = rows.concat(
      dataItem.transactionItems.map((subitem, i) =>
        renderSubItem(`${i}-${dataItem.invoiceNumber}`, className, subitem.description, subitem.points, subitem.discount)
      )
    );

    return rows;
  };

  const renderSubItem = (key, className, desc, points, discount) => (
    <tr key={key} className={className}>
      <td></td>
      <td></td>
      <td></td>
      <td>{desc}</td>
      <td></td>
      <td className={cx(+points < 0 ? styles.colorNegatives : '')}>{renderPoints(points)}</td>
      <td>{renderCurrency(discount)}</td>
      <td></td>
      <td></td>
    </tr>
  );

  return (
    <div className={styles.wrapper}>
      <HeaderView tabID={HEADER_TAB_STATES.TAB_TRANSACTIONS} className={styles.excludeFromPrint} />
      <div className={styles.pageBody}>
        <div className={cx(utils.centerCenter, utils.marginTop50, styles.excludeFromPrint)}>
          <form
            className={styles.pagination}
            onSubmit={e => {
              e.preventDefault();
              doSearch();
            }}
          >
            <span>
              <FontAwesome icon='search' className={styles.searchIcon} />
              <InputWithLabel
                label={t('TRANS_SEARCH')}
                value={searchTerm}
                id='search'
                disabled={loading}
                onChange={e =>
                  setSearchTerm(
                    e.target.value
                      .trim()
                      .toUpperCase()
                      .replace(/[^0-9A-Z]/g, '')
                  )
                }
                className={cx(utils.searchInput, styles.searchInput)}
              />

              {searchTerm && (
                <FontAwesome
                  icon='times'
                  className={styles.searchCancelIcon}
                  onClick={() => {
                    setSearchTerm('');
                    setCurrentPage(0);
                    fetchTransactions({});
                  }}
                />
              )}
            </span>
            <CtaButton label={t('SEARCH')} onClick={doSearch} />
          </form>
        </div>

        <TabBar setTab={(id: string) => setActiveTabId(id)} active={activeTabId} className={cx(utils.breathingTop, styles.excludeFromPrint)}>
          <Tab id={TAB_STATES.TAB_ALL}>{t(TAB_STATES.TAB_ALL)}</Tab>
          <Tab id={TAB_STATES.TAB_SALES}>{t(TAB_STATES.TAB_SALES)}</Tab>
          <Tab id={TAB_STATES.TAB_SERVICE}>{t(TAB_STATES.TAB_SERVICE)}</Tab>
          <Tab id={TAB_STATES.TAB_GOODWILL}>{t(TAB_STATES.TAB_GOODWILL)}</Tab>
          <Tab id={TAB_STATES.TAB_OTHER}>{t(TAB_STATES.TAB_OTHER)}</Tab>
        </TabBar>
        <div className={styles.tabContent}>
          {loading ? (
            <ActivityIndicator />
          ) : (
            <DataGrid
              data={transactions}
              columns={columns}
              filter={filter}
              className={styles.datagrid}
              onTwist={onTwist}
              twistState={twistState}
              renderTwist={renderTwist}
              setFilteredPageSize={setFilteredPageSize}
            />
          )}
          {!loading && transactions?.length ? (
            <div className={cx(utils.centerTop)}>
              <span className={styles.pagination}>
                <b>
                  {'1-'}
                  {filteredPageSize} of {activeTabId == TAB_STATES.TAB_ALL ? totalSize : filteredPageSize}
                </b>
                {activeTabId == TAB_STATES.TAB_ALL && filteredPageSize < totalSize && (
                  <CtaButton label={t('More')} onClick={() => setCurrentPage(currentPage + 1)} />
                )}
              </span>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <TransactionViewFooter
        transactions={transactions}
        columns={columns}
        subColumns={subCols}
        startDate={startDate}
        endDate={endDate}
        setEditingCalendar={setEditingCalendar}
        setStartDate={d => {
          setSearchTerm('');
          setStartDate(d);
        }}
        setEndDate={d => {
          setSearchTerm('');
          setEndDate(d);
        }}
      />
    </div>
  );
};
