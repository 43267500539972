import React, { useContext, useEffect, useState } from 'react';
import styles from './tab-connected-services-view.module.scss';
import utils from '../../../utils/utils.module.scss';
import cx from '../../../utils/classnames';
import { differenceInDays, parseISO, isValid } from 'date-fns';
import { ConnectedServicesContext } from '../../../context/connected-services-context';
import { TranslationContext } from '../../../context/translations-context';
import { CustomerContext } from '../../../context/customer-context';
import { DataGrid } from '../../common/data-grid/data-grid';
import StarsIdService from '../../../services/stars-id-service/stars-id-service';
import { SelectStars } from './components/stars/select-stars-id';
import { SelectVehicle } from './components/garage/select-vehicle';
import { CtaLink, CtaSecondaryButton } from '../../common/cta/cta';
import { ActivityIndicator } from '../../common/activity-indicator/activity-indicator';
import { ConnectedServiceDetailsDialog } from '../../dialogs/connected-services-details/connected-services-details-dialog';
import { ConnectedServiceLinkDialog } from '../../dialogs/connected-services-link/connected-services-link-dialog';
import { APIHelperContext } from '../../../context/api-helper-context';
import ServiceHandler from '../../../services/service-handler';
import { AddToGarageDialog } from '../../dialogs/add-to-garage/add-to-garage-dialog';
import { AuthorizeModemDialog } from '../../dialogs/auth-modem/auth-modem-dialog';
import { PrivacyPolicy } from '../../common/privacy-policy/privacy-policy';
import { SmsTCsDialog } from '../../dialogs/text-msg-tc/text-msg-tc-dialog';
import { SiteContext } from '../../../context/site-context';
import { AltErrorText } from '../../common/error-text/error-text-alt';
import amplitude from 'amplitude-js';
import { FinFormView } from '../../../views/financing-form-view/financing-form-view';
import { FinancingPrefillContext } from '../../../context/financing-prefill-context';
import { DealerContext } from '../../../context/dealer-context';


export const TabConnectedServicesView = () => {
  const [{ garage, vehicle, refreshGarage, paidSubscriptions, freeSubscriptions, loadSubscriptionOffers, selectedStarsId, hasModem }] =
    useContext(ConnectedServicesContext);
  const [{ t, formatDate }] = useContext(TranslationContext);
  const [{ dealerDetails }] = useContext(DealerContext);
  const [apiHelper] = useContext(APIHelperContext);
  const [{ isCanada }] = useContext(SiteContext);
  const [{ userProfile }] = useContext(CustomerContext);
  const [starsIds, setStarsIds] = useState({});
  const [showFinForm, setShowFinForm] = useState<null | any>(null);
  const [showDetails, setShowDetails] = useState<null | any>(null);
  const [showLink, setShowLink] = useState<null | any>(null);
  const [showAddVehicle, setShowAddVehicle] = useState<null | string>(null);
  const [showAuthModem, setShowAuthModem] = useState<null | string>(null);
  const [showSmsTCs, setShowSmsTCs] = useState<null | string>(null);
  const [sortedStarsIds, setSortedStarsIds] = useState<null | any>(null);
  const parsedWarrantyStartDate = parseISO(vehicle?.warrantyStartDate);
  const warrantyStartDate = isValid(parsedWarrantyStartDate) ? parsedWarrantyStartDate : null;
  const currentDate = new Date();

  const {
    vin,
    setVin,
    modelYear,
    setModelYear,
    modelName,
    setModelName,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    phoneNumber,
    setPhoneNumber,
    productDetails,
    setProductDetails,
    setConnectedService,
    setRatePlan,
    dealershipName,
    setDealershipName,
    dealershipPACode,
    setDealershipPACode,
    dealershipState,
    setDealershipState,
    setCustomerId,
    setCustomerCity,
    setCustomerState
  } = useContext(FinancingPrefillContext);

  useEffect(() => {
    if (userProfile) {
      setFirstName(userProfile.firstName);
      setLastName(userProfile.lastName);
      setEmail(userProfile.userName);
      setPhoneNumber(userProfile.phoneNumber);
      setCustomerId(userProfile.lighthouseGuid);
      setCustomerCity(userProfile.city);
      setCustomerState(userProfile.state);
    }
    if (paidSubscriptions && productDetails) {
      const selectedSubscription = paidSubscriptions.find(sub => sub.id === productDetails.subscriptionId);
      if (selectedSubscription) {
        setProductDetails(productDetails);
        sessionStorage.setItem('productDetails', JSON.stringify(productDetails));
        setConnectedService(productDetails.name);
        setRatePlan(selectedSubscription.availablePlans.map(plan => plan.name));
      }
    }
    if (dealerDetails) {
      setDealershipName(dealerDetails.dealer.name);
      setDealershipPACode(dealerDetails.dealer.paCode);
      setDealershipState(dealerDetails.dealer.address.state);
    }
  }, [
    userProfile,
    paidSubscriptions,
    productDetails,
    dealerDetails,
  ]);

  useEffect(() => {
    if (apiHelper) {
      const selectedStarsId = new StarsIdService();
      selectedStarsId.request(apiHelper).then((response: any) => {
        if (response.status == 'error') return setSortedStarsIds('error');
        if (response.employees) setSortedStarsIds([...response.employees].sort((a, b) => a.firstName.localeCompare(b.firstName)));
      });
    }
  }, [apiHelper]);

  useEffect(() => {
    if (vehicle) {
      loadSubscriptionOffers(vehicle);
      setVin(vehicle.vin);
      setModelYear(vehicle.modelYear);
      setModelName(vehicle.modelName);
    }
  }, [vehicle, setVin, setModelYear, setModelName]);

  useEffect(() => {
    if (userProfile) {
      const starsIdService = new StarsIdService();
      starsIdService.request(apiHelper).then((response: any) => {
        setStarsIds(response);
      });
      refreshGarage();
    }
  }, [userProfile]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     setIsLoading(false);
  //   };
  //   fetchData();
  // }, []);

  const handleRefreshGarage = () => {
    refreshGarage();
  };

  const renderDate = (dt: string) => {
    if (dt == 'MultipleOptions') return t('Multiple Options');
    if (dt == 'N/A') return '';
    return formatDate(new Date(dt.replace(/-/g, '/')));
  };

  const renderFinFormBtn = (unused: string, dataItem: any) => {
    const handleClick = () => {
      sessionStorage.setItem('productDetails', JSON.stringify(dataItem));
      setProductDetails(dataItem);
      sessionStorage.setItem('userProfile', userProfile);
      sessionStorage.setItem('vin', vin);
      sessionStorage.setItem('modelYear', modelYear);
      sessionStorage.setItem('modelName', modelName);
      sessionStorage.setItem('firstName', firstName);
      sessionStorage.setItem('lastName', lastName);
      sessionStorage.setItem('email', email);
      sessionStorage.setItem('phoneNumber', phoneNumber);
      sessionStorage.setItem('dealershipName', dealershipName);
      sessionStorage.setItem('dealershipPACode', dealershipPACode);
      sessionStorage.setItem('dealershipState', dealershipState);
      sessionStorage.setItem('customerId', userProfile.lighthouseGuid);
      sessionStorage.setItem('customerCity', userProfile.city);
      sessionStorage.setItem('customerState', userProfile.state);
      sessionStorage.setItem('starsId', selectedStarsId);
        const newWindow = window.open('/fin-auth-form', '_blank');
        if (newWindow) {
          sessionStorage.setItem('productDetails', JSON.stringify(dataItem));
        }
      };
    
    if (dataItem?.financeable == false) {
      return ('Not Available')
    } else if (differenceInDays(currentDate, warrantyStartDate) > 30) {
      return <div className={styles.grayedOut}>{t('Authorization Form')}</div>;
    } else if (dataItem?.status === 'Activation Pending' || dataItem?.status === 'Cancellation Pending' || dataItem?.status === 'Pending') {
      return <div className={styles.grayedOut}>{t('Authorization Form')}</div>;
    } else {
      return <a onClick={() => {
        setProductDetails(dataItem);
        handleClick();
      }}
    >
      {t('Authorization Form')}
    </a>
    }}

  const renderIncentive = (unused: string, dataItem: any) => {
    return dataItem?.financeable == false ? '' : '';
  };

  const renderDetailBtn = (unused: string, dataItem: any) => {
    return dataItem?.productDetailsLinkDisplayFlag == false ? (
      ''
    ) : (
      <a
        onClick={() => {
          setShowDetails(dataItem);
        }}
      >
        {t('Product Details')}{' '}
      </a>
    );
  };

  const renderStatus = (status: string) => {
    return <b className={styles[status.replace(/ /g, '')]}>{status}</b>;
  };

  const renderLink = (unused: string, dataItem: any) => {
    return dataItem.activationLinkDisplayFlag == true ? (
      <button
        className={cx(utils.buttonPrimary, styles.button)}
        onClick={() => {
          setShowLink(dataItem);

          amplitude.getInstance().logEvent('pfs SSP dialog', {
            offerSku: dataItem.productSku,
            offerName: dataItem.name,
            classification: dataItem.classification,
          });
        }}
      >
        {t('Activation Link')}
      </button>
    ) : (
      ''
    );
  };

  const renderName = (name: string) => {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: name,
        }}
      />
    );
  };

  // const isQuebec =
  //   userProfiles?.userProfile?.address?.state === 'QC' ||
  //   userProfiles?.userProfile?.address?.state === 'PQ' ||
  //   dealerDetails?.dealer?.address.state === 'QC' ||
  //   dealerDetails?.dealer?.address.state === 'PQ';
  const FIN_FORM = t('FIN_AVAIL');
  const INCENTIVE = t('Dealer Incentive');
  const DETAIL_BTN = t('SSP_DESC');

  const freeColumns = [
    { id: 'name', title: t('NAME'), render: renderName },
    { id: 'status', title: t('STATUS'), render: renderStatus },
    { id: 'expirationDate', title: t('Expiration Date'), render: renderDate },
    { id: 'name', title: t('SSP_DESC'), render: renderDetailBtn },
    { id: 'name', title: '', render: renderLink },
  ];

  const paidColumns = [
    { id: 'name', title: t('NAME'), render: renderName },
    { id: 'status', title: t('STATUS'), render: renderStatus },
    { id: 'expirationDate', title: t('Expiration Date'), render: renderDate },
    { id: 'finForm', title: <div dangerouslySetInnerHTML={{ __html: FIN_FORM }} />, render: renderFinFormBtn },
    { id: 'incentive', title: <div dangerouslySetInnerHTML={{ __html: INCENTIVE }} />, render: renderIncentive },
    { id: 'name', title: <div dangerouslySetInnerHTML={{ __html: DETAIL_BTN }} />, render: renderDetailBtn },
    { id: 'name', title: '', render: renderLink },
  ];

  // const userCountryItem = Array.isArray(garage) ? garage.find(item => item.userProfileCountry !== undefined) : null;
  // const userProfileCountry = userCountryItem?.userProfileCountry;
  
  // if (isLoading) {
  //   return <ActivityIndicator />;
  // }
  
  // if (garage?.length > 0 && userProfileCountry !== undefined && userProfileCountry !== null && userProfileCountry !== apiHelper.countryCode) {
  //   return <AltErrorText text={t('WRONG_COUNTRY')} subtext={t('WRONG_COUNTRY_SUBTEXT')} />;
  // }

  return (
      <div className={styles.connectedServicesContainer}>
        {showFinForm && (
          <a target='_blank' rel='noopener noreferrer'>
            <FinFormView />
          </a>
        )}
        {showDetails && <ConnectedServiceDetailsDialog offer={showDetails} doClose={() => setShowDetails(null)} />}
        {showLink && <ConnectedServiceLinkDialog offer={showLink} doClose={() => setShowLink(null)} />}
        {showAddVehicle && <AddToGarageDialog make={showAddVehicle} doClose={() => setShowAddVehicle(null)} />}
        {showAuthModem && <AuthorizeModemDialog make={showAuthModem} doClose={() => setShowAuthModem(null)} />}
        {showSmsTCs && <SmsTCsDialog doClose={() => setShowSmsTCs(null)} />}

        {sortedStarsIds != null ? (
          sortedStarsIds == 'error' ? (
            <AltErrorText text={t('CS_UNAVAILABLE')} subtext={t('CS_UNAVAILABLE_SUBTEXT')} />
          ) : (
            <>
              <div className={styles.idAndGarageText}>{t('STARS ID')}</div>
              <div>
                <SelectStars options={sortedStarsIds} />
              </div>
            </>
          )
        ) : (
          <ActivityIndicator />
        )}

        <br />
        
        {selectedStarsId && (
          <div className={styles.garageColumns}>
            <div>
              <div className={styles.idAndGarageText}>{t('MEMBER_GARAGE').replace('{firstName}', firstName)}</div>
              <SelectVehicle vehicles={garage || []} />
            </div>
            <div>
              <div className={styles.howToAdd}>
                {t('HOW_TO_GARAGE')}
                <CtaLink label={'Ford'} onClick={() => setShowAddVehicle('F')} />
                {' or '}
                <CtaLink label={'Lincoln'} onClick={() => setShowAddVehicle('L')} />
              </div>
              <CtaSecondaryButton onClick={handleRefreshGarage} className={styles.refreshBtn}>
                {t('Refresh Garage')}
              </CtaSecondaryButton>
            </div>
            <div></div>
          </div>
        )}

        <br />

        {vehicle && !hasModem && (
          <div className={styles.modemUnauth}>
            {vehicle.modelYear} {vehicle.make} {vehicle.modelName} {t('NO_MODEM')}
            <br />
            {t('CONT_MODEM')} <CtaLink onClick={handleRefreshGarage}>{t('REFRESH_GARAGE')}</CtaLink>.
            <br />
            <CtaLink onClick={setShowAuthModem}>{t('AUTH_MODEM')}</CtaLink>
          </div>
        )}

        {((vehicle && hasModem && paidSubscriptions !== false) ||
          (vehicle && hasModem && (paidSubscriptions?.length === 0 || paidSubscriptions === false))) && (
          <div className={styles.vehicleDesc}>
            <div>{t('Connected Services for:')} </div>
            <div>
              <img
                src={
                  // vehicle.vehicleImageUrl ? vehicle.vehicleImageUrl :   // commented out temporarily for backend
                  `${ServiceHandler.ConfigService.AEM_BASE_URL}/content/dam/loyalty/common/images/NoVehicle_Placeholder.png`
                }
              />
            </div>
            <div className={styles.selectedVehicle}>
              {vehicle.modelYear} {vehicle.make} {vehicle.modelName}
              <br />
              {vehicle.vin}
              <br />
              {t('WARRANTY_DATE')}{vehicle.warrantyStartDate}
            </div>
          </div>
        )}

        {vehicle && hasModem && (freeSubscriptions?.length === 0 || freeSubscriptions === false) && (
          <>
            <br />
            <br />
            <br />
            <br />
            <h2 className={styles.tableHeading}>{t('FREE_TRIAL')}</h2>
            <div className={styles.emptyTable}>
              <div className={styles.noOffers}>{t('NO_FREE')}</div>
            </div>
          </>
        )}

        {vehicle && freeSubscriptions?.length > 0 && hasModem && (
          <>
            <br />
            <br />
            <br />
            <br />
            <br />
            <h2 className={styles.tableHeading}>
              {t('FREE_TRIAL')}
              <CtaSecondaryButton onClick={() => loadSubscriptionOffers(vehicle)} className={styles.refreshFreePaidBtn}>
                {t('Refresh Product Status')}
              </CtaSecondaryButton>
            </h2>
            <DataGrid data={freeSubscriptions} columns={freeColumns} sortable={false} />
            <div className={styles.disclaimer}>{t('SSP_DISCLAIMER')}</div>
          </>
        )}

        {vehicle && hasModem && (paidSubscriptions?.length === 0 || paidSubscriptions === false) ? (
          <>
            <br />
            <br />
            <h2 className={styles.tableHeading}>{t('Paid Services')}</h2>
            <div className={styles.emptyTable}>
              <div className={styles.noOffers}>{t('NO_PAID')}</div>
            </div>
          </>
        ) : (
          <>{vehicle && hasModem && paidSubscriptions?.length == undefined && <ActivityIndicator />}</>
        )}

        {vehicle && paidSubscriptions?.length > 0 && paidSubscriptions !== false && hasModem && (
          <>
            <br />
            <br />
            <br />
            <h2 className={styles.tableHeading}>
              {t('Paid Services')}
              <CtaSecondaryButton onClick={() => loadSubscriptionOffers(vehicle)} className={styles.refreshFreePaidBtn}>
                {t('Refresh Product Status')}
              </CtaSecondaryButton>
            </h2>
            <DataGrid data={paidSubscriptions} columns={paidColumns} sortable={false} />
            <div className={styles.disclaimer}>{t('SSP_DISCLAIMER')}</div>
          </>
        )}
        <div className={styles.privacy}>
          {t('Ford and Lincoln Privacy Notice: ')}
          <PrivacyPolicy />
        </div>
        {!isCanada() && (
          <>
            <div className={styles.textTCs}>
              {t('Text Messaging Terms and Conditions: ')}
              <CtaLink className={styles.textTCs} onClick={setShowSmsTCs}>
                {t('Ford and Lincoln SMS T&Cs')}
              </CtaLink>
            </div>
          </>
        )}
      </div>
  );
};
