import React, { useContext } from 'react';
import utils from '../../../utils/utils.module.scss';
import { Header } from '../../common/header/header';

import { TranslationContext } from '../../../context/translations-context';
import { DatePickerWithLabel } from '../../common/datepicker-with-label/datepicker-with-label';
import { InputsContext } from '../../../context/inputs-context';
import styles from './sale-date.module.scss';
import cx from '../../../utils/classnames';

export const SaleDatePicker = ({ title }: { title: string }) => {
  const [{ t }] = useContext(TranslationContext);
  const [{ saleDate }, { setSaleDate }] = useContext(InputsContext);
  // const fifteenDaysInFuture = new Date();
  // fifteenDaysInFuture.setDate(fifteenDaysInFuture.getDate() + 15);
  const oneYearInPast = new Date();
  oneYearInPast.setDate(oneYearInPast.getDate() - 364);
  return (
    <Header title={title} className={cx(utils.section, styles.labelTweak)}>
      <DatePickerWithLabel
        label={t('Select date')}
        onChange={date => setSaleDate(date)}
        id='saleDate'
        //maxDate={fifteenDaysInFuture}
        minDate={oneYearInPast}
        selectedDate={saleDate}
        className={styles.saleDate}
      />
    </Header>
  );
};
