// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dN3pkJ8t9wKII45oRO2g{font-size:14px;padding-top:0}`, "",{"version":3,"sources":["webpack://./src/components/common/privacy-policy/privacy-policy.module.scss"],"names":[],"mappings":"AACA,sBACI,cAAA,CACA,aAAA","sourcesContent":["@import \"main.scss\";\n.privacy {\n    font-size: 14px;\n    padding-top: 0;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"privacy": `dN3pkJ8t9wKII45oRO2g`
};
export default ___CSS_LOADER_EXPORT___;
