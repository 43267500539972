import React, { useContext, useState } from 'react';
import utils from '../../../utils/utils.module.scss';
import { Header } from '../../common/header/header';
import { CtaButton } from '../../common/cta/cta';
import { TranslationContext } from '../../../context/translations-context';
import ReportDownloadService, { REPORT_TYPE } from '../../../services/report-download-service/report-download-service';
import { APIHelperContext } from '../../../context/api-helper-context';
import DsecReportDownloadService from '../../../services/dsec-report-download-service/dsec-report-download-service';
import { SiteContext } from '../../../context/site-context';


export const TabReportsDealerCompensation = () => {
  const [{ t, language }] = useContext(TranslationContext);
  const [{ isCanada, countryCode }] = useContext(SiteContext);
  const [apiHelper] = useContext(APIHelperContext);

  const [year, setYear] = useState(new Date().getFullYear());
  const [type, setType] = useState<string>();
  const [month, setMonth] = useState(1);
  const years = [2019];
  while (years[0] < new Date().getFullYear()) years.unshift(years[0] + 1);
  const thisYear = new Date().getFullYear();
  const thisMonth = new Date().getMonth();
  const months =
    year === thisYear
      ? Array.from({ length: 12 }, (e, i) => new Date(null, i + 1, null).toLocaleDateString(language, { month: 'long' })).slice(0, thisMonth + 1)
      : Array.from({ length: 12 }, (e, i) => new Date(null, i + 1, null).toLocaleDateString(language, { month: 'long' }));

  const download = () => {
    if (type == 'DealerCompensation') {
      const reportDownloadService = new ReportDownloadService();
      const filename = `${REPORT_TYPE.DealerCompensation}${isCanada() ? `${countryCode[0]}${countryCode[1].toLowerCase()}` : ''}/${language == 'fr' ? 'ConcessionaireIndemniteEncouragementPourMois' : 'DealerCompensationIncentive'}_${apiHelper.siteCode}_${year}_${months[month - 1].substr(
        0,
        3
      )}.xlsx`;

      const filepath = `reports/${filename}`;
      reportDownloadService.downloadReport(filepath, apiHelper, t('REPORT_NOT_FOUND_FOR_MONTH_AND_YEAR') + ': ' + months[month - 1] + ', ' + year);
    } else {
      const data = {
        language: apiHelper.languageCountry(),
        partnerCountry: apiHelper.countryCode,
        siteCode: apiHelper.siteCode,
        reportType: type,
        month: month,
        year: year,
      };
      const dsecReportDownload = new DsecReportDownloadService();
      dsecReportDownload.downloadReport(data, apiHelper, t('REPORT_NOT_FOUND_FOR_MONTH_AND_YEAR'));
    }
  };

  return (
    <div>
      <Header title={t('SELECT_REPORT')} className={utils.section}>
        <select name='reportType' className={utils.combo} onChange={e => setType(e.target.value)}>
          <option value=''>{t('REPORT_TYPE')}</option>
          <option value={REPORT_TYPE.DealerCompensation}>{t('DEALER_COMPENSATION')}</option>
          <option value={REPORT_TYPE.ActiveSubscriptions}>{t('ACTIVE_SUBSCRIPTIONS')}</option>
          <option value={REPORT_TYPE.ExpiredSubscriptions}>{t('EXPIRED_SUBSCRIPTIONS')}</option>
          <option value={REPORT_TYPE.CancelledSubscriptions}>{t('CANCELLED_SUBSCRIPTIONS')}</option>
        </select>
      </Header>
      <Header title={'YEAR'} className={utils.section}>
        <select name='years' className={utils.combo} onChange={e => setYear(+e.target.value)}>
          {years.map((y: number) => (
            <option key={y}>{y}</option>
          ))}
        </select>
      </Header>
      <Header title={'MONTH'} className={utils.section}>
        <select name='months' className={utils.combo} onChange={e => setMonth(+e.target.value)}>
          {months.map((monthName, i) => (
            <option key={monthName} value={i + 1} selected={i + 1 === month}>
              {monthName}
            </option>
          ))}
        </select>
      </Header>

      <CtaButton label={'Download Report'} className={utils.max300} onClick={download} />
    </div>
  );
};
