import React from 'react';
import cx from '../../../utils/classnames';

interface Props {
  icon: string;
  prefix?: string;
  className?: string;
  onClick?(): void;
}

export function FontAwesome({ icon, className, prefix = 'fa', onClick }: Props) {
  return <i className={cx(prefix, `fa-${icon}`, className)} onClick={onClick}></i>;
}
