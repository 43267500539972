import React, { useContext } from 'react';
import cx from '../../../utils/classnames';
import styles from './contact-us.module.scss';
import utils from '../../../utils/utils.module.scss';
import { FontAwesome } from '../../common/font-awesome/font-awesome';
import { ModalDialog, Body } from '../../common/modal-dialog/modal-dialog';
import { TranslationContext } from '../../../context/translations-context';

interface Props {
  make: string;
  doClose?(): void;
}

export const ContactUsDialog = ({ doClose }: Props) => {
  const [{ t }] = useContext(TranslationContext);
  const rewardsHours = t('REWARDS_HOURS');
  const connectedServicesContact = t('CONNECTED_SERVICES_CONTACT');
  const connectedServicesSubheader = t('CONNECTED_SERVICE_SUBHEADER');


  return (
    <ModalDialog title={t('Contact Us')} doClose={doClose} className={styles.modal}>
      <Body>
        <div className={styles.contactUs}>
          <div className={styles.subtext}>{t('CONTACT_SUBHEADER')}</div>
            <div className={styles.row}>
              <div className={styles.column}>
                <div className={styles.subtext}>{t('REWARDS_SUBHEADER')}</div>
                  <div className={styles.box}>
                    <div className={styles.boxHeader}>{t('Rewards Contact Center')}</div>
                    <div className={styles.subtext}>{t('REWARDS_NUMBER')}</div>
                    <div className={styles.boxHeader}>{t('Hours:')}</div>
                    <div className={styles.subtextTimes} dangerouslySetInnerHTML={{ __html: rewardsHours }} />
                  </div>
              </div>
              <div className={styles.column}>
                <div className={styles.subtext} dangerouslySetInnerHTML={{ __html: connectedServicesSubheader }} />
                  <div className={styles.box}>
                    <div className={styles.boxHeader}>{t('Connected Services Support')}</div>
                    <div className={styles.subtext} dangerouslySetInnerHTML={{ __html: connectedServicesContact }} />
                  </div>
              </div>
            </div>
            <div>
              <FontAwesome icon='triangle-exclamation' className={styles.icon} />
              <span className={styles.subtext}>
                {t('CONTACT_US_DISCLAIMER')}
              </span>
            </div>
          </div>
      </Body>
    </ModalDialog>
  );
};
