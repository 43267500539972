import React, { useContext } from 'react';
import { DealerContext } from '../../../context/dealer-context';
import { TranslationContext } from '../../../context/translations-context';
import { Body, ModalDialog } from '../../common/modal-dialog/modal-dialog';
import utils from '../../../utils/utils.module.scss';
import { SiteContext } from '../../../context/site-context';

export const PACodeDialog = () => {
  const [{ t }] = useContext(TranslationContext);

  const [{ dealerPaCodes }] = useContext(DealerContext);
  const [{ setSiteCode, paCodeDialog, setPaCodeDialog }] = useContext(SiteContext);

  return (
    <>
      {!paCodeDialog && dealerPaCodes.length > 1 && (
        <ModalDialog title={t('DEALER_PACODE_TITLE')} width='30vw'>
          <Body>
            {dealerPaCodes.map(dealer => (
              <div key={dealer.code} className={utils.radioItem}>
                <input
                  type='radio'
                  id={dealer.code}
                  name={'sitecode'}
                  onChange={() => {
                    setPaCodeDialog(true);
                    setSiteCode(dealer.code);
                  }}
                />
                <label htmlFor={dealer.code}>
                  <strong>{dealer.code} </strong> {dealer.name}
                </label>
              </div>
            ))}
          </Body>
        </ModalDialog>
      )}
    </>
  );
};
