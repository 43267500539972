/* eslint-disable no-useless-escape */
import React from 'react';
import cx from '../../../utils/classnames';
import utils from '../../../utils/utils.module.scss';

interface Props {
  text: string;
  subtext: string;
  className?: string;
}

export function AltErrorText({ className, text, subtext }: Props) {
  return (
    <>
      {text ? (
        <div className={cx(utils.altError, className)} data-testid='text'>
          <div
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
          <div className={utils.subtext}
            dangerouslySetInnerHTML={{
              __html: subtext,
            }}
          />
        </div>
      ) : null}
    </>
  );
}
