import React from 'react';
import cx from '../../../utils/classnames';
import { InputWithLabel } from '../Input-with-label/input-with-label';
import styles from './vin-input.module.scss';

export const doValidateVin = (vin: string) => {
  vin = vin.toLowerCase();
  if (!/^[a-hj-npr-z0-9]{8}[0-9xX][a-hj-npr-z0-9]{8}$/.test(vin)) {
    return false;
  }

  const transliterationTable = {
    '0': 0,
    '1': 1,
    '2': 2,
    '3': 3,
    '4': 4,
    '5': 5,
    '6': 6,
    '7': 7,
    '8': 8,
    '9': 9,
    a: 1,
    b: 2,
    c: 3,
    d: 4,
    e: 5,
    f: 6,
    g: 7,
    h: 8,
    j: 1,
    k: 2,
    l: 3,
    m: 4,
    n: 5,
    p: 7,
    r: 9,
    s: 2,
    t: 3,
    u: 4,
    v: 5,
    w: 6,
    x: 7,
    y: 8,
    z: 9,
  };

  const weightsTable = [8, 7, 6, 5, 4, 3, 2, 10, 0, 9, 8, 7, 6, 5, 4, 3, 2];
  let sum = 0;

  for (let i = 0; i < vin.length; ++i) {
    sum += transliterationTable[vin.charAt(i)] * weightsTable[i];
  }

  const mod = sum % 11;
  return mod === 10 ? vin.charAt(8) === 'x' : +vin.charAt(8) == mod;
};

export const isFordOrLincolnVin = (vin: string) => {
  if (!doValidateVin(vin)) return true;
  const FordLincolnVins = [
    'AFA',
    'FVL',
    'KNA',
    'KNJ',
    'LEF',
    'LFA',
    'LJX',
    'LVR',
    'LVS',
    'LXW',
    'MAJ',
    'MNA',
    'MNB',
    'MNC',
    'MND',
    'MPB',
    'MS5',
    'MS6',
    'NM0',
    'PE1',
    'PE2',
    'PR8',
    'RL0',
    'RL2',
    'RL3',
    'SFA',
    'THD',
    'TW2',
    'UNI',
    'VS6',
    'WF0',
    'WF1',
    'XLC',
    'X2F',
    'X9F',
    'Y4F',
    'Z6F',
    '1F1',
    '1F6',
    '1F7',
    '1FA',
    '1FB',
    '1FC',
    '1FD',
    '1FF',
    '1FM',
    '1FT',
    '1L1',
    '1LJ',
    '1LN',
    '1ME',
    '1MH',
    '1ZV',
    '1ZW',
    '2FA',
    '2FD',
    '2FM',
    '2FT',
    '2L1',
    '2LJ',
    '2LM',
    '2LN',
    '2ME',
    '2MH',
    '2MR',
    '3FA',
    '3FC',
    '3FD',
    '3FE',
    '3FM',
    '3FN',
    '3FR',
    '3FT',
    '3LA',
    '3LN',
    '3MA',
    '3ME',
    '4M2',
    '5L1',
    '5LD',
    '5LM',
    '5LT',
    '537',
    '6FP',
    '7A5',
    '8AF',
    '8YP',
    '8YT',
    '8XD',
    '9BF',
  ];
  return FordLincolnVins.find(v => vin.startsWith(v)) != undefined;
};

interface Props {
  label: string;
  className?: string;
  value: string;
  setValue(val: string): void;
  validator?(): string;
}

export function VinInput({ label, className, value, setValue, validator }: Props) {
  return (
    <InputWithLabel
      label={label}
      id='vin'
      className={cx(className, styles.vinInput)}
      value={value}
      onChange={e => setValue(e.target.value.toUpperCase().replace(/[^A-Z0-9]/g, ''))}
      validator={validator}
      maxLength={17}
    />
  );
}
