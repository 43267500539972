import React, { useContext } from 'react';
import cx from '../../../utils/classnames';
import utils from '../../../utils/utils.module.scss';
import { Header } from '../../common/header/header';

import { CustomerContext } from '../../../context/customer-context';
import { Column, ThreeColumnRow } from '../../common/column/column';
import { PointsDollarsEntry } from '../points-dollars-entry/points-dollars-entry';
import styles from './points-section.module.scss';
import { TranslationContext } from '../../../context/translations-context';
import { Odometer } from '../../common/odometer/odometer';
import { ShoppingCartContext } from '../../../context/shopping-cart-context';

export const PointsSection = () => {
  const [{ t }] = useContext(TranslationContext);
  const [{ redeemPoints, redeemDollars }, { setRedeemPoints, setRedeemDollars }] = useContext(ShoppingCartContext);
  const [{ points, rewardProgram }] = useContext(CustomerContext);

  return (
    <Header title={t('Redeem Points')} icon='star'>
      <ThreeColumnRow>
        <Column className={cx(utils.noPadding, styles.rightBorder)}>
          <div className={styles.points}>
            {points?.pointsTotals?.[rewardProgram]?.points ? <Odometer data={points?.pointsTotals[rewardProgram]?.points} /> : '--'}
          </div>
          <div>{t('available Points')}</div>
        </Column>
        <PointsDollarsEntry className={styles.inputs} inputs={[redeemPoints, redeemDollars, setRedeemPoints, setRedeemDollars]} />
      </ThreeColumnRow>
    </Header>
  );
};
