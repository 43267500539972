// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gxSbAoHTuJC6iq6md4Xz{font-size:16px;color:#555;margin-bottom:4px}.gxSbAoHTuJC6iq6md4Xz+i{color:red;margin-right:15px}.M3tFPyEMiH2U1CoGmJCS div{display:inline-block;font-size:20px}`, "",{"version":3,"sources":["webpack://./src/components/common/labeled-data/labeled-data.module.scss"],"names":[],"mappings":"AACA,sBACE,cAAA,CACA,UAAA,CACA,iBAAA,CACA,wBACE,SAAA,CACA,iBAAA,CAIF,0BACE,oBAAA,CACA,cAAA","sourcesContent":["@import \"main.scss\";\n.label {\n  font-size: 16px;\n  color: #555;\n  margin-bottom: 4px;\n  + i {\n    color: #f00;\n    margin-right: 15px;\n  }\n}\n.columnData {\n  div {\n    display: inline-block;\n    font-size: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `gxSbAoHTuJC6iq6md4Xz`,
	"columnData": `M3tFPyEMiH2U1CoGmJCS`
};
export default ___CSS_LOADER_EXPORT___;
