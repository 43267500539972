// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Cg6X7m_1HidF4f2uZsOm{margin-bottom:50px}.Cg6X7m_1HidF4f2uZsOm>div{font-family:FordAntennaBold,sans-serif;letter-spacing:2px;font-size:25px;font-weight:900;text-align:center;margin-bottom:10px}`, "",{"version":3,"sources":["webpack://./src/components/dialogs/pmp-contracts/pmp-contracts.module.scss","webpack://./src/styles/theme/_fonts.scss"],"names":[],"mappings":"AACA,sBASE,kBAAA,CARA,0BACE,sCCCc,CAAA,kBAAA,CDCd,cAAA,CACA,eAAA,CACA,iBAAA,CACA,kBAAA","sourcesContent":["@import \"main.scss\";\n.section {\n  > div {\n    font-family: $theme-font-bold;\n    letter-spacing: 2px;\n    font-size: 25px;\n    font-weight: 900;\n    text-align: center;\n    margin-bottom: 10px;\n  }\n  margin-bottom: 50px;\n}\n","$theme-font: FordAntenna, sans-serif;\n$theme-font-reg-italic: FordAntennaItalic, sans-serif;\n$theme-font-light: FordAntennaLight, sans-serif;\n$theme-font-medium: FordAntennaMedium, sans-serif;\n$theme-font-bold: FordAntennaBold, sans-serif;\n$theme-font-extra-light: FordAntennaExtraLight, sans-serif;\n$theme-font-condensed-regular: FordAntennaCondensedRegular, sans-serif;\n$theme-font-condensed-bold: FordAntennaCondensedBold, sans-serif;\n$theme-font-condensed-medium: FordAntennaCondensedMedium, sans-serif;\n$theme-font-condensed-light: FordAntennaCondensedLight, sans-serif;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `Cg6X7m_1HidF4f2uZsOm`
};
export default ___CSS_LOADER_EXPORT___;
