import React, { useContext, useEffect } from "react";
import utils from "../../../utils/utils.module.scss";
import { Header } from "../../common/header/header";

import { CustomerContext } from "../../../context/customer-context";
import { ShoppingCartContext } from "../../../context/shopping-cart-context";
import { TranslationContext } from "../../../context/translations-context";
import cx from "../../../utils/classnames";
import { useAmplitude } from "../../../hooks/useAmplitude";

interface Props {
  uniqueID: string;
  checkEligibility?: boolean;
}

export const RewardProgramChoice = ({
  uniqueID,
  checkEligibility = true,
}: Props) => {
  const [{ t }] = useContext(TranslationContext);

  // eslint-disable-next-line no-empty-pattern
  const [{}, { clearCart }] = useContext(ShoppingCartContext);
  const [{ userProfile, points, rewardProgram }, { setRewardProgram }] =
    useContext(CustomerContext);

  const isEligible = (programCode: string) =>
    checkEligibility
      ? points?.eligibility[programCode].status == "ELIGIBLE"
      : true;

  useEffect(() => {
    if (!rewardProgram) {
      if (isEligible("F")) return setRewardProgram("F");
      if (isEligible("L")) return setRewardProgram("L");
    }
  }, [userProfile]);

  const [logAmplitudeEvent] = useAmplitude();
  const handleRadioChange = (programCode: string, label: string) => {
    console.log("handleRadioChange called with programCode:", programCode);
    clearCart();
    setRewardProgram(programCode);
    logAmplitudeEvent("reward program tapped", t, {
      label: label,
      programCode: programCode,
    });
  };

  return (
    <Header title={t("Rewards Program")} className={utils.section}>
      <div className={utils.radioItem}>
        <input
          type="radio"
          id="F"
          name={uniqueID + "RewardProgramChoice"}
          value="F"
          onChange={() => handleRadioChange("F", t("FORDPASS_REWARDS"))}
          checked={rewardProgram == "F"}
          disabled={!isEligible("F")}
        />
        <label
          htmlFor="F"
          className={cx({ [utils.disabledText]: !isEligible("F") })}
        >
          {t("FORDPASS_REWARDS")}
        </label>
      </div>
      <div className={utils.radioItem}>
        <input
          type="radio"
          id="L"
          name={uniqueID + "RewardProgramChoice"}
          value="L"
          onChange={() => handleRadioChange("L", t("LINCOLN_ACCESS_REWARDS"))}
          checked={rewardProgram == "L"}
          disabled={!isEligible("L")}
        />
        <label
          htmlFor="L"
          className={cx({ [utils.disabledText]: !isEligible("L") })}
        >
          {t("LINCOLN_ACCESS_REWARDS")}
        </label>
      </div>
    </Header>
  );
};
