import React, { useContext } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { ActivityIndicator } from '../activity-indicator/activity-indicator';
import { SiteContext } from '../../../context/site-context';
import ServiceHandler from '../../../services/service-handler';
import { useAmplitude } from '../../../hooks/useAmplitude';
import { AuthContext } from '../../../context/auth-context';
import { NotAuthorizedView } from '../../../views/not-authorized-view/not-authorized-view';
import { DealerContext } from '../../../context/dealer-context';
import { TranslationContext } from '../../../context/translations-context';

export const AuthRoute = () => {
  const { isDealer, authService, adminAuthenticating, isAdmin } = useContext(AuthContext);
  const [{ dealerDetails }] = useContext(DealerContext);
  const [{ siteCode, setSiteCode }] = useContext(SiteContext);
  const [logAmplitudeEvent] = useAmplitude();
  const [translation] = useContext(TranslationContext);

  const navigate = useNavigate();

  if (siteCode == null && sessionStorage.getItem('siteCode') != null) {
    setSiteCode(sessionStorage.getItem('siteCode'))
  }
  if (!isDealer() && adminAuthenticating) {
    return <ActivityIndicator />;
  } else if (dealerDetails && dealerDetails['isAuthorized'] != undefined && !dealerDetails.isAuthorized) {
    return <NotAuthorizedView />;
  } else if (isAdmin == true && !siteCode) {
    navigate('/change-site-code');
    return <ActivityIndicator />;
  } else if (!siteCode && ServiceHandler.AuthenticationService.getIsAuthenticated()) {
    return <NotAuthorizedView />;
  } else if (dealerDetails?.error) {
    return <NotAuthorizedView />;
  } else if (siteCode || isDealer() || isAdmin) {
    return <Outlet />;
  } else {
    logAmplitudeEvent('login', translation);
    authService.signinRedirect();
    return <ActivityIndicator />;
  }
};
