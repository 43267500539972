import amplitude from "amplitude-js";
import { TranslationContext } from "../context/translations-context";
import { useContext } from "react";

export const useAmplitude = () => {
  const contextValue = useContext(TranslationContext);

  const [{ languageCountry }] = contextValue;

  const logAmplitudeEvent = (name = "unknown", translation, params = {}) => {
    try {
      const languageCountryValue = languageCountry();

      amplitude
        .getInstance()
        .logEvent(`pfs ${name}`.toLowerCase().replace(/\W+/g, " "), {
          ...params,
          //ver: global['pfs_app_version'],
          languageCountry: languageCountryValue.toLowerCase(),
        });
    } catch (error) {
      console.error("Error logging Amplitude event:", error);
    }
  };

  return [logAmplitudeEvent];
};
