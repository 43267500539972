import React, { useState, useContext, useEffect } from 'react';
import { CtaLink } from '../../components/common/cta/cta';
import { HeaderView, HEADER_TAB_STATES } from '../../components/sections/header-bar/header-bar';
import { Header } from '../../components/common/header/header';
import { ThreeColumnRow, Column } from '../../components/common/column/column';
import { LabeledData } from '../../components/common/labeled-data/labeled-data';
import { CustomerContext } from '../../context/customer-context';
import { ShoppingCartContext } from '../../context/shopping-cart-context';
import { MemberSearch } from '../../components/sections/member-search/member-search';
import { ModalLink } from '../../components/common/modal-link/modal-link';
import TabBar from '../../components/common/tabbar/tabbar';
import Tab from '../../components/common/tabbar/tab';
import { GoodwillPointsDialog } from '../../components/dialogs/goodwill-points-dialog/goodwill-points-dialog';
import { RewardsHistoryDialog } from '../../components/dialogs/rewards-history-dialog/rewards-history-dialog';
import { TermsNotAccepted } from '../../components/sections/terms-not-accepted/terms-not-accepted';
import { TabServiceView } from '../../components/tabs/tab-service-view/tab-service-view';
import { TabPartsView } from '../../components/tabs/tab-parts-view/tab-parts-view';
import { TabSalesView } from '../../components/tabs/tab-sales-view/tab-sales-view';
import { TabFandIView } from '../../components/tabs/tab-fi-view/tab-fi-view';
import utils from '../../utils/utils.module.scss';

import styles from './member-view.module.scss';
import { DealerContext } from '../../context/dealer-context';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { DealerAcknowledgeDialog } from '../../components/dialogs/dealer-acknowlege-dialog/dealer-acknowlege-dialog';
import { Odometer } from '../../components/common/odometer/odometer';
import { TranslationContext } from '../../context/translations-context';
import { formatPhone } from '../../utils/helpers';
import { SiteContext } from '../../context/site-context';
import cx from '../../utils/classnames';
import { TabConnectedServicesView } from '../../components/tabs/tab-connected-services-view/tab-connected-services-view';
import { MemberNotFoundDialog } from '../../components/dialogs/member-not-found/member-not-found';
import { AuthContext } from '../../context/auth-context';
// import { ConnectedServicesContext } from '../../context/connected-services-context';
// import { AltErrorText } from '../../components/common/error-text/error-text-alt';
// import { APIHelperContext } from '../../context/api-helper-context';

export enum TAB_STATES {
  TAB_SALES = 'Rewards Sales',
  TAB_SERVICE = 'Rewards Service',
  TAB_CONNECTEDSERVICES = 'Connected Services',
  TAB_PARTS = 'Parts & Accessories',
  TAB_FANDI = 'F&I',
}

export const MemberView = () => {
  const [{ t, formatDate }] = useContext(TranslationContext);
  const [{ userProfile, points, transactions, notFound }, { doSearch }] = useContext(CustomerContext);
  // const [{ garage }] = useContext(ConnectedServicesContext);
  // eslint-disable-next-line no-empty-pattern
  const [{}, { clearCart }] = useContext(ShoppingCartContext);
  const { isConnectedServAgent } = useContext(AuthContext);
  const [activeTabId, setActiveTabId] = useState(TAB_STATES.TAB_SALES as string);

  const [showMemberNotFound, setShowMemberNotFound] = useState<null | string>(null);
  const [{ dealerDetails, defaultTab, isConnectedServiceDealer, ConnectedServiceDealerWhitelist }] = useContext(DealerContext);
  // const [apiHelper] = useContext(APIHelperContext);
  const [moreInfo, setMoreInfo] = useState(false);
  const [{ isCanada }] = useContext(SiteContext);

  const NullComponent = () => null;
  const tabContents = {};
  tabContents[TAB_STATES.TAB_SERVICE] = TabServiceView;
  tabContents[TAB_STATES.TAB_SALES] = TabSalesView;
  tabContents[TAB_STATES.TAB_CONNECTEDSERVICES] = TabConnectedServicesView;
  tabContents[TAB_STATES.TAB_PARTS] = TabPartsView;
  tabContents[TAB_STATES.TAB_FANDI] = TabFandIView;
  tabContents['NULL_TAB'] = NullComponent;
  const TabContent = tabContents[activeTabId];


  useEffect(() => {
    if (isConnectedServAgent && !ConnectedServiceDealerWhitelist) {
        setActiveTabId('NULL_TAB')}
    else if (isConnectedServAgent && ConnectedServiceDealerWhitelist) {
      setActiveTabId(TAB_STATES.TAB_CONNECTEDSERVICES)}
    else if (!isConnectedServAgent && ConnectedServiceDealerWhitelist && isConnectedServiceDealer) {
      setActiveTabId(defaultTab) }

  }, [defaultTab, isConnectedServAgent, ConnectedServiceDealerWhitelist, isConnectedServiceDealer]);

  const showGoodwillLink =
    userProfile && dealerDetails?.isGoodwilJobRole && (points.eligibility?.F.status == 'ELIGIBLE' || points.eligibility?.L.status == 'ELIGIBLE');

  const today = new Date();
  const ninetyDays = new Date(new Date().setDate(today.getDate() + 90));

  return (
    <>
      {showMemberNotFound && <MemberNotFoundDialog doClose={() => setShowMemberNotFound(null)} />}

      <HeaderView tabID={HEADER_TAB_STATES.TAB_EARNREDEEM} />
      <DealerAcknowledgeDialog />
      <div className={styles.contentWrapper}>
        <ThreeColumnRow>
          <Column className={utils.greyBackground}>
            <Header title={t('MEMBER_SEARCH')}>
              <MemberSearch doSearch={doSearch} />
              <div className={styles.links}>
                <div>
                  <CtaLink
                    label={t('ENROLL_IN_FORDPASS_REWARDS')}
                    href={
                      isCanada()
                        ? 'https://www.ford.ca/support/fordpass/fordpass-rewards/overview/'
                        : 'https://www.ford.com/support/fordpass/fordpass-rewards/overview/'
                    }
                    target='_blank'
                  />
                </div>
                <div>
                  <CtaLink
                    label={t('ENROLL_IN_LINCOLN_ACCESS_REWARDS')}
                    href={
                      isCanada()
                        ? 'https://www.lincolncanada.com/support/lincoln-way-app/lincoln-access-rewards/overview/'
                        : 'https://www.lincoln.com/support/lincoln-way-app/lincoln-access-rewards/overview/'
                    }
                    target='_blank'
                  />
                </div>
                {!isCanada() && (
                  <>
                    <div>
                      <CtaLink
                        label={t('ENROLL_FORD_VISA')}
                        href='https://www.fmcdealer.dealerconnection.com/content/fmcdealer/en/us/Sales/fp/fbkc.html'
                        target='_blank'
                      />
                    </div>
                    <div>
                      <CtaLink
                        label={t('ENROLL_LINCOLN_VISA')}
                        href='https://www.fmcdealer.dealerconnection.com/content/fmcdealer/en/us/Sales/fp/fbkc.html'
                        target='_blank'
                      />
                    </div>
                  </>
                )}
              </div>
            </Header>
          </Column>
          <Column>
            <Header title={t('CURRENT_MEMBER')}>
              <LabeledData title={t('NAME')} data={userProfile ? `${userProfile.firstName} ${userProfile.lastName}` : null} />
              <LabeledData title={t('MEMBER_ID')} data={userProfile?.memberId} />
              <LabeledData title={t('USERNAME')} data={userProfile?.userName} />
              {userProfile && (
                <>
                  {!moreInfo ? (
                    <CtaLink label={t('More Info')} onClick={() => setMoreInfo(true)} />
                  ) : (
                    <>
                      <LabeledData title={t('PHONE_NUMBER')} data={formatPhone(userProfile?.phoneNumber)} />
                      <LabeledData title={t('ADDRESS')}>
                        {userProfile ? (
                          <>
                            {userProfile.address1?.replace(/\d/g, '-') || ''}
                            <br />
                            {userProfile?.city}, {userProfile?.state}
                          </>
                        ) : (
                          '--'
                        )}
                      </LabeledData>
                    </>
                  )}
                </>
              )}
              {showGoodwillLink && (
                <div>
                  <ModalLink title={t('GOODWILL_POINTS')}>
                    <GoodwillPointsDialog />
                  </ModalLink>
                </div>
              )}
            </Header>
          </Column>

          <Column>
            <Header title={t('FORDPASS_REWARDS')} className={utils.bottomSpacerLarge}>
              {points && points.eligibility?.F.status == 'ELIGIBLE' && (
                <>
                  {!isCanada() && points.eligibility?.F.memberStatus == 'A' && <LabeledData title={t('Member Status')} data={t('Active')} />}
                  {!isCanada() && points.eligibility?.F.memberStatus == 'I' && (
                    <LabeledData title={t('Member Status')}>
                      <>
                        <i className={cx(`fas fa-info-circle`)}></i>
                        {t('INACTIVE')}
                      </>
                    </LabeledData>
                  )}

                  {points.pointsTotals.F.recognitionTiers?.[0]?.tierName && (
                    <LabeledData title={t('Member Tier')} data={points.pointsTotals.F.recognitionTiers?.[0]?.tierName} />
                  )}
                  {!isCanada() && userProfile?.creditCardIndicatorStatusFord != undefined && (
                    <LabeledData title={t('CREDITCARD_FORD')} data={userProfile.creditCardIndicatorStatusFord ? t('YES') : t('NO')} />
                  )}

                  <LabeledData title={t('Redeemable Amount')}>
                    <>
                      <Odometer data={+points.pointsTotals.F.points} />
                      {` ${t('Points')} / `}
                      <Odometer data={+points.pointsTotals.F.points * +points.pointsTotals.F.conversionFactor} currency={true} />
                    </>
                  </LabeledData>
                  {points.pointsTotals.F.points ? (
                    <LabeledData
                      title={t('EXPIRES')}
                      data={formatDate(new Date(points.pointsTotals.F.pointExpirationDate))}
                      className={new Date(points.pointsTotals.F.pointExpirationDate) < ninetyDays ? styles.expired : ''}
                    />
                  ) : (
                    ''
                  )}
                  <ModalLink title={t('REWARDS_HISTORY')}>
                    <RewardsHistoryDialog rewardProgram='F' data={transactions?.filter((t: any) => t.rewardProgram == 'F')} />
                  </ModalLink>
                </>
              )}

              {userProfile && (!points || points.eligibility?.F.status != 'ELIGIBLE') && <TermsNotAccepted program='F' />}

              {!userProfile && t('Please search for a member by Member ID or Username')}
            </Header>
          </Column>
          <Column>
            <Header title={t('LINCOLN_ACCESS_REWARDS')}>
              {points && points.eligibility?.L.status == 'ELIGIBLE' && (
                <>
                  {!isCanada() && points.eligibility?.L.memberStatus == 'A' && <LabeledData title={t('Member Status')} data={t('Active')} />}
                  {!isCanada() && points.eligibility?.L.memberStatus == 'I' && (
                    <LabeledData title={t('Member Status')}>
                      <>
                        <i className={cx(`fas fa-info-circle`)}></i>
                        {t('INACTIVE')}
                      </>
                    </LabeledData>
                  )}

                  {points.pointsTotals.L.recognitionTiers?.[0]?.tierName && (
                    <LabeledData title={t('Member Tier')} data={points.pointsTotals.L.recognitionTiers?.[0]?.tierName} />
                  )}
                  {!isCanada() && userProfile?.creditCardIndicatorStatusLincoln != undefined && (
                    <LabeledData title={t('CREDITCARD_LINCOLN')} data={userProfile.creditCardIndicatorStatusLincoln ? t('YES') : t('NO')} />
                  )}

                  <LabeledData title={t('Redeemable Amount')}>
                    <>
                      <Odometer data={+points.pointsTotals.L.points} />
                      {` ${t('Points')} / `}
                      <Odometer data={+points.pointsTotals.L.points * +points.pointsTotals.L.conversionFactor} currency={true} />
                    </>
                  </LabeledData>
                  {points.pointsTotals.L.points ? (
                    <LabeledData
                      title={t('EXPIRES')}
                      data={formatDate(new Date(points.pointsTotals.L.pointExpirationDate))}
                      className={new Date(points.pointsTotals.L.pointExpirationDate) < ninetyDays ? styles.expired : ''}
                    />
                  ) : (
                    ''
                  )}

                  <ModalLink title={t('REWARDS_HISTORY')}>
                    <RewardsHistoryDialog rewardProgram='L' data={transactions?.filter((t: any) => t.rewardProgram == 'L')} />
                  </ModalLink>
                </>
              )}
              {userProfile && (!points || points.eligibility?.L.status != 'ELIGIBLE') && <TermsNotAccepted program='L' />}
              {!userProfile && t('Please search for a member by Member ID or Username')}
            </Header>
          </Column>
        </ThreeColumnRow>

        {notFound == true && (
          <div>
            <br />
            {t('To continue, the customer must complete the')} <CtaLink onClick={setShowMemberNotFound}>{t('Customer Onboarding Process')}</CtaLink>.
          </div>
        )}

        {userProfile && (points.eligibility?.F.status == 'ELIGIBLE' || points.eligibility?.L.status == 'ELIGIBLE') && (
          <>
            <br />
            <br />
            <br />
            {points?.pointsTotals && (
              <>
                <TabBar
                  setTab={(id: string) => {
                    clearCart();
                    setActiveTabId(id);
                  }}
                  active={activeTabId}
                >
                  {!isConnectedServAgent && <Tab id={TAB_STATES.TAB_SALES}>{t(TAB_STATES.TAB_SALES)}</Tab>}
                  {!isConnectedServAgent && <Tab id={TAB_STATES.TAB_SERVICE}>{t(TAB_STATES.TAB_SERVICE)}</Tab>}
                  {(isConnectedServiceDealer || (isConnectedServAgent && ConnectedServiceDealerWhitelist)) && (
                    <Tab id={TAB_STATES.TAB_CONNECTEDSERVICES}>
                      {t(TAB_STATES.TAB_CONNECTEDSERVICES)} <span className={styles.newBox}>{t('New!')}</span>
                    </Tab>
                  )}

                  {/* <Tab id={TAB_STATES.TAB_PARTS}>{t(TAB_STATES.TAB_PARTS)}</Tab>
                      <Tab id={TAB_STATES.TAB_FANDI}>{t(TAB_STATES.TAB_FANDI)}</Tab> */}
                </TabBar>
                <div className={styles.tabContent}>
                  <TabContent />
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};
