import React, { useContext, useEffect, useState } from 'react';
import styles from './select-vehicle.module.scss';
import { ConnectedServicesContext } from '../../../../../context/connected-services-context';
import { ErrorText } from '../../../../common/error-text/error-text';
import { TranslationContext } from '../../../../../context/translations-context';


export const SelectVehicle: any = ({ vehicles }: any) => {
  const [showMenu, setShowMenu] = useState(true);
  const [{ setVehicle, hasModem }] = useContext(ConnectedServicesContext);
  const [{ t }] = useContext(TranslationContext);

  useEffect(() => {
    if (showMenu) {
      document.addEventListener('mousedown', () => setShowMenu(true));
    }
    return () => document.removeEventListener('mousedown', () => setShowMenu(false));
  }, [showMenu]);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setShowMenu(true);
    const value = e.target.value;
    setVehicle(value.includes('Select a vehicle') ? null : JSON.parse(value));
  };

  const items = Object.values(handleChange);

  return (
    <div>
      <div className={styles.vehicleSelectContainer}>
        <select id='vehicleSelect' onChange={handleChange} className={vehicles?.length && hasModem ? '' : styles.errorSelect}>
          {vehicles?.length > 0 ? <option>{'- Select a vehicle -'}</option> : <option>No Vehicle Available</option>}
          {vehicles?.length &&
            vehicles.map((item: any, index: any) => (
              <option value={JSON.stringify(item)} key={index}>{`${item?.modelYear} ${item?.make} ${item?.modelName}`}</option>
            ))}
        </select>
      </div>
      {vehicles?.length == 0 && <ErrorText text={t('NO_CARS')} />}
      {!hasModem && <ErrorText text={t('NO_MODEM')} />}
    </div>
  );
};

export default SelectVehicle;
