// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BkDyqLWgiYOkrb31YcrG{width:100%}.AGu6KaCuIkTKRWntWADQ{width:100%}.AGu6KaCuIkTKRWntWADQ td,.AGu6KaCuIkTKRWntWADQ th{white-space:nowrap;width:13vw;max-width:200px;overflow:hidden;text-align:left}.AGu6KaCuIkTKRWntWADQ td:nth-child(2){min-width:20vw}.AGu6KaCuIkTKRWntWADQ td:nth-child(4),.AGu6KaCuIkTKRWntWADQ td:nth-child(5){text-align:right}.CxXp5b1yeOZekMyofFRd{background:#eee;border:solid 1px #ccc;padding:40px;text-align:center;display:inline-block;width:77vw;margin:30px}`, "",{"version":3,"sources":["webpack://./src/components/dialogs/rewards-history-dialog/rewards-history-dialog.module.scss"],"names":[],"mappings":"AACA,sBACE,UAAA,CAEF,sBACE,UAAA,CAEA,kDAEE,kBAAA,CACA,UAAA,CACA,eAAA,CACA,eAAA,CACA,eAAA,CAEF,sCACE,cAAA,CAEF,4EAEE,gBAAA,CAGJ,sBACE,eAAA,CACA,qBAAA,CACA,YAAA,CACA,iBAAA,CACA,oBAAA,CACA,UAAA,CACA,WAAA","sourcesContent":["@import \"main.scss\";\n.maxwidth {\n  width: 100%;\n}\n.fixed_header {\n  width: 100%;\n\n  td,\n  th {\n    white-space: nowrap;\n    width: 13vw;\n    max-width: 200px;\n    overflow: hidden;\n    text-align: left;\n  }\n  td:nth-child(2) {\n    min-width: 20vw;\n  }\n  td:nth-child(4),\n  td:nth-child(5) {\n    text-align: right;\n  }\n}\n.noData {\n  background: #eee;\n  border: solid 1px #ccc;\n  padding: 40px;\n  text-align: center;\n  display: inline-block;\n  width: 77vw;\n  margin: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"maxwidth": `BkDyqLWgiYOkrb31YcrG`,
	"fixed_header": `AGu6KaCuIkTKRWntWADQ`,
	"noData": `CxXp5b1yeOZekMyofFRd`
};
export default ___CSS_LOADER_EXPORT___;
