import React, { useContext, useEffect } from 'react';
import { CustomerContext } from '../../../context/customer-context';
import { ShoppingCartContext } from '../../../context/shopping-cart-context';
import { TranslationContext } from '../../../context/translations-context';
import { filterCatalog, filterOffers } from '../../../services/offers-service/offers-service';
import { CtaButton, CtaSecondaryButton } from '../../common/cta/cta';

import { ModalDialog, Body, Buttons } from '../../common/modal-dialog/modal-dialog';
interface Props {
  onClose?(): void;
  setUnusedOffers?(value): void;
}

export const UnusedOffersDialog = ({ onClose, setUnusedOffers }: Props) => {
  const [{ t }] = useContext(TranslationContext);
  const [{ rewardProgram, offers, availablePoints }] = useContext(CustomerContext);
  const [{ selectedOffers, selectedRewards, redeemPoints, salesTab }] = useContext(ShoppingCartContext);

  useEffect(() => {
    const salesOffers = filterOffers(offers?.[rewardProgram].drc, salesTab ? 'VX' : 'SX');
    const rewards = filterCatalog(offers?.[rewardProgram].rewards, salesTab ? 'VX' : 'SX');
    if (
      (salesOffers.length + rewards.length == 0 || selectedOffers.length + selectedRewards.length > 0) &&
      (redeemPoints > 0 || availablePoints() == 0)
    )
      setUnusedOffers(false);
  });

  return (
    <ModalDialog title={t('UNUSED_OFFERS_TITLE')}>
      <Body>{t(salesTab ? 'UNUSED_OFFERS_SALES' : 'UNUSED_OFFERS_SERVICE')}</Body>
      <Buttons>
        <CtaSecondaryButton label={t('CANCEL')} onClick={onClose} />

        <CtaButton
          onClick={() => {
            setUnusedOffers(false);
          }}
          label={t('Confirm')}
        />
      </Buttons>
    </ModalDialog>
  );
};
