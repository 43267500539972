import amplitude from 'amplitude-js';
import React, { useContext } from 'react';
import { ActivityIndicator } from '../../components/common/activity-indicator/activity-indicator';
import { AuthContext } from '../../context/auth-context';

export const AuthTokenRenew = () => {
  const { signinSilentCallback } = useContext(AuthContext);
  amplitude.getInstance().logEvent('pfs AuthTokenRenew');
  signinSilentCallback();
  return <ActivityIndicator />;
};
