// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ARHq6IGpvTz1Rzu92_6g{margin:30px 50px}.yw4SHYhpU7tZMO7dIKBT{border:solid 2px #ccc;border-top:none;min-height:100vh;padding:40px}`, "",{"version":3,"sources":["webpack://./src/views/reports-view/reports-view.module.scss"],"names":[],"mappings":"AACA,sBACE,gBAAA,CAEF,sBACE,qBAAA,CACA,eAAA,CACA,gBAAA,CACA,YAAA","sourcesContent":["@import \"main.scss\";\n.contentWrapper {\n  margin: 30px 50px;\n}\n.tabContent {\n  border: solid 2px #ccc;\n  border-top: none;\n  min-height: 100vh;\n  padding: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentWrapper": `ARHq6IGpvTz1Rzu92_6g`,
	"tabContent": `yw4SHYhpU7tZMO7dIKBT`
};
export default ___CSS_LOADER_EXPORT___;
