/* istanbul ignore file */
import React, { useState, useContext } from 'react';
import { TranslationContext } from './translations-context';
import { AlertDialogContext } from './alert-dialog-context';
import { SiteContext } from './site-context';
import amplitude from 'amplitude-js';

export const APIHelperContext = React.createContext<any>({
  hasError: null,
  handleError: null,
  languageCountry: null,
});
interface Props {
  children: React.ReactElement;
}

export const APIHelperContextProvider = ({ children }: Props) => {
  const [hasError, setHasError] = useState<string | null>(null);
  const [{ t, language, languageCountry }] = useContext(TranslationContext);
  const [{ siteCode, countryCode }] = useContext(SiteContext);

  const [{ setShowAlert }] = useContext(AlertDialogContext);

  const handleError = (error: any, service: string, guid: string, data: string) => {
    if (error.toString().includes('404')) return;
    if (error.toString().includes('Network Error')) return;
    const whitelist = [
      'guid cannot be empt',
      'value cannot be emp',
      'memberId is invalid',
      '[TRN_CANCELED_DUE_T',
      '[TRN_TRANSACTION_AL',
      'userName is invalid',
      'totalNet is invalid',
      '[TRN_NOT_ENOUGH_POI',
      'invoiceNumberStarts',
      '[TRN_REDEMPTION_BLO',
      '[MULTIPLE_REMOTE_EX',
      '[TRN_NOT_ENOUGH_POI',
    ];
    const msg = error?.response?.data?.error?.message || 'none';
    if (whitelist.includes(msg.substr(0, 19))) {
      amplitude.getInstance().logEvent('pfs handleUserError', { error, guid, service, data, ver: global['pfs_app_version'], message: msg });
    } else {
      amplitude.getInstance().logEvent('pfs handleError', { error, guid, service, data, ver: global['pfs_app_version'], message: msg });
    }
    setHasError(service);
  };

  const displayErrorMsg = (msg: string) => setShowAlert(msg);

  return (
    <APIHelperContext.Provider value={[{ hasError, handleError, language, languageCountry, siteCode, countryCode, displayErrorMsg, t }]}>
      {children}
    </APIHelperContext.Provider>
  );
};
