import { APIHelper } from '../../models/api-helper';
import { post } from '../generic-service';
import ServiceHandler from '../service-handler';

// This service returns all the stars id's of dealer employees for given site code

export default class StarsIdService {
  public async request(apiHelper: APIHelper) {
    const data = {
      dlrSubCode: '',
      language: apiHelper.languageCountry(),
      partnerCountry: apiHelper.countryCode,
      siteCode: apiHelper.siteCode,
    };

    return await post('STARS_ID_LOOKUP', null, null, data, null, apiHelper, ServiceHandler.ConfigService.DSEC_BASE_URL);
  }
}
