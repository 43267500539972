// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sUBMNCZ1nDenE4MYxjfS{display:flex;justify-content:center;margin:10px 0}`, "",{"version":3,"sources":["webpack://./src/components/common/qr-code/qr-code.module.scss"],"names":[],"mappings":"AACA,sBACI,YAAA,CACA,sBAAA,CACA,aAAA","sourcesContent":["@import \"main.scss\";\n.title{\n    display: flex;\n    justify-content: center;\n    margin: 10px 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `sUBMNCZ1nDenE4MYxjfS`
};
export default ___CSS_LOADER_EXPORT___;
