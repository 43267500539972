import React from 'react';
import styles from './header.module.scss';

interface Props {
  title: string;
  className?: string;
  children: React.ReactNode;
  icon?: string;
}

export function Header({ title, className, icon, children }: Props) {
  return (
    <div className={className}>
      <div className={styles.header}>
        {icon && <i className={`fas fa-${icon}`}></i>}
        {title}
      </div>
      {children}
    </div>
  );
}
