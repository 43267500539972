class ConfigService {
  public GOOGLE_ID: string;
  public LPS_BASE_URL: string;
  public DSEC_BASE_URL: string;

  public APP_BASEPATH: string;

  public AEM_BASE_URL: string;
  public AMPLITUDE_API_KEY: string;

  public initDataAttributes() {
    this.LPS_BASE_URL = process.env.REACT_APP_LPS_BASE_URL;
    this.DSEC_BASE_URL = process.env.REACT_APP_DSEC_BASE_URL;
    this.APP_BASEPATH = process.env.REACT_APP_BASEPATH;
    this.AEM_BASE_URL = process.env.REACT_APP_AEM_BASE_URL;
    this.AMPLITUDE_API_KEY = process.env.AMPLITUDE_API_KEY;
  }
}

export default ConfigService;
