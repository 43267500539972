// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qxoL15Cm1UKO748WYz7e{margin-top:20px;font-weight:400;font-size:16px;line-height:24px}.qxoL15Cm1UKO748WYz7e i{color:red;padding-right:10px}.qxoL15Cm1UKO748WYz7e div{font-size:20px;font-weight:400;padding-bottom:10px}`, "",{"version":3,"sources":["webpack://./src/components/sections/terms-not-accepted/terms-not-accepted.module.scss"],"names":[],"mappings":"AACA,sBACE,eAAA,CAKA,eAAA,CACA,cAAA,CACA,gBAAA,CANA,wBACE,SAAA,CACA,kBAAA,CAKF,0BACE,cAAA,CACA,eAAA,CACA,mBAAA","sourcesContent":["@import \"main.scss\";\n.wrapper {\n  margin-top: 20px;\n  i {\n    color: red;\n    padding-right: 10px;\n  }\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 24px;\n  div {\n    font-size: 20px;\n    font-weight: 400;\n    padding-bottom: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `qxoL15Cm1UKO748WYz7e`
};
export default ___CSS_LOADER_EXPORT___;
