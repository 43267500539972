import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CtaLink, CtaSecondaryButton } from '../../common/cta/cta';
import TabBar from '../../common/tabbar/tabbar';
import Tab from '../../common/tabbar/tab';
import logoEn from '../../../../assets/png/logo-en.png';
import logoFr from '../../../../assets/png/logo-fr.png';
import styles from './header-bar.module.scss';
import utils from '../../../utils/utils.module.scss';
import { FontAwesome } from '../../common/font-awesome/font-awesome';
import ServiceHandler from '../../../services/service-handler';
import { TranslationContext } from '../../../context/translations-context';
import { CustomerContext } from '../../../context/customer-context';
import cx from '../../../utils/classnames';
import { DealerContext } from '../../../context/dealer-context';
import { SiteContext } from '../../../context/site-context';
import { ShoppingCartContext } from '../../../context/shopping-cart-context';
import { PACodeDialog } from '../../dialogs/pacode-dialog/pacode-dialog';
import { AlertDialog } from '../../dialogs/alert-dialog/alert-dialog';
import { AuthContext } from '../../../context/auth-context';
import { DealerAlertBar } from '../dealer-alert-bar/dealer-alert-bar';
import { ContactUsDialog } from '../../dialogs/contact-us/contact-us-dialog';

export enum HEADER_TAB_STATES {
  TAB_EARNREDEEM = '/',
  TAB_TRANSACTIONS = '/transactions',
  TAB_TRAINING = '/training',
  TAB_REPORTS = '/reports',
}
interface Props {
  tabID: string;
  className?: string;
}

export const HeaderView = ({ tabID, className }: Props) => {
  const [showLogout, setShowLogout] = useState(false);
  const [showLanguage, setShowLanguage] = useState(false);
  const [{ userProfile }, { doSearch }] = useContext(CustomerContext);
  // eslint-disable-next-line no-empty-pattern
  const [{}, { clearSearch }] = useContext(CustomerContext);

  // eslint-disable-next-line no-empty-pattern
  const [{}, { clearCart }] = useContext(ShoppingCartContext);
  const [{ dealerDetails, dealerPaCodes, isConnectedServiceDealer }] = useContext(DealerContext);
  const [{ siteCode, setSiteCode, isCanada }] = useContext(SiteContext);
  const { isDealer, isAdmin, isConnectedServAgent } = useContext(AuthContext);
  const [showContactBtn, setShowContactBtn] = useState<null | string>(null);

  const [{ t, language, setLanguage, languagesSupported }] = useContext(TranslationContext);

  const navigate = useNavigate();

  const HEADER_TAB_TEXT: any = [];
  HEADER_TAB_TEXT[HEADER_TAB_STATES.TAB_EARNREDEEM] = t('HOME');
  HEADER_TAB_TEXT[HEADER_TAB_STATES.TAB_TRANSACTIONS] = t('TRANSACTIONS');
  HEADER_TAB_TEXT[HEADER_TAB_STATES.TAB_TRAINING] = t('TRAINING');
  HEADER_TAB_TEXT[HEADER_TAB_STATES.TAB_REPORTS] = t('REPORTS');

  useEffect(() => {
    function handleClickOutside() {
      setShowLogout(false);
      setShowLanguage(false);
    }
    if (showLogout || showLanguage) {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [showLogout, showLanguage]);

  return (
    <>
      {showContactBtn && <ContactUsDialog make={showContactBtn} doClose={() => setShowContactBtn(null)} />}

      <div className={cx(styles.headerWrapper, className)}>
        <img src={language == 'en' ? logoEn : logoFr} alt='logo' style={{ height: '35px' }} />
        <div style={{ flexGrow: 4 }} />
        <TabBar
          setTab={(id: string) => {
            navigate(id);
          }}
          active={tabID}
          className={styles.tabBar}
        >
          <Tab id={HEADER_TAB_STATES.TAB_EARNREDEEM}>{HEADER_TAB_TEXT[HEADER_TAB_STATES.TAB_EARNREDEEM]}</Tab>

          {!isConnectedServAgent && <Tab id={HEADER_TAB_STATES.TAB_TRANSACTIONS}>{HEADER_TAB_TEXT[HEADER_TAB_STATES.TAB_TRANSACTIONS]}</Tab>}
          {!isConnectedServAgent && <Tab id={HEADER_TAB_STATES.TAB_TRAINING}>{HEADER_TAB_TEXT[HEADER_TAB_STATES.TAB_TRAINING]}</Tab>}
          {!isConnectedServAgent && (dealerDetails?.isReportRole || isAdmin) && (
            <Tab id={HEADER_TAB_STATES.TAB_REPORTS}>{HEADER_TAB_TEXT[HEADER_TAB_STATES.TAB_REPORTS]}</Tab>
          )}
        </TabBar>
        <div style={{ flexGrow: 1 }} />
        {isConnectedServiceDealer && (
          <CtaSecondaryButton onClick={setShowContactBtn} className={styles.contactUsBtn}>
            {t('Contact Us')}
          </CtaSecondaryButton>
        )}
        {isCanada() && (
          <div onClick={() => setShowLanguage(true)} className={utils.menuWrapper}>
            {showLanguage && (
              <div className={utils.menu}>
                {Object.values(languagesSupported).map((lang: string, i: number) => (
                  <div
                    key={lang}
                    onMouseDown={() => {
                      setLanguage(Object.keys(languagesSupported)[i]);
                      clearCart();
                      doSearch(userProfile?.memberId, false);
                    }}
                  >
                    {lang}
                  </div>
                ))}
              </div>
            )}
            <FontAwesome icon='caret-down' />
            <CtaLink label={languagesSupported[language]} />
          </div>
        )}

        <div onClick={() => setShowLogout(true)} className={utils.menuWrapper}>
          {showLogout && (
            <div className={utils.menu}>
              <>
                {dealerPaCodes?.length > 1 &&
                  dealerPaCodes.map(dealer => (
                    <div
                      key={dealer.code}
                      onMouseDown={() => {
                        setSiteCode(dealer.code);
                        clearSearch();
                      }}
                    >
                      {siteCode === dealer.code ? <span>&#10004;</span> : <span> &nbsp; &nbsp;</span>} {dealer.code}
                    </div>
                  ))}

                {!isDealer() && <div onMouseDown={() => navigate('/change-site-code')}>{t('ChangeSiteCode')}</div>}
              </>

              <div onMouseDown={() => navigate('/logout')}>{t('LOGOUT')}</div>
            </div>
          )}
          <FontAwesome icon='user' prefix='far' />
          <CtaLink label={`${ServiceHandler.AuthenticationService.getDealerUserName()}(${siteCode})`} />
        </div>
      </div>
      <DealerAlertBar />
      <AlertDialog />
      <PACodeDialog />
      <div style={{ clear: 'both' }} />
    </>
  );
};
