import React, { useContext, useState } from 'react';
import cx from '../../../utils/classnames';
import utils from '../../../utils/utils.module.scss';
import { RewardProgramChoice } from '../../sections/choice-reward-program/choice-reward-program';
import { PointsSection } from '../../sections/points-section/points-section';
import { ThreeColumnRow, TwoColumnRow, Column, DoubleColumn } from '../../common/column/column';
import { OffersSection } from '../../sections/offers-section/offers-section';
import { ShoppingCartSection } from '../../sections/shopping-cart-section/shopping-cart-section';
import { Header } from '../../common/header/header';

import { InputsContext } from '../../../context/inputs-context';
import { InputWithLabel } from '../../common/Input-with-label/input-with-label';
import { CustomerContext } from '../../../context/customer-context';
import { TranslationContext } from '../../../context/translations-context';
import { OffersRedeemableSection } from '../../sections/offers-redeemable-section/offers-redeemable-section';
import OffersService, { filterOffers, filterCatalog } from '../../../services/offers-service/offers-service';
import { APIHelperContext } from '../../../context/api-helper-context';
import { AlertDialogContext } from '../../../context/alert-dialog-context';
import { ShoppingCartContext } from '../../../context/shopping-cart-context';
import { useAmplitude } from '../../../hooks/useAmplitude';
import { CurrencyInput } from '../../common/formatted-input/formatted-input';
import { encryptFormData } from '../../../utils/url.utils';
import { SaleDatePicker } from '../../sections/sale-date/sale-date';
import { SuccessDialog } from '../../dialogs/success-dialog/success-dialog';

export const TabServiceView = () => {
  const [{ t }] = useContext(TranslationContext);
  const [{ invoiceNumber, invoiceAmount, saleDate }, { setInvoiceNumber, setInvoiceAmount }, { validateInvoice }] = useContext(InputsContext);
  const [{ userProfile, rewardProgram, offers }, { doSearch }] = useContext(CustomerContext);
  const [{ discount }, { setRedeemFetching, clearCart }, { validatePoints }] = useContext(ShoppingCartContext);

  const [apiHelper] = useContext(APIHelperContext);
  const [{ setShowAlert }] = useContext(AlertDialogContext);
  const [logAmplitudeEvent] = useAmplitude();
  const [translation] = useContext(TranslationContext);
  const [{ selectedOffers, selectedRewards }] = useContext(ShoppingCartContext);
  const [showSuccess, setShowSuccess] = useState(false);

  const submitTransaction = (formdata, onSuccess) => {
    formdata.append('totalNet', invoiceAmount ? Math.abs(invoiceAmount - discount).toFixed(2) : '0');
    formdata.append('totalGross', invoiceAmount.toFixed(2));
    formdata.append('invoiceNumber', invoiceNumber);
    formdata.append('serviceDate', saleDate.toISOString());

    const offersService = new OffersService();
    setRedeemFetching(true);
    offersService.redeemServiceOffer(userProfile, { rewardProgram: rewardProgram, partnerCategory: 'D' }, formdata, apiHelper).then(response => {
      setRedeemFetching(false);
      if (response?.pointBalance) {
        setShowSuccess(true);

        logAmplitudeEvent('service', translation, {
          offers: selectedOffers.length,
          rewards: selectedRewards.length,
          guid: userProfile.lighthouseGuid,
        });
        onSuccess(response.pointBalance);
      } else {
        setShowAlert({
          title: t('ERROR'),
          message: offersService.getError(response, t, 'service'),
        });

        logAmplitudeEvent('service error', translation, {
          ...response,
          msg: (response.data?.error?.message || '').substr(0, 50),
          form: encryptFormData(formdata),
          guid: userProfile.lighthouseGuid,
        });

        doSearch(userProfile.memberId, false);
      }
    });
  };

  const validateSaleDate = () => (saleDate == null ? t('Service date required') : null);
  const validateInvoiceAmount = () => (invoiceAmount <= 0 || invoiceAmount > 99999.99 ? t('Invalid service invoice amount') : null);

  const closeSuccessDlg = () => {
    setShowSuccess(false);
    clearCart();
    doSearch(userProfile.memberId, false);
  };

  return (
    <ThreeColumnRow>
      {showSuccess && <SuccessDialog doClose={closeSuccessDlg} />}
      <DoubleColumn>
        <RewardProgramChoice uniqueID='TabServiceView' />
        <Header title={t('Invoice')} className={utils.section}>
          <TwoColumnRow>
            <Column className={utils.noPadding}>
              <InputWithLabel
                label={t('RO Number')}
                id='invoiceNumber'
                value={invoiceNumber}
                onChange={e => setInvoiceNumber(e.target.value.toUpperCase().replace(/[^0-9A-Z]/g, ''))}
                validator={validateInvoice}
                maxLength={8}
              />
            </Column>
            <Column className={cx(utils.marginLeft30, utils.noPadding)}>
              <CurrencyInput label={t('Amount')} id='invoiceAmount' value={invoiceAmount} setValue={setInvoiceAmount} />
            </Column>
          </TwoColumnRow>
        </Header>
        <SaleDatePicker title={t('Vehicle Service Date')} />

        <PointsSection />
        <Header title={t('Points Offers')} icon='tags' className={cx(utils.marginTop50)}>
          <OffersRedeemableSection offers={filterOffers(offers?.[rewardProgram].dro, 'XS')} />
        </Header>
        <OffersSection
          title={`${rewardProgram == 'F' ? 'Ford ' : 'Lincoln'} ${t('Offers')}`}
          offers={filterOffers(offers?.[rewardProgram].drc, 'XS')}
          rewards={filterCatalog(offers?.[rewardProgram].rewards, 'XS')}
        />
      </DoubleColumn>
      <Column>
        <ShoppingCartSection
          title={t('Invoice Amount')}
          amount={invoiceAmount}
          validators={[validateInvoice, validatePoints, validateSaleDate, validateInvoiceAmount]}
          submitTransaction={submitTransaction}
        />
      </Column>
    </ThreeColumnRow>
  );
};
