import React, { useState, useContext, useEffect } from 'react';
import utils from '../../../utils/utils.module.scss';

import styles from './offers-section.module.scss';
import { CtaButton, CtaLink } from '../../common/cta/cta';
import { TranslationContext } from '../../../context/translations-context';
import { ActivityIndicator, ActivityIndicatorSmall } from '../../common/activity-indicator/activity-indicator';
import { CustomerContext } from '../../../context/customer-context';
import { AlertDialogContext } from '../../../context/alert-dialog-context';
import OffersService from '../../../services/offers-service/offers-service';
import { APIHelperContext } from '../../../context/api-helper-context';
import { currencyToFloat } from '../../common/currency/currency';
import { AuthContext } from '../../../context/auth-context';

interface Props {
  offers: any;
  redeem?(offer: string): void;
}

export const OffersRedeemableSection = ({ offers, redeem }: Props) => {
  const [{ t, formatCurrency }] = useContext(TranslationContext);
  const [{ userProfile, rewardProgram }, { doSearch }] = useContext(CustomerContext);
  const [{ setShowAlert }] = useContext(AlertDialogContext);
  const [apiHelper] = useContext(APIHelperContext);

  const [showDetails, setShowDetails] = useState(-1);
  const [loading, setLoading] = useState('');
  const { isOk2Submit } = useContext(AuthContext);

  useEffect(() => {
    setShowDetails(-1);
  }, [rewardProgram]);

  const redeemOffer = (offer: any) => {
    setLoading(offer.barcode);
    const formData = new FormData();

    formData.append('couponBarcode', offer.barcode);
    formData.append(
      'couponName',
      offer.couponTypeName + (offer.cpnDiscountAmount ? `: ${(+offer.cpnDiscountAmount / 0.005).toLocaleString()} Points` : '')
    );
    formData.append('rewardProgram', rewardProgram);
    formData.append('memberGuid', userProfile.lighthouseGuid);
    formData.append('email', userProfile.userName);
    formData.append('firstName', userProfile.firstName.replace(/‘/g, ''));
    formData.append('lastName', userProfile.lastName.replace(/‘/g, ''));
    formData.append('city', userProfile.city);
    formData.append('state', userProfile.state);

    const offerService = new OffersService();

    const _setShowAlert = (alert: any) => {
      setLoading('');
      setShowAlert(alert);
    };

    offerService.redeemOffer(formData, apiHelper, _setShowAlert, t, () => {
      offer.barcode = null;
      doSearch(userProfile.memberId, false);
      setLoading('');
    });
  };

  return (
    <table className={styles.table}>
      <thead className={styles.header}>
        <tr>
          <th colSpan={4}>Name</th>
        </tr>
      </thead>
      <tbody>
        {offers?.length ? (
          offers.map((offer: any, i: number) => (
            <React.Fragment key={offer.barcode}>
              <tr className={styles.row}>
                <td>
                  <label htmlFor={`offercx${i}`}>
                    {offer.couponTypeName}
                    {currencyToFloat(offer.cpnDiscountAmount) > 0 ? `: ${formatCurrency(currencyToFloat(offer.cpnDiscountAmount))}` : ''} {!offer.couponTypeAttributes?.some(attr => attr.code === "redeemButtonDisable" && attr.value !== 0) ? null : <span className={styles.newBox}>{t('New!')}</span>}
                    {offer.remaining && <span className={styles.remaining}>{` ( ${offer.remaining} ${t('remaining')} )`}</span>}
                  </label>
                </td>
                <td>
                  {loading == offer.barcode ? (
                    <ActivityIndicatorSmall />
                  ) : (
                    <>
                      {isOk2Submit && offer.barcode && !offer.couponTypeAttributes?.some(attr => attr.code === "redeemButtonDisable" && attr.value !== 0) ? (
                        <CtaButton
                          label={t('Redeem')}
                          onClick={() => {
                            if (redeem) redeem(offer);
                            else redeemOffer(offer);
                          }}
                        />
                      ) : (
                        null
                      )}
                    </>
                  )}
                </td>
                <td>
                  <CtaLink onClick={() => setShowDetails(showDetails == i ? -1 : i)} label={showDetails == i ? t('Hide') : t('Details')} />
                </td>
              </tr>

              {showDetails == i && (
                <tr>
                  <td colSpan={3} className={styles.details}>
                    <div>
                      <span>{t('Description')}: </span>
                      <div dangerouslySetInnerHTML={{ __html: offer.rewardDescription || offer.description }} />
                    </div>
                    <div>
                      <span>{t('Legal Disclaimer')}: </span>
                      <div dangerouslySetInnerHTML={{ __html: offer.rewardLegalText || offer.legalDisclaimer }} />
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))
        ) : (
          <tr>
            <td colSpan={3}>{offers == null ? <ActivityIndicator /> : <div className={utils.emptyList}>{t('No offers available')}</div>}</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};
