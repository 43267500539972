import React, { useContext, useState } from 'react';
import { TranslationContext } from '../../../context/translations-context';
import cx from '../../../utils/classnames';

import styles from './terms-not-accepted.module.scss';
// import { CtaLink } from '../../common/cta/cta';
import { RewardsActivationDialog } from '../../dialogs/rewards-activation/rewards-activation-dialog';

interface Props {
  program: string;
}

export const TermsNotAccepted = ({ program }: Props) => {
  const [{ t }] = useContext(TranslationContext);
  const [showActivationDialog, setShowActivationDialog] = useState<null | any>(null);

  return (
    <>
    <div>
      {showActivationDialog && <RewardsActivationDialog program={showActivationDialog} doClose={() => setShowActivationDialog(null)} make={''} />}

      <div className={styles.wrapper}>
        <div>
          <i className={cx(`fas fa-info-circle`)}></i>
          {t('NOT_MEMBER').replace('{program}', t(program))}
        </div>
        {/* <CtaLink onClick={setShowActivationDialog}>{t('HELP_ENROLL')}</CtaLink> */}
        {/* TODO: intentionally commented out, not releasing yet */}
      </div>
    </div>
    </>
  );
};
