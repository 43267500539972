// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tvbg1LopK96Ssu8DkrF1{display:none}`, "",{"version":3,"sources":["webpack://./src/components/dialogs/success-dialog/success-dialog.module.scss"],"names":[],"mappings":"AACA,sBACE,YAAA","sourcesContent":["@import \"main.scss\";\n.successInvoice {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"successInvoice": `tvbg1LopK96Ssu8DkrF1`
};
export default ___CSS_LOADER_EXPORT___;
