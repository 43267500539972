import { APIHelper } from '../../models/api-helper';
import { get } from '../generic-service';

export const REPORT_TYPE = {
  MonthlyDealerLiabilityReport: 'MonthlyDealerLiabilityReport',
  DealerReimbursement: 'DealerReimbursement',
  DealerGoodwill: 'DealerGoodwill',
  VehiclePurchase: 'PmpReports',
  DealerCompensation: 'DealerCompensation',
  ActiveSubscriptions: 'activation', //temp change, delete line later
  ExpiredSubscriptions: 'expired', //temp change, delete line later
  CancelledSubscriptions: 'cancelled', //temp change, delete line later
  // ActiveSubscriptions: 'ActiveSubscriptions',
  // ExpiredSubscriptions: 'ExpiredSubscriptions',
  // CancelledSubscriptions: 'CancelledSubscriptions',
};

export default class ReportDownloadService {
  public async downloadReport(filepath: string, apiHelper: APIHelper, errorMsg: string) {
    const filename = filepath.substring(filepath.lastIndexOf('/') + 1);

    get('REPORT_DOWNLOAD', filename, {}, apiHelper, 'blob', '&filePath=' + filepath).then(response => {
      if (response?.status == 'error') {
        return apiHelper.displayErrorMsg({ title: apiHelper.t('Unable to Download'), message: errorMsg });
      }

      const blob = new Blob([response], { type: 'application/zip' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename); //or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  }
}
