import React, { useContext, useEffect, useState } from 'react';
import styles from './select-stars-id.module.scss';
import { usePascalCase } from '../../../../../utils/url.utils';
import { ConnectedServicesContext } from '../../../../../context/connected-services-context';
import { TranslationContext } from '../../../../../context/translations-context';

export const SelectStars: any = ({ options }: any) => {
  const [{ t }] = useContext(TranslationContext);
  const [showMenu, setShowMenu] = useState(true);
  const [{ setSelectedStarsId, clearConnectedServices }] = useContext(ConnectedServicesContext);

  useEffect(() => {
    if (showMenu) {
      document.addEventListener('mousedown', () => setShowMenu(true));
    }
    return () => document.removeEventListener('mousedown', () => setShowMenu(false));
  }, [showMenu]);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value;
    setShowMenu(true);
    setSelectedStarsId(selectedValue);
    if (!selectedValue) clearConnectedServices();
  };

  const items = Object.values(handleChange);

  return (
    <div>
      <div className={styles.selectStarsContainer}>
        <select id='starsSelect' onChange={handleChange}>
          <option value=''>– {t('Select your STARS ID for Attribution')} –</option>
          {options.map((item: any, index: number) => (
            <option value={item.starsId} key={index}>{`${item.starsId} - ${usePascalCase(item.firstName)} ${usePascalCase(item.lastName)}`}</option>
          ))}
          <option value='000000000'>{t('Not Applicable')}</option>
          <option value='111111111'>Nexus</option>
        </select>
      </div>
    </div>
  );
};
