import React, { useContext, useEffect, useState } from 'react';
import DealerDetailsService from '../services/dealer-details-service/dealer-details-service';
import { APIHelperContext } from './api-helper-context';
import { TAB_STATES } from '../views/member-view/member-view';
import amplitude from 'amplitude-js';
import { AuthContext } from './auth-context';
import { SiteContext } from './site-context';

export const DealerContext = React.createContext<any>({
  message: '',
});

const dealerSales = [
  'ACCTNT',
  'ADFCMG',
  'AOFCMG',
  'ASLSMG',
  'BDCMGR',
  'BUSMGR',
  'CDTMGR',
  'CONTRL',
  'CRMGR',
  'DEALER',
  'DGTMKT',
  'GENMGR',
  'INTCRD',
  'OFCMGR',
  'SLSCLTFV',
  'SLSCLT',
  'SLSMGR',
  'SMEGSM',
];

const service = ['ASVCMG', 'CASHR', 'CTSADV', 'PTSSDR', 'QLNCSR', 'QLNMGR', 'SVCADV', 'SVCMGR', 'WRNCLK'];
const parts = ['PTSCTR', 'PTSCTS', 'PTSGEN', 'PTSMGR', 'PTSMNGR'];
const fAndI = ['F&IMGR'];

interface Props {
  children: React.ReactNode;
}

export const DealerContextProvider = ({ children }: Props) => {
  const [dealerDetails, setDealerDetails] = useState(null);
  const [dealerPaCodes, setDealerPaCodes] = useState([]);

  const { isDealer, isLocal, isQA } = useContext(AuthContext);

  const [retry, setRetry] = useState(false);
  const [defaultTab, setDefaultTab] = useState(TAB_STATES.TAB_SALES as string);
  const [apiHelper] = useContext(APIHelperContext);
  const [isConnectedServiceDealer, setIsConnectedServiceDealer] = useState(false);

  const authenticated = isDealer();

  const ConnectedServiceDealerWhitelist = [
    '02741',
    '11472',
    '04927',
    '07909',
    '08110',
    '05892',
    '04945',
    '04921',
    '00025',
    '06888',
    '07402',
    '04758',
    '05471',
    '07742',
    '07820',
    'B8144',
    'A8044',
    'A6065',
    'B8002',
    'A2058',
    'B6249',
    'B6269',

    '00004',
    '00100',
    '03932',
    '05357',
    '01933',
    '02345',
    '07247',
    '29995',
    '04961',
    '00783',
    '03854',
    '00724',
    '01855',
    '01886',
    '02083',
    '09268',
    '29508',
    '05317',
    '00486',
    '02068',
    '00169',
    '01268',
  ];

  if (isQA || isLocal) ConnectedServiceDealerWhitelist.push(...['08888', '06462', 'A1323']);

  useEffect(() => {
    if (authenticated && apiHelper.siteCode && !retry) {
      window.setTimeout(() => {
        const dealerDetailsService = new DealerDetailsService();
        dealerDetailsService.request(apiHelper).then((details: any) => {
          setDealerDetails(details);
          const dealer = details?.dealer;

          if (!dealer) {
            amplitude.getInstance().logEvent('pfs dealerdetails error', { dealerDetails, details, retry });
            setRetry(true);
          } else {
            if (dealerSales.includes(details.role) || fAndI.includes(details.role)) setDefaultTab(TAB_STATES.TAB_SALES as any);
            if (service.includes(details.role) || parts.includes(details.role)) setDefaultTab(TAB_STATES.TAB_SERVICE as any);

            setDealerPaCodes([
              { code: dealer.paCode },
              ...(dealer.secondaryDealers || []).map(code => ({
                code: code,
              })),
            ]);
          }
        });
      }, 200);
    }
  }, [apiHelper.siteCode, retry]);

  useEffect(() => {
    if (apiHelper.siteCode) setIsConnectedServiceDealer(ConnectedServiceDealerWhitelist.includes(apiHelper.siteCode));
  }, [apiHelper.siteCode]);

  return <DealerContext.Provider value={[{ dealerDetails, dealerPaCodes, defaultTab, isConnectedServiceDealer }]}>{children}</DealerContext.Provider>;
};
