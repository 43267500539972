// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.T0MZL3ilkZR_OGsD_SeO{min-width:245px}.XPcvvHhFDRq2CFyvLzaL label{transform:translateY(-35px);font-size:16px}`, "",{"version":3,"sources":["webpack://./src/components/sections/sale-date/sale-date.module.scss"],"names":[],"mappings":"AACA,sBACE,eAAA,CAGA,4BACE,2BAAA,CACA,cAAA","sourcesContent":["@import \"main.scss\";\n.saleDate {\n  min-width: 245px;\n}\n.labelTweak {\n  label {\n    transform: translateY(-35px);\n    font-size: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"saleDate": `T0MZL3ilkZR_OGsD_SeO`,
	"labelTweak": `XPcvvHhFDRq2CFyvLzaL`
};
export default ___CSS_LOADER_EXPORT___;
