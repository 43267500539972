import React, { useContext, useState } from 'react';
import { InputsContext } from './inputs-context';
import { TranslationContext } from './translations-context';

export const ShoppingCartContext = React.createContext<any>({
  redeemPoints: null,
  redeemDollars: null,
  cart: [],
});

interface Props {
  children: React.ReactNode;
}
export const ShoppingCartContextProvider = ({ children }: Props) => {
  const [{ t }] = useContext(TranslationContext);
  const [redeemPoints, setRedeemPoints] = useState(0);
  const [redeemDollars, setRedeemDollars] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [selectedOffers, setSelectedOffers] = useState([]);
  const [selectedRewards, setSelectedRewards] = useState([]);
  const [showValidationErrors, setShowValidationErrors] = useState(false);
  const [salesTab, setSalesTab] = useState(false);
  const [redeemFetching, setRedeemFetching] = useState(false);
  const [showOffersDetails, setShowOffersDetails] = useState(-1);
  const [couponPointsTotal, setCouponPointsTotal] = useState(0);

  const [{}, { clearInputs }] = useContext(InputsContext); // eslint-disable-line no-empty-pattern

  const clearCart = () => {
    setRedeemPoints(0);
    setRedeemDollars(0);
    setSelectedOffers([]);
    setSelectedRewards([]);
    setShowValidationErrors(false);
    setSalesTab(false);
    setShowValidationErrors(false);
    clearInputs();
    setShowOffersDetails(-1);
    setCouponPointsTotal(0);
  };

  const validatePoints = () => (redeemPoints % 2 ? t('EVEN_POINTS') : null);

  return (
    <ShoppingCartContext.Provider
      value={[
        {
          redeemPoints,
          redeemDollars,
          showValidationErrors,
          discount,
          selectedOffers,
          selectedRewards,
          salesTab,
          redeemFetching,
          showOffersDetails,
          couponPointsTotal,
        },
        {
          setRedeemPoints,
          setRedeemDollars,
          clearCart,
          setShowValidationErrors,
          setDiscount,
          setSelectedOffers,
          setSelectedRewards,
          setSalesTab,
          setRedeemFetching,
          setShowOffersDetails,
          setCouponPointsTotal,
        },
        { validatePoints },
      ]}
    >
      {children}
    </ShoppingCartContext.Provider>
  );
};
