/* eslint-disable no-console */
import { IDENTITY_CONFIG, METADATA_OIDC } from './auth-config';
import { UserManager, WebStorageStateStore, Log } from 'oidc-client';
import amplitude from 'amplitude-js';

class MyLogger {
  info(arg) {
    console.info('OIDCinfo: ', arg);
  }
  warn(arg) {
    console.warn('OIDCwarn: ', arg);
  }
  error(arg) {
    if (!arg.includes('No matching state')) {
      console.error('OIDCerror: ', arg);
      amplitude.getInstance().logEvent('pfs oidc error', { arg: arg });
    }
  }
  debug(arg) {}
}
export default class AuthService {
  UserManager;

  constructor() {
    this.UserManager = new UserManager({
      ...IDENTITY_CONFIG,
      userStore: new WebStorageStateStore({ store: window.sessionStorage }),
      metadata: {
        ...METADATA_OIDC,
      },
    });
    // Logger
    //TODO replace console with splunk
    Log.logger = new MyLogger();
    Log.level = Log.ERROR;
    this.UserManager.events.addUserLoaded(() => {
      if (window.location.href.indexOf('signin-oidc') !== -1) {
        amplitude.getInstance().logEvent('pfs addUserLoaded', { href: window.location.href });
        window.location.replace('/');
      }
    });
    this.UserManager.events.addSilentRenewError(e => {
      Log.logger.error('silent renew error', e.message);
    });

    this.UserManager.events.addAccessTokenExpired(() => {
      Log.logger.warn('token expired');
      this.signinSilent();
    });
  }

  signinRedirectCallback = () => {
    this.UserManager.signinRedirectCallback()

      .then(() => {
        window.location.replace('/');
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {});
  };

  getUser = async () => {
    const user = await this.UserManager.getUser();
    if (!user) {
      return await this.UserManager.signinRedirectCallback();
    }

    return user;
  };

  parseJwt = (token: string) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');

    return JSON.parse(window.atob(base64));
  };

  signinRedirect = () => {
    localStorage.setItem('redirectUri', window.location.pathname);
    this.UserManager.signinRedirect({});
  };

  isAuthenticated = () => {
    const { user_attributes } = this.getUserProfile();
    return !!user_attributes;
  };

  isDealer = () => {
    const { user_attributes } = this.getUserProfile();
    return !!user_attributes?.fordJobRole;
  };

  signinSilent = () => {
    this.UserManager.signinSilent()
      .then(user => {
        Log.logger.info('signed in', user);
      })
      .catch(err => {
        Log.logger.error(err);
      });
  };

  signinSilentCallback = () => {
    this.UserManager.signinSilentCallback();
  };

  createSigninRequest = () => {
    return this.UserManager.createSigninRequest();
  };

  logout = () => {
    this.UserManager.signoutRedirect({
      id_token_hint: localStorage.getItem('id_token'),
    });
    this.UserManager.clearStaleState();
  };

  signoutRedirectCallback = () => {
    this.UserManager.signoutRedirectCallback().then(() => {
      localStorage.clear();
    });
    this.UserManager.clearStaleState();
  };

  getIdToken = () => {
    const oidcStorage = JSON.parse(sessionStorage.getItem(`oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`));

    return oidcStorage && `Bearer ${oidcStorage.id_token}`;
  };

  getUserProfile = () => {
    const oidcStorage = JSON.parse(sessionStorage.getItem(`oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`));

    if (oidcStorage && oidcStorage.profile) {
      const profileValues = oidcStorage.profile;
      const accessToken = oidcStorage.id_token;
      const base64Url = accessToken.split('.')[1];
      const base64 = base64Url.replace('-', '+').replace('_', '/');
      const user_attributes = JSON.parse(window.atob(base64));
      profileValues['user_attributes'] = user_attributes;
      return profileValues;
    } else {
      return {};
    }
  };
}
