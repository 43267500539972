// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WHauTpuOU3oYaLiGtBYA{position:relative}.WHauTpuOU3oYaLiGtBYA select{cursor:pointer;width:390px;padding:10px 20px;border-radius:var(--radius-SM, 8px);border:1px solid #4d4d4d;color:#4d4d4d;font-size:16px;letter-spacing:1px}.lDYj0nZzFZwOT760hDN1{color:#00095b}`, "",{"version":3,"sources":["webpack://./src/components/tabs/tab-connected-services-view/components/stars/select-stars-id.module.scss"],"names":[],"mappings":"AACA,sBACE,iBAAA,CACA,6BACE,cAAA,CACA,WAAA,CACA,iBAAA,CACA,mCAAA,CACA,wBAAA,CACA,aAAA,CACA,cAAA,CACA,kBAAA,CAIJ,sBACE,aAAA","sourcesContent":["@import \"main.scss\";\n.selectStarsContainer {\n  position: relative;\n  select {\n    cursor: pointer;\n    width: 390px;\n    padding: 10px 20px;\n    border-radius: var(--radius-SM, 8px);\n    border: 1px solid #4d4d4d;\n    color: #4d4d4d;\n    font-size: 16px;\n    letter-spacing: 1px;\n  }\n}\n\n.selectStarIdSelected {\n  color: #00095b;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectStarsContainer": `WHauTpuOU3oYaLiGtBYA`,
	"selectStarIdSelected": `lDYj0nZzFZwOT760hDN1`
};
export default ___CSS_LOADER_EXPORT___;
