import React, { useContext, useEffect, useState } from 'react';
import { TranslationContext } from '../../../context/translations-context';
import styles from './member-not-found.module.scss';
import { ModalDialog, Body } from '../../common/modal-dialog/modal-dialog';
import { QRCodeComponent } from '../../common/qr-code/qr-code';
import { SiteContext } from '../../../context/site-context';
import { InputWithLabel } from '../../common/Input-with-label/input-with-label';
import { CtaButton } from '../../common/cta/cta';
import SmsService from '../../../services/sms-service/sms-service';
import { APIHelperContext } from '../../../context/api-helper-context';
import { CustomerContext } from '../../../context/customer-context';
import { AlertDialogContext } from '../../../context/alert-dialog-context';

interface Props {
    make?: string;
    doClose?(): void;
}

export const MemberNotFoundDialog = ({ make, doClose }: Props) => {
  const [{ t, language }] = useContext(TranslationContext);
  const [apiHelper] = useContext(APIHelperContext);
  const [{ userProfile }] = useContext(CustomerContext);
  const [{ setShowAlert }] = useContext(AlertDialogContext);
  const [{ isCanada }] = useContext(SiteContext);
  const [fordSms, setFordSms] = useState('');
  const [lincolnSms, setLincolnSms] = useState('');
  const onboardingSteps = t('ONBOARDING_STEPS');

  useEffect(() => {
    if (userProfile && userProfile.phoneNumber) {
      setLincolnSms(userProfile.phoneNumber);
      setFordSms(userProfile.phoneNumber);
    }
  }, [userProfile]);

  const formatPhone = (phoneNumber: string) =>
    phoneNumber ? (phoneNumber.slice(0, 3) + '-' + phoneNumber.slice(3, 6) + '-' + phoneNumber.slice(6)).replace(/-+$/, '') : '';

  const fordUrl = `https://${language == 'fr' ? 'fr' : 'www'}.ford.${isCanada() == true ? 'ca' : 'com'}/support/fordpass/fordpass-rewards/overview/`;
  const lincolnUrl = `https://${language == 'fr' ? 'fr' : 'www'}.${isCanada() == true ? 'lincolncanada.com' : 'lincoln.com'}/support/lincoln-way-app/lincoln-access-rewards/overview/`;

  const smsDisclaimer = t('SMS_DISCLAIMER');
  const sendFordSms = () => {
    const smsService = new SmsService();
    const baseText = t('activation_SMS');
    const makeText = isCanada() ? (language === 'fr' ? 'Ford du Canada' : 'Ford of Canada') : (make === 'F' ? 'Ford' : 'Ford');
    const text = baseText.replace('{make}', makeText) + `: ${fordUrl} ${t('MSG_DATA_DSCLMR').replace('{make}', makeText)}`;

    smsService
      .request(
        {
          text: text,
          toNumber: fordSms,
        },
        apiHelper
      )
      .then((response) => {
        doClose();
        if (response.status !== 'queued') {setShowAlert({ title: t('SMS_FAIL'), message: t('MSG_NOT_SENT')}) 
        } else { 
          setShowAlert({ title: t('SUCCESS'), message: t('MSG_SENT')});
      }});
  };
  const sendLincolnSms = () => {
    const smsService = new SmsService();
    const baseText = t('activation_SMS');
    const makeText = isCanada() ? (language === 'fr' ? 'Lincoln Canada' : 'Lincoln Canada') : (make === 'L' ? 'Lincoln' : 'Lincoln');
    const text = baseText.replace('{make}', makeText) + `: ${lincolnUrl} ${t('MSG_DATA_DSCLMR').replace('{make}', makeText)}`;
  
    smsService
      .request(
        {
          text: text,
          toNumber: lincolnSms,
        },
        apiHelper
      )
      .then((response) => {
        doClose();
        if (response.status === 'queued') {
          setShowAlert({ title: t('SUCCESS'), message: t('MSG_SENT')});
        } else {
          setShowAlert({ title: t('SMS_FAIL'), message: t('MSG_NOT_SENT')});
        }
      }
    )
  };

    return(
      <ModalDialog title={t('Customer Onboarding Process')} doClose={doClose} className={styles.modal}>
      <Body>
      <div>
      <div className={styles.subtextBold}>{t('ONBOARDING_BOLD')}</div>
      <div className={styles.subtext} dangerouslySetInnerHTML={{ __html: onboardingSteps }} />
        <div className={styles.outerGrid}>
          <div className={styles.fordContainer}>
            <div className={styles.brand}>Ford</div>
              <CtaButton className={styles.smsBtn} label={t('Send Link')} onClick={sendFordSms} disabled={fordSms.length != 10} />
              <InputWithLabel
                className={styles.input}
                label={t('Send via SMS')}
                id='fordSms'
                onChange={(e: any) => setFordSms(e.target.value.replace(/\D/g, '').substr(0, 10))}
                value={formatPhone(fordSms)}
                maxLength={12}
                minLength={12}
              />
            <div className={styles.disclaimer} dangerouslySetInnerHTML={{ __html: smsDisclaimer }} />
            <div className={styles.QR}>
              <QRCodeComponent name={fordUrl} qrSize={175} />
            </div>
          </div>
          <div className={styles.lincolnContainer}>
            <div className={styles.brand}>Lincoln</div>
            <CtaButton className={styles.smsBtn} label={t('Send Link')} onClick={sendLincolnSms} disabled={lincolnSms.length != 10} />
            <InputWithLabel
              className={styles.input}
              label={t('Send via SMS')}
              id='lincolnSms'
              onChange={(e: any) => setLincolnSms(e.target.value.replace(/\D/g, '').substr(0, 10))}
              value={formatPhone(lincolnSms)}
              maxLength={12}
              minLength={12}
            />
            <div className={styles.disclaimer} dangerouslySetInnerHTML={{ __html: smsDisclaimer }} />
            <div className={styles.QR}>
             <QRCodeComponent name={lincolnUrl} qrSize={175} />
            </div>
          </div>
        </div>
      </div>
    </Body>
    </ModalDialog>

    )
}
