import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FinancingPrefillContext } from '../../context/financing-prefill-context';
import { CustomerContext } from '../../context/customer-context';
import { TranslationContext } from '../../context/translations-context';
import styles from './financing-form-view.module.scss';
import cx from '../../utils/classnames';
import utils from '../../utils/utils.module.scss';
import { SiteContext } from '../../context/site-context';
import { DatePickerWithLabel } from '../../components/common/datepicker-with-label/datepicker-with-label';
import { Link } from 'react-router-dom';
import ServiceHandler from '../../services/service-handler';

interface Props {
  setDate?(date: Date): void;
  date?: Date;
  onChange?: (date: Date, evnt: React.SyntheticEvent<any, Event>) => void;
}

export const FinFormView = ({ setDate, onChange }: Props) => {
  const { setIsAdmin, setSiteCode, vin, setVin, modelYear, setModelYear, modelName, setModelName, trim, setTrim, purchaseDate, setPurchaseDate, firstName, setFirstName, lastName, setLastName, email, setEmail, phoneNumber, setPhoneNumber, zipCode, setZipCode, paymentSource, setPaymentSource, connectedService, setConnectedService, productDetails, setProductDetails, ratePlan, setRatePlan, salePrice, setSalePrice, dealershipName, setDealershipName, dealershipPACode, setDealershipPACode, dealershipState, setDealershipState, employeeName, setEmployeeName, employeeTitle, setEmployeeTitle, employeePhoneNumber, setEmployeePhoneNumber, employeeEmail, setEmployeeEmail, setCustomerId, setCustomerCity, setCustomerState, setStarsId } = useContext(FinancingPrefillContext);
  const [{ t, languageCountry }] = useContext(TranslationContext);
  const [{ rewardProgram }] = useContext(CustomerContext);
  const [inputError, setInputError] = useState<null | string>(null);
  const [emailInputError, setEmailInputError] = useState<null | string>(null);
  const [{ isCanada }] = useContext(SiteContext);
  const [connectedServiceSelected, setConnectedServiceSelected] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [showMenu, setShowMenu] = useState(true);
  const country = isCanada() ? 'ca' : 'us';
  const isLAR = () => rewardProgram == 'L';
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const storedAdmin = sessionStorage.getItem('isAdmin');
    const storedSiteCode = sessionStorage.getItem('siteCode');
    const storedVin = sessionStorage.getItem('vin');
    const storedModelYear = sessionStorage.getItem('modelYear');
    const storedModelName = sessionStorage.getItem('modelName');
    const storedFirstName = sessionStorage.getItem('firstName');
    const storedLastName = sessionStorage.getItem('lastName');
    const storedEmail = sessionStorage.getItem('email');
    const storedPhoneNumber = sessionStorage.getItem('phoneNumber');
    const storedProductDetails = sessionStorage.getItem('productDetails');
    const storedDealershipName = sessionStorage.getItem('dealershipName');
    const storedDealershipPACode = sessionStorage.getItem('dealershipPACode');
    const storedDealershipState = sessionStorage.getItem('dealershipState');
    const storedCustomerId = sessionStorage.getItem('customerId');
    const storedCustomerCity = sessionStorage.getItem('customerCity');
    const storedCustomerState = sessionStorage.getItem('customerState');
    const storedStarsId = sessionStorage.getItem('starsId');

    if (storedAdmin) setIsAdmin(storedAdmin === 'true');
    if (storedSiteCode) setSiteCode(storedSiteCode);
    if (storedVin) setVin(storedVin);
    if (storedModelYear) setModelYear(storedModelYear);
    if (storedModelName) setModelName(storedModelName);
    if (storedFirstName) setFirstName(storedFirstName);
    if (storedLastName) setLastName(storedLastName);
    if (storedEmail) setEmail(storedEmail);
    if (storedPhoneNumber) setPhoneNumber(storedPhoneNumber);
    if (storedProductDetails) {setProductDetails(JSON.parse(storedProductDetails))}
    if (storedDealershipName) setDealershipName(storedDealershipName);
    if (storedDealershipPACode) setDealershipPACode(storedDealershipPACode);
    if (storedDealershipState) setDealershipState(storedDealershipState);
    if (storedCustomerId) setCustomerId(storedCustomerId);
    if (storedCustomerCity) setCustomerCity(storedCustomerCity);
    if (storedCustomerState) setCustomerState(storedCustomerState);
    if (storedStarsId) setStarsId(storedStarsId);
  }, []);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.origin !== window.location.origin) return;
      if (event.data.productDetails) {
        setProductDetails(event.data.productDetails);
      }
    };
  
    window.addEventListener('message', handleMessage);
  
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  const today = new Date();
  const lastMonth = new Date();
  lastMonth.setMonth(today.getMonth() - 1);
  
  function validateEmail(email: string) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  const formatPhone = (phoneNumber: string) =>
    phoneNumber ? (phoneNumber.slice(0, 3) + '-' + phoneNumber.slice(3, 6) + '-' + phoneNumber.slice(6)).replace(/-+$/, '') : '';
  
  const handleDateChange = (newDate: Date, event: React.SyntheticEvent<any, Event>) => {
    setPurchaseDate(newDate);
    if (setDate) {
      setDate(newDate);
      }
      if (onChange) {
        onChange(newDate, event);
      }
  };

  const handleEmailBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const email = e.target.value;
    if (!validateEmail(email)) {
      setEmailInputError(t('Invalid email address'));
    } else {
      setEmailInputError(null);
    }
  };

  const plan = productDetails?.availablePlans?.find(plan => {
    return plan.planName === ratePlan;
  }); 
  const MSRP = parseFloat(plan?.price.replace(/^\$/, ''));

  const handleBlur = (event: React.FocusEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { value } = event.target;
    if (!value) {
      setInputError('This field is required.');
    } else {
      setInputError('');
    }
  };

  const handleBtnClick = async () => {
    setIsButtonDisabled(false);
    navigate('/dealer-auth-form');
  };

  const isDisabled = !(vin && modelYear && modelName && trim && purchaseDate && firstName && lastName && email && phoneNumber && paymentSource && connectedService && salePrice && dealershipName && dealershipPACode && dealershipState && employeeName && employeeTitle && employeeEmail && employeePhoneNumber);
  
  useEffect(() => {
    setTrim('');
    setZipCode('');
    setSalePrice('');
    setEmployeeName('');
    setEmployeeTitle('');
    setEmployeeEmail('');
    setEmployeePhoneNumber('');
    setPurchaseDate(null);
    setPaymentSource('');
    setConnectedService('');
    setRatePlan('');
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (showMenu) {
      document.addEventListener('mousedown', () => setShowMenu(true));
    }
    return () => document.removeEventListener('mousedown', () => setShowMenu(false));
  }, [showMenu]);


  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <div className={styles.logo}>
          <img src={`${ServiceHandler.ConfigService.AEM_BASE_URL}/content/dam/loyalty/common/images/ford-logo.png`}/>
        </div>
        <div className={styles.title}>{t('Customer Authorization Form')}</div>
        <div className={styles.subtitle}>{t('One Time Connected Service Purchase')}</div>
      </div>
      <div className={styles.container}>
        <div className={styles.header}>{t('Vehicle Information')}</div>
          <div className={styles.columns}>
            <div className={styles.inputField}>
              {t('VIN')}
              <input
                className={styles.readOnly}
                type='text'
                name='vin'
                value={vin}
                onBlur={handleBlur}
                onChange={(e: any) => setVin(e.target.value)}
                required
              />
              {inputError && <p className="error">{inputError}</p>}            
            </div>
            <div className={styles.inputField}>
              {t('Model Year')}
              <input
                className={styles.readOnly}
                type='text'
                name='modelYear'
                value={modelYear}
                onBlur={handleBlur}
                onChange={(e: any) => setModelYear(e.target.value)}
                required
              />
              {inputError && <p className="error">{inputError}</p>}
            </div>
            <div className={styles.inputField}>
              {t('Vehicle Line')}
              <input
                className={styles.readOnly}
                type='text'
                name='modelName'
                value={modelName}
                onBlur={handleBlur}
                onChange={(e: any) => setModelName(e.target.value)}
                required
              />
              {inputError && <p className="error">{inputError}</p>}
            </div>
            <div className={styles.inputField}>
              {t('Trim (PEP)')}
              <input 
                className={styles.newEntry} 
                type='text' 
                value={trim} 
                onBlur={handleBlur} 
                onChange={(e: any) => setTrim(e.target.value)} 
                required 
              />
              {inputError && <p className="error">{inputError}</p>}
            </div>
            <div className={styles.inputField}>
              {t('Purchase Date')}
              <br />
              <DatePickerWithLabel 
                label='' 
                id='purchaseDate' 
                onChange={handleDateChange} 
                selectedDate={purchaseDate} 
                minDate={lastMonth} 
                maxDate={today} 
              />
            </div>
          </div>
        </div>
      <div className={styles.lineBreak}></div>
      <div className={styles.container}>
        <div className={styles.header}>{t('Customer Information')}</div>
        <div className={styles.columns}>
        <div className={styles.inputField}>
            {t('First Name')}
            <input
              className={styles.readOnly}
              type='text'
              name='firstName'
              value={firstName}
              onBlur={handleBlur}
              onChange={(e: any) => setFirstName(e.target.value)}
              required
            />
            {inputError && <p className="error">{inputError}</p>}
          </div>
          <div className={styles.inputField}>
            {t('Last Name')}
            <input
              className={styles.readOnly}
              type='text'
              name='lastName'
              value={lastName}
              onBlur={handleBlur}
              onChange={(e: any) => setLastName(e.target.value)}
              required
            />
            {inputError && <p className="error">{inputError}</p>}
          </div>
          <div className={styles.inputField}>
            {t('Email')}
            <input 
              className={styles.readOnly}
              type='text'
              value={email}
              onBlur={handleEmailBlur}
              onChange={(e: any) => setEmail(e.target.value)} 
              required
            />
            {emailInputError && <p className="error">{emailInputError}</p>}
          </div>
          <div className={styles.inputField}>
            {t('Phone Number')}
            <input
              className={styles.readOnly}
              type='text'
              name='phoneNumber'
              value={formatPhone(phoneNumber)}
              maxLength={12}
              minLength={12}
              onBlur={handleBlur}
              onChange={(e: any) => setPhoneNumber(e.target.value.replace(/\D/g, '').substr(0, 10))}
              required
            />
            {inputError && <p className="error">{inputError}</p>}
          </div>
          <div className={styles.inputField}>
            {isCanada() ? t('Postal Code') : t('Zip Code')}
            <input 
              className={styles.newEntry}
              type='text'
              minLength={5}
              maxLength={7}
              value={zipCode}
              onBlur={handleBlur}
              onChange={(e: any) => setZipCode(e.target.value)}
              required
            />
            {inputError && <p className="error">{inputError}</p>}
          </div>
          <div className={styles.inputField}>
            {t('Payment Source')}
            <select 
              className={styles.dropdown}
              name='paymentSource'
              value={paymentSource}
              onBlur={handleBlur}
              onChange={(e: any) => setPaymentSource(e.target.value)}
              required
            >
              <option value=''>– {t('Select Payment Source')} –</option>
              <option value='cash'>{t('Cash')}</option>
              <option value='lease'>{t('Finance (Lease)')}</option>
              <option value='purchase'>{t('Finance (Purchase)')}</option>
            </select>
            {inputError && <p className="error">{inputError}</p>}
          </div>
          </div>
      </div>
      <div className={styles.lineBreak} />
      <div className={styles.container}>
        <div className={styles.header}>{t('Connected Service Information')}</div>
        <div className={styles.columns}>
          <div className={styles.inputField}>
            {t('Connected Service')}
            <select
              className={styles.dropdown} 
              name='connectedService' 
              value={connectedService} 
              onBlur={handleBlur} 
              onChange={(e: any) => setConnectedService(e.target.value)}
              required
            >
              <option value=''>– {t('Select Connected Service')} –</option>
              <option value={productDetails?.name}>{productDetails?.name}</option>
            </select>
            {inputError && <p className="error">{inputError}</p>}
         </div>
         <div className={styles.inputField}>
            {t('Connected Service Plan')}
            <select 
              className={styles.dropdown} 
              id='ratePlan'
              value={ratePlan}
              onBlur={handleBlur} 
              onChange={(e: any) => {setRatePlan(e.target.value); setConnectedServiceSelected(true);
              }}
              required
            >
              <option value=''>– {t('Select Service Plan')} –</option>
              {productDetails?.availablePlans
                .filter(plan => plan.financeable !== false && plan.price !== null)
                .map(plan => (
                  <option key={plan.planName} value={plan.planName}>
                    {plan.planName}
                  </option>
                ))
              }
            </select>
            {inputError && <p className="error">{inputError}</p>}
          </div>
          <div className={styles.inputField}>
            {t('Dealer Selling Price – ${MSRP}').replace('${MSRP}', ratePlan ? `$ ${MSRP} MSRP` : '$')}
            <input 
              className={styles.newEntry} 
              type='text'
              placeholder={connectedServiceSelected && '$'}
              value={salePrice} 
              onBlur={handleBlur} 
              onChange={(e: any) => {
                const value = e.target.value;
                const regex = /^\d*\.?\d{0,2}$/;
                if (regex.test(value)) {
                  setSalePrice(value);
                }
              }}   
              onInput={(e: any) => {
                const value = e.target.value;
                e.target.value = value.replace(/[^0-9.]/g, '');
              }}            
              required 
            />
            {inputError && <p className="error">{inputError}</p>}
          </div>
        </div>
      </div>
      <div className={styles.lineBreak} />
      <div className={styles.container}>
        <div className={styles.header}>{t('Dealer Information')}</div>
        <div className={styles.columns}>
          <div className={styles.inputField}>
            {t('Dealership Name')}
            <input
              className={styles.readOnly}
              type='text'
              name='dealershipName'
              value={dealershipName}
              onBlur={handleBlur}
              onChange={(e: any) => setDealershipName(e.target.value)}
              required
            />
            {inputError && <p className="error">{inputError}</p>}
          </div>
          <div className={styles.inputField}>
            {t('Dealer P&A Code')}
            <input
              className={styles.readOnly}
              type='text'
              name='dealershipPACode'
              value={dealershipPACode}
              onBlur={handleBlur}
              onChange={(e: any) => setDealershipPACode(e.target.value)}
              required
            />
            {inputError && <p className="error">{inputError}</p>}
          </div>
          <div className={styles.inputField}>
            {t('Dealer State')}
            <input
              className={styles.readOnly}
              type='text'
              name='dealershipState'
              value={dealershipState}
              onBlur={handleBlur}
              onChange={(e: any) => setDealershipState(e.target.value)}
              required
            />
            {inputError && <p className="error">{inputError}</p>}
          </div>
          </div>
      </div>
      <div className={styles.lineBreak} />
      <div className={styles.container}>
        <div className={styles.header}>{t('Authorizing Dealer Employee')}</div>
        <div className={styles.columns}>
          <div className={styles.inputField}>
            {t('Full Name')}
            <input 
              className={styles.newEntry} 
              type='text'
              value={employeeName}
              onBlur={handleBlur}
              onChange={(e: any) => setEmployeeName(e.target.value)} />
            {inputError && <p className="error">{inputError}</p>}
          </div>
          <div className={styles.inputField}>
            {t('Title')}
            <input
              className={styles.newEntry}
              type='text'
              value={employeeTitle}
              onBlur={handleBlur}
              onChange={(e: any) => setEmployeeTitle(e.target.value)} />
            {inputError && <p className="error">{inputError}</p>}
          </div>
          <div className={styles.inputField}>
            {t('Email')}
            <input
              className={styles.newEntry}
              type='text'
              value={employeeEmail}
              onBlur={handleEmailBlur}
              onChange={(e: any) => setEmployeeEmail(e.target.value)}
            />
            {emailInputError && <p className="error">{emailInputError}</p>}
          </div>
          <div className={styles.inputField}>
            {t('Phone Number')}
            <input 
              className={styles.newEntry}
              type='text'
              value={formatPhone(employeePhoneNumber)}
              maxLength={12}
              minLength={12}
              onBlur={handleBlur}
              onChange={(e: any) => setEmployeePhoneNumber(e.target.value.replace(/\D/g, '').substr(0, 10))} />
            {inputError && <p className="error">{inputError}</p>}
          </div>
          </div>
      </div>
      <div className={styles.lineBreak} />
      <div className={styles.buttonBar}>
        <button
          disabled={isDisabled}
          className={cx(utils.buttonPrimary, styles.disabledButton) || cx(isButtonDisabled ? styles.disabledButton : utils.buttonPrimary)}
          onClick={handleBtnClick}
        >
          {t('Continue to Dealer Authorization Form')}
        </button>
      </div>
    </div>
  );
};