import amplitude from 'amplitude-js';

export const useAmplitude = () => {
  const logAmplitudeEvent = (name = 'unknown', translationContext, params = {}) => {
    amplitude.getInstance().logEvent(`pfs ${name}`.toLowerCase().replace(/\W+/g, ' '), {
      ...params,
      //ver: global['pfs_app_version'],
      languageCountry: `${translationContext.language}-${(translationContext.countryCode || '').toLowerCase().substr(0, 2)}`,
    });
  };

  return [logAmplitudeEvent];
};
