import React, { useContext, useEffect } from 'react';
import { TranslationContext } from '../../context/translations-context';
import { useAmplitude } from '../../hooks/useAmplitude';
import utils from '../../utils/utils.module.scss';

export const MaintenanceBreakView = () => {
  const [{ t }] = useContext(TranslationContext);

  const [logAmplitudeEvent] = useAmplitude();
  const [translation] = useContext(TranslationContext);

  useEffect(() => {
    logAmplitudeEvent('maintenance break', translation);
  }, []);

  return (
    <div className={utils.centerCenter} style={{ paddingTop: '20%', fontSize: '25px' }}>
      {t('MAINTENANCE_BREAK')}
    </div>
  );
};
