import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import ServiceHandler from './services/service-handler';
import './index.scss';
import amplitude from 'amplitude-js';

ServiceHandler.ConfigService.initDataAttributes();
amplitude.getInstance().init(process.env.AMPLITUDE_API_KEY);
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('pfs-container')
);
