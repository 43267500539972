import React from 'react';
import cx from '../../../utils/classnames';
import styles from './tabbar.module.scss';

interface Props {
  children: React.ReactElement[];
  setTab(id: string): void;
  active: string;
  className?: string;
}

function TabBar({ children, active, setTab, className }: Props) {
  return (
    <div className={cx(styles.container, className)} role='tablist' aria-label='Rewards smart tile'>
      {children
        .filter(t => t)
        .map(tab =>
          React.cloneElement(tab, {
            key: tab.props.id,
            setTab: setTab,
            active: active === tab.props.id,
          })
        )}
    </div>
  );
}

export default TabBar;
