// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vK6KSluF9cNnPHAb22Zh{text-align:center;padding:10vh 20vw;font-size:25px}`, "",{"version":3,"sources":["webpack://./src/views/not-authorized-view/not-authorized-view.module.scss"],"names":[],"mappings":"AACA,sBACE,iBAAA,CACA,iBAAA,CAEA,cAAA","sourcesContent":["@import \"main.scss\";\n.error {\n  text-align: center;\n  padding: 10vh 20vw;\n\n  font-size: 25px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": `vK6KSluF9cNnPHAb22Zh`
};
export default ___CSS_LOADER_EXPORT___;
