import React from 'react';

import styles from './pmp-form-view.module.scss';

interface Props {
  label: string;
  data?: string;
  width?: number;
}

export const Field = ({ label, data, width = 230 }: Props) => {
  return (
    <span className={styles.field} style={{ width: `${width}px` }}>
      <div className={styles.fieldData}>{data}</div>
      <div className={styles.fieldLabel}>{label}</div>
    </span>
  );
};
