import React, { useContext } from 'react';
import { TranslationContext } from '../../../context/translations-context';
import { ModalDialog, Body } from '../../common/modal-dialog/modal-dialog';
import styles from './text-msg-tc.module.scss';
import { PrivacyPolicy } from '../../common/privacy-policy/privacy-policy';

interface Props {
  doClose?(): void;
}

export const SmsTCsDialog = ({ doClose }: Props) => {
  const [{ t }] = useContext(TranslationContext);
  const smsTcSubtext = t('SMS_TC_SUBTEXT');
  const smsTcOptOut = t('SMS_TC_OPT_OUT');
  const smsTcHelp = t('SMS_TC_HELP');
  const smsTcMsgData = t('SMS_TC_MSG_DATA')


  return (
    <ModalDialog title={t('TXT_MSG_TC_TITLE')} doClose={doClose} className={styles.modal}>
      <Body>
        <div className={styles.SmsTCs}>
          <div className={styles.header} dangerouslySetInnerHTML={{ __html: smsTcSubtext }} />
          <div className={styles.header}>{t('SMS_TC_DISCLAIMER_HEADER')}</div>
          <div className={styles.subtext}>{t('SMS_TC_DISCLAIMER')}</div>
          <div className={styles.header}>{t('SMS_TC_OPT_OUT_HEADER')}</div>
          <div className={styles.subtext} dangerouslySetInnerHTML={{ __html: smsTcOptOut }} />
          <div className={styles.header}>{t('SMS_TC_HELP_HEADER')}</div>
          <div className={styles.subtext} dangerouslySetInnerHTML={{ __html: smsTcHelp }} />
          <div className={styles.header}>{t('SMS_TC_MSG_DATA_HEADER')}</div>
          <div className={styles.subtext} dangerouslySetInnerHTML={{ __html: smsTcMsgData }} />
          <div className={styles.header}>{t('SMS_TC_NUMBER_HEADER')}</div>
          <div className={styles.subtext}>{t('SMS_TC_NUMBER')}</div>
          <div className={styles.header}>{t('SMS_TC_PRIVACY_HEADER')}</div>
          <div className={styles.subtext}>
            {t('SMS_TC_PRIVACY')}
            <PrivacyPolicy />
            {('.')}
          </div>
        </div>
      </Body>
    </ModalDialog>
  );
};
