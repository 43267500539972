/* istanbul ignore file */
export default function cx(...args: any[]): string {
  const result: string[] = [];
  args.map(arg => {
    const argType = typeof arg;
    if (argType === 'string' || argType === 'number') {
      result.push(arg);
    } else if (argType === 'object') {
      for (const key in arg) {
        if (arg[key]) {
          result.push(key);
        }
      }
    }
    return '';
  });
  return result.join(' ');
}
