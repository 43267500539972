import React, { useContext, useState } from 'react';
import { SiteContext } from './site-context';

export const TranslationContext = React.createContext<any>({
  message: '',
});

export const STORAGE_KEY = 'pfs-react-language';

interface Props {
  children: React.ReactNode;
}
export const TranslationContextProvider = ({ children }: Props) => {
  const [{ countryCode }] = useContext(SiteContext);
  const db = {};

  db['en'] = require('../../locale/en-US.json');
  db['fr'] = require('../../locale/fr-CA.json');

  const [language, _setLanguage] = useState(localStorage.getItem(STORAGE_KEY) || 'en');
  const [{ isCanada }] = useContext(SiteContext);

  function t(id: string): any {
    if (db[language.substring(0, 2)]?.[id] == true) return id;
    return db[language.substring(0, 2)]?.[id] || `##${id}##`;
  }

  const setLanguage = (lang: string) => {
    _setLanguage(isCanada() ? lang : 'en');
    localStorage.setItem(STORAGE_KEY, isCanada() ? lang : 'en');
  };

  const languagesSupported = { en: t('English'), fr: t('French') };
  const languageCountry = () => `${language.substring(0, 2)}-${isCanada() ? 'CA' : 'US'}`;

  const formatCurrency = (value: number) =>
    (Math.round(value * 100) / 100).toLocaleString(language, { style: 'currency', currency: 'USD' }).replace('US', '');
  const formatPoints = (value: number) => Math.floor(value).toLocaleString(language, { style: 'decimal' });
  const formatDate = (date: Date) => date.toLocaleDateString(language, { year: 'numeric', month: 'long', day: 'numeric' });

  return (
    <TranslationContext.Provider
      value={[{ t, languagesSupported, language, languageCountry, setLanguage, formatCurrency, formatDate, formatPoints, countryCode }]}
    >
      {children}
    </TranslationContext.Provider>
  );
};
