import React, { useContext, useState } from 'react';
import styles from './tab-connected-services-view.module.scss';
import { TranslationContext } from '../../../context/translations-context';

import { DataGrid } from '../../common/data-grid/data-grid';

import { CtaLink } from '../../common/cta/cta';
import { ActivityIndicator } from '../../common/activity-indicator/activity-indicator';
import { ConnectedServiceDetailsDialog } from '../../dialogs/connected-services-details/connected-services-details-dialog';
import { MemberNotFoundDialog } from '../../dialogs/member-not-found/member-not-found';

import ServiceHandler from '../../../services/service-handler';

import { PrivacyPolicy } from '../../common/privacy-policy/privacy-policy';
import { SmsTCsDialog } from '../../dialogs/text-msg-tc/text-msg-tc-dialog';
import { SiteContext } from '../../../context/site-context';
import { VinContext } from '../../../context/vin-lookup-context';

export const VinTabConnectedServicesView = () => {
  const [{ t }] = useContext(TranslationContext);

  const [showDetails, setShowDetails] = useState<null | any>(null);
  const [showMemberNotFound, setShowMemberNotFound] = useState<null | any>(null);
  const [showSmsTCs, setShowSmsTCs] = useState<null | string>(null);

  const [{ isCanada }] = useContext(SiteContext);
  const [{ vin, vehicleMake, vehicleModel, vehicleYear, paidSubscriptions, freeSubscriptions, vinNotFound }] = useContext(VinContext);

  const renderDetailBtn = (unused: string, dataItem: any) => {
    return dataItem?.productDetailsLinkDisplayFlag == false ? (
      ''
    ) : (
      <a
        onClick={() => {
          setShowDetails(dataItem);
        }}
      >
        {t('Product Details')}{' '}
      </a>
    );
  };

  const renderStatus = (status: string) => {
    return <b className={styles[status.replace(/ /g, '')]}>{status}</b>;
  };

  const renderFinanceAvailable = (unused: string, dataItem: any) => {
    return dataItem?.financeable == true ? <p>Financing Available</p> : <p>Not Available</p>;
  };

  const FIN_AVAIL = t('FIN_AVAIL');
  const DETAIL_BTN = t('SSP_DESC');

  const freeColumns = [
    { id: 'name', title: t('NAME') },
    { id: 'status', title: t('STATUS'), render: renderStatus },
    { id: 'details', title: <div dangerouslySetInnerHTML={{ __html: DETAIL_BTN }} />, render: renderDetailBtn },
  ];

  const paidColumns = [
    { id: 'name', title: t('NAME') },
    { id: 'status', title: t('STATUS'), render: renderStatus },
    { id: 'financeAvailable', title: <div dangerouslySetInnerHTML={{ __html: FIN_AVAIL }} />, render: renderFinanceAvailable },
    // { id: 'incentive', title: t('Dealer Incentive') },
    { id: 'details', title: <div dangerouslySetInnerHTML={{ __html: DETAIL_BTN }} />, render: renderDetailBtn },
  ];

  return (
    !vinNotFound && (
      <>
        <div className={styles.connectedServicesContainer}>
          {showMemberNotFound && <MemberNotFoundDialog doClose={() => setShowMemberNotFound(null)} />}
          {showDetails && <ConnectedServiceDetailsDialog offer={showDetails} doClose={() => setShowDetails(null)} />}
          {showSmsTCs && <SmsTCsDialog doClose={() => setShowSmsTCs(null)} />}
          <br />
          <br />
          {(paidSubscriptions !== false || paidSubscriptions?.length === 0 || paidSubscriptions === false) && (
            <div className={styles.vehicleDesc}>
              <div>{t('Connected Services for:')} </div>
              <div>
                <img
                  src={
                    // vehicle.vehicleImageUrl ? vehicle.vehicleImageUrl :   // commented out temporarily for backend
                    `${ServiceHandler.ConfigService.AEM_BASE_URL}/content/dam/loyalty/common/images/NoVehicle_Placeholder.png`
                  }
                />
              </div>
              <div className={styles.selectedVehicle}>
                {vehicleYear} {vehicleMake} {vehicleModel}
                <br />
                {vin.toUpperCase()}
              </div>
            </div>
          )}
          <br />
          <br />
          <br />
          <div className={styles.onboardingContainer}>
            <div>{t('Customer Onboarding')}</div>&nbsp;
            <a className={styles.onboarding} onClick={() => setShowMemberNotFound(true)}>
              {t('Onboarding Link')}
            </a>
          </div>

          {(freeSubscriptions?.length === 0 || freeSubscriptions === false) && (
            <>
              <br />
              <br />
              <h2 className={styles.tableHeading}>{t('FREE_TRIAL')}</h2>
              <div className={styles.emptyTable}>
                <div className={styles.noOffers}>{t('NO_FREE')}</div>
              </div>
            </>
          )}

          {freeSubscriptions?.length > 0 && (
            <>
              <br />
              <br />
              <br />
              <br />
              <br />
              <h2 className={styles.tableHeading}>{t('FREE_TRIAL')}</h2>
              <DataGrid data={freeSubscriptions} columns={freeColumns} sortable={false} />
              <div className={styles.disclaimer}>{t('SSP_DISCLAIMER')}</div>
            </>
          )}

          {paidSubscriptions?.length === 0 || paidSubscriptions === false ? (
            <>
              <br />
              <br />

              <h2 className={styles.tableHeading}>{t('Paid Services')}</h2>
              <div className={styles.emptyTable}>
                <div className={styles.noOffers}>{t('NO_PAID')}</div>
              </div>
            </>
          ) : (
            <>{paidSubscriptions?.length == undefined && <ActivityIndicator />}</>
          )}

          {paidSubscriptions?.length > 0 && paidSubscriptions !== false && (
            <>
              <br />
              <br />
              <br />
              <h2 className={styles.tableHeading}>{t('Paid Services')}</h2>
              <DataGrid data={paidSubscriptions} columns={paidColumns} sortable={false} />
              <div className={styles.disclaimer}>{t('SSP_DISCLAIMER')}</div>
            </>
          )}
          <div className={styles.privacy}>
            {t('Ford and Lincoln Privacy Notice: ')}
            <PrivacyPolicy />
          </div>
          {!isCanada() && (
            <>
              <div className={styles.textTCs}>
                {t('Text Messaging Terms and Conditions: ')}
                <CtaLink className={styles.textTCs} onClick={setShowSmsTCs}>
                  {t('Ford and Lincoln SMS T&Cs')}
                </CtaLink>
              </div>
            </>
          )}
        </div>
      </>
    )
  );
};
