/* eslint-disable no-useless-escape */
import React, { useState, useContext, useEffect } from 'react';
import utils from '../../../utils/utils.module.scss';
import { Column } from '../../common/column/column';
import { CustomerContext } from '../../../context/customer-context';

import styles from './points-input.module.scss';
import { ErrorText } from '../../common/error-text/error-text';
import cx from '../../../utils/classnames';
import { TranslationContext } from '../../../context/translations-context';
import { InputsContext } from '../../../context/inputs-context';
import { CurrencyInput, PointsInput } from '../../common/formatted-input/formatted-input';

interface Props {
  inputs: any;
  isGoodWillDialog?: boolean;
  className?: string;
  dollarsOnly?: boolean;
}

export const PointsDollarsEntry = ({
  isGoodWillDialog = false,
  className,
  dollarsOnly = false,
  inputs: [redeemPoints, redeemDollars, setRedeemPoints, setRedeemDollars],
}: Props) => {
  const [{ t }] = useContext(TranslationContext);
  const [error, setError] = useState('');
  const [{ points, rewardProgram, availablePoints }] = useContext(CustomerContext);
  const [{ rewardChoiceDefault }] = useContext(InputsContext);

  useEffect(() => checkPointsLimit(redeemPoints), [rewardChoiceDefault]);

  const checkPointsLimit = redeemPoints => {
    setError('');
    if (!isGoodWillDialog && redeemPoints > availablePoints()) setError(t('EXCEEDED_POINTS'));
    else if (redeemPoints % 2 > 0) {
      setError(t('EVEN_POINTS'));
    }
  };

  const conversionFactor = () => {
    return points?.pointsTotals[rewardProgram]?.conversionFactor || 0.005;
  };

  const updatePoints = value => {
    setRedeemPoints(value);
    checkPointsLimit(value);

    if (conversionFactor()) {
      setRedeemDollars(Math.round(100 * (value * conversionFactor())) / 100);
    }
  };

  const updateDollars = value => {
    setRedeemDollars(+value);
    if (conversionFactor()) {
      const points = Math.round(+value / conversionFactor());
      setRedeemPoints(points);
      checkPointsLimit(points);
    }
  };

  return (
    <>
      {!dollarsOnly && (
        <>
          <Column className={cx(utils.noPadding, className)}>
            <div>
              <PointsInput
                label={isGoodWillDialog ? t('Points to Award') : t('Points to Redeem')}
                id='pointsToRedeem'
                className={styles.pointsToRedeem}
                value={redeemPoints}
                setValue={updatePoints}
              />
              {error && <ErrorText className={styles.error} text={error} />}
            </div>
          </Column>

          <Column className={styles.equalsign}>=</Column>
        </>
      )}
      <Column className={utils.noPadding}>
        <CurrencyInput
          label={isGoodWillDialog ? t('Dollars to Award') : t('Dollars to Redeem')}
          id='dollarsToRedeem'
          className={styles.dollarsToRedeem}
          value={redeemDollars}
          setValue={updateDollars}
        />
        {error && dollarsOnly && <ErrorText className={styles.error} text={error} />}
      </Column>
    </>
  );
};
