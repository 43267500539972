import React, { useContext, useEffect } from 'react';
import { ShoppingCartContext } from '../../../context/shopping-cart-context';
import utils from '../../../utils/utils.module.scss';
import { Header } from '../../common/header/header';

import styles from './offers-section.module.scss';
import cx from '../../../utils/classnames';
import { ActivityIndicator } from '../../common/activity-indicator/activity-indicator';
import { TranslationContext } from '../../../context/translations-context';

import { CustomerContext } from '../../../context/customer-context';
import { Currency, currencyToFloat } from '../../common/currency/currency';

interface Props {
  title: string;
  offers: any;
  rewards?: any;
}

export const OffersSection = ({ offers, rewards, title }: Props) => {
  const [{ t }] = useContext(TranslationContext);
  const [{ selectedOffers, showOffersDetails }, { setSelectedOffers, setShowOffersDetails }] = useContext(ShoppingCartContext);

  const [{ rewardProgram }] = useContext(CustomerContext);

  useEffect(() => {
    setShowOffersDetails(-1);
  }, [rewardProgram]);

  const addAllToCart = (checked: boolean) => {
    if (checked) {
      setSelectedOffers(offers);
    } else {
      setSelectedOffers([]);
    }
  };

  const updateSelectedOffer = (add: boolean, offer: any) => {
    if (add) setSelectedOffers([...selectedOffers, offer]);
    else setSelectedOffers(selectedOffers.filter((item: any) => !offer.barcode.includes(item.barcode))); //TODO: replace any
  };

  const items = [...offers, ...rewards].sort((a, b) => currencyToFloat(a.memberDiscount) - currencyToFloat(b.memberDiscount));

  return (
    <Header title={title} icon='gift' className={cx(utils.marginTop50)}>
      <table className={styles.table}>
        <thead className={styles.header}>
          <tr>
            <th>
              <input type='checkbox' onChange={e => addAllToCart((e.target as HTMLInputElement).checked)} aria-label='Select all' />
            </th>
            <th>{t('NAME')}</th>
          </tr>
        </thead>
        <tbody>
          {items.length ? (
            <>
              {items?.map((item: any, i: number) => (
                <React.Fragment key={item.barcode}>
                  <tr className={styles.row} key={item.barcode}>
                    <td>
                      <input
                        id={`offercx${i}`}
                        type='checkbox'
                        checked={selectedOffers.includes(item)}
                        onChange={e => updateSelectedOffer((e.target as HTMLInputElement).checked, item)}
                        aria-label='select'
                      />
                    </td>

                    <td>
                      <label htmlFor={`offercx${i}`}>
                        {item.cpnDiscountAmount && (
                          <>
                            {item.couponTypeName}
                            {' : '}
                            {item.pricePlan && (currencyToFloat(item.cpnDiscountAmount) / 0.005).toLocaleString() + ' pts / '}
                            <Currency data={item.memberDiscount} />
                          </>
                        )}
                        {!item.cpnDiscountAmount && (
                          <>
                            {`${item.rewardName}: ${(+item.pricePlan?.[0].points).toLocaleString()} pts / `}
                            <Currency data={item.memberDiscount} />{' '}
                          </>
                        )}
                      </label>
                      <a onClick={() => setShowOffersDetails(showOffersDetails == i ? -1 : i)}>{showOffersDetails == i ? t('Hide') : t('Details')}</a>
                    </td>
                  </tr>

                  {showOffersDetails == i && (
                    <tr>
                      <td colSpan={2} className={styles.details}>
                        <div>
                          <span>{t('Description')}: </span>
                          <div dangerouslySetInnerHTML={{ __html: item.rewardDescription || item.description }} />
                        </div>
                        <div>
                          <span>{t('Legal Disclaimer')}: </span>
                          <div dangerouslySetInnerHTML={{ __html: item.rewardLegalText || item.legalDisclaimer }} />
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </>
          ) : (
            <tr>
              <td colSpan={3}>{offers == null ? <ActivityIndicator /> : <div className={utils.emptyList}>{t('No offers available')}</div>}</td>
            </tr>
          )}
        </tbody>
      </table>
    </Header>
  );
};
