import React, { useContext, useEffect, useState } from "react";
import cx from "../../../utils/classnames";
import styles from "./tab-sales-view.module.scss";
import utils from "../../../utils/utils.module.scss";
import { RewardProgramChoice } from "../../sections/choice-reward-program/choice-reward-program";
import {
  REWARD_CHOICE,
  RewardPurchaseChoice,
} from "../../sections/choice-purchase-reward/choice-purchase-reward";
import { PointsSection } from "../../sections/points-section/points-section";
import {
  ThreeColumnRow,
  TwoColumnRow,
  Column,
  DoubleColumn,
} from "../../common/column/column";
import { OffersSection } from "../../sections/offers-section/offers-section";
import { ShoppingCartSection } from "../../sections/shopping-cart-section/shopping-cart-section";
import { Header } from "../../common/header/header";
import { isFordOrLincolnVin, VinInput } from "../../common/vin-input/vin-input";
import {
  InputsContext,
  REWARD_CHOICE_DEFAULTED,
} from "../../../context/inputs-context";
import { InputWithLabel } from "../../common/Input-with-label/input-with-label";
import { CustomerContext } from "../../../context/customer-context";
import { TranslationContext } from "../../../context/translations-context";
import { OffersRedeemableSection } from "../../sections/offers-redeemable-section/offers-redeemable-section";
import OffersService, {
  filterCatalog,
  filterOffers,
} from "../../../services/offers-service/offers-service";
import { SaleTypeChoice } from "../../sections/choice-sale-type/choice-sale-type";
import { ShoppingCartContext } from "../../../context/shopping-cart-context";
import { APIHelperContext } from "../../../context/api-helper-context";
import { AlertDialogContext } from "../../../context/alert-dialog-context";
import { ActivityIndicator } from "../../common/activity-indicator/activity-indicator";
import { useAmplitude } from "../../../hooks/useAmplitude";
import { encryptFormData } from "../../../utils/url.utils";
import { SaleDatePicker } from "../../sections/sale-date/sale-date";
import { SiteContext } from "../../../context/site-context";
import { useNavigate } from "react-router-dom";
import { SuccessDialog } from "../../dialogs/success-dialog/success-dialog";

export const TabSalesView = () => {
  const [{ t }] = useContext(TranslationContext);
  const [
    {
      invoiceNumber,
      vin,
      saleType,
      rewardChoice,
      rewardChoiceDefault,
      saleDate,
      nonFordVin,
    },
    { setInvoiceNumber, setVin, setNonFordVin, setSaleType, setRewardChoice },
    { validateInvoice, validateVin },
  ] = useContext(InputsContext);
  const [{ userProfile, rewardProgram, offers }, { doSearch }] =
    useContext(CustomerContext);
  const [logAmplitudeEvent] = useAmplitude();
  const [translation] = useContext(TranslationContext);
  const [{ selectedOffers, selectedRewards }, { clearCart }] =
    useContext(ShoppingCartContext);
  const [{ isCanada }] = useContext(SiteContext);
  const navigate = useNavigate();
  const [showSuccess, setShowSuccess] = useState(false);

  // eslint-disable-next-line no-empty-pattern
  const [{}, { setSalesTab, setRedeemFetching }, { validatePoints }] =
    useContext(ShoppingCartContext);
  const [apiHelper] = useContext(APIHelperContext);
  const [{ setShowAlert }] = useContext(AlertDialogContext);

  setSalesTab(true);

  const validateSaleDate = () =>
    saleDate == null ? t("Sale date required") : null;

  const submitTransaction = (formdata: FormData, onSuccess) => {
    formdata.append("totalNet", "0.0");
    formdata.append("totalGross", "0.0");
    formdata.append("rewardChoice", rewardChoice);
    formdata.append("saleType", saleType);
    formdata.append("saleNumber", invoiceNumber);
    formdata.append("vin", vin);
    formdata.append("saleDate", saleDate.toISOString());

    const offersService = new OffersService();
    setRedeemFetching(true);
    offersService
      .redeemSalesOffer(
        userProfile,
        { rewardProgram: rewardProgram, partnerCategory: "D" },
        formdata,
        apiHelper
      )
      .then((response) => {
        setRedeemFetching(false);
        if (response?.pointBalance) {
          setShowSuccess(true);

          logAmplitudeEvent("sales", translation, {
            rewardChoice,
            rewardChoiceDefault,
            saleType,
            offers: selectedOffers.length,
            rewards: selectedRewards.length,
            guid: userProfile.lighthouseGuid,
          });

          setSalesTab(true);
          onSuccess(response.pointBalance);
        } else {
          logAmplitudeEvent("sales error", translation, {
            ...response,
            msg: (response.data?.error?.message || "").substr(0, 50),
            form: encryptFormData(formdata),
            guid: userProfile.lighthouseGuid,
          });

          setShowAlert({
            title: t("ERROR"),
            message: offersService.getError(response, t, "sales"),
          });
        }
      });
  };

  useEffect(() => {
    if (nonFordVin) {
      setRewardChoice("points");
      if (!isCanada()) setSaleType("VehicleCPOLite");
    }

    if (nonFordVin) setSaleType("VehicleCPOLite");
  }, [nonFordVin]);

  const validateVinAndRewardChoice = () => {
    setNonFordVin(!isFordOrLincolnVin(vin));
    return validateVin();
  };

  const disableRewardChoice = ![
    REWARD_CHOICE_DEFAULTED.DEFAULTPOINTS,
    REWARD_CHOICE_DEFAULTED.BLANK,
    REWARD_CHOICE_DEFAULTED.SWITCH_PMP,
  ].includes(rewardChoiceDefault);

  const closeSuccessDlg = () => {
    setShowSuccess(false);
    if (rewardChoice == REWARD_CHOICE.PMP)
      setTimeout(() => navigate("/pmp-form"), 100);
    else {
      clearCart();
      doSearch(userProfile.memberId, false);
    }
  };

  return (
    <ThreeColumnRow>
      {showSuccess && <SuccessDialog doClose={closeSuccessDlg} />}
      <DoubleColumn>
        <RewardProgramChoice uniqueID="TabSalesView" />

        <Header title={t("Invoice")} className={utils.section}>
          <ThreeColumnRow>
            <Column className={utils.noPadding}>
              <InputWithLabel
                validator={validateInvoice}
                id="saleNumber"
                label={t("Vehicle Deal Number")}
                value={invoiceNumber}
                maxLength={8}
                onChange={(e) =>
                  setInvoiceNumber(
                    e.target.value.toUpperCase().replace(/[^0-9A-Z]/g, "")
                  )
                }
              />
            </Column>
            <Column className={cx(utils.marginLeft30, utils.noPadding)}>
              <VinInput
                label="VIN"
                value={vin}
                setValue={setVin}
                validator={validateVinAndRewardChoice}
              />
            </Column>
            <Column>
              {rewardChoiceDefault === REWARD_CHOICE_DEFAULTED.LOADING && (
                <div className={styles.vinActivityIndicator}>
                  <ActivityIndicator size="S" />
                </div>
              )}
            </Column>
          </ThreeColumnRow>
        </Header>
        <SaleDatePicker title={t("Vehicle Sale Date") as string} />

        <TwoColumnRow
          className={cx({ [styles.hideRewardChoice]: disableRewardChoice })}
        >
          <Column className={utils.noPadding}>
            <RewardPurchaseChoice />
          </Column>
          {(!isCanada() ||
            (rewardProgram == "F" &&
              saleDate &&
              saleDate <= new Date("January 3, 2023"))) && (
            <Column className={cx(utils.noPadding, utils.marginLeft30)}>
              <SaleTypeChoice />
            </Column>
          )}
        </TwoColumnRow>

        <PointsSection />

        <Header
          title={t("Points Offers")}
          icon="tags"
          className={cx(utils.marginTop50)}
        >
          <OffersRedeemableSection
            offers={filterOffers(offers?.[rewardProgram].dro, "XV")}
          />
        </Header>

        <OffersSection
          title={`${rewardProgram == "F" ? "Ford " : "Lincoln"} ${t("Offers")}`}
          offers={filterOffers(offers?.[rewardProgram].drc, "XV")}
          rewards={filterCatalog(offers?.[rewardProgram].rewards, "XV")}
        />
      </DoubleColumn>
      <Column>
        <ShoppingCartSection
          validators={[
            validateInvoice,
            validateVin,
            validatePoints,
            validateSaleDate,
          ]}
          submitTransaction={submitTransaction}
        />
      </Column>
    </ThreeColumnRow>
  );
};
