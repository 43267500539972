import React, { useContext } from 'react';
import cx from '../../../utils/classnames';
import utils from '../../../utils/utils.module.scss';
import { RewardProgramChoice } from '../../sections/choice-reward-program/choice-reward-program';
import { PointsSection } from '../../sections/points-section/points-section';
import { ThreeColumnRow, Column, DoubleColumn } from '../../common/column/column';
import { OffersSection } from '../../sections/offers-section/offers-section';
import { ShoppingCartSection } from '../../sections/shopping-cart-section/shopping-cart-section';
import { Header } from '../../common/header/header';
import { VinInput } from '../../common/vin-input/vin-input';

import { InputsContext } from '../../../context/inputs-context';
import { InputWithLabel } from '../../common/Input-with-label/input-with-label';
import { CustomerContext } from '../../../context/customer-context';
import { TranslationContext } from '../../../context/translations-context';
import { OffersRedeemableSection } from '../../sections/offers-redeemable-section/offers-redeemable-section';
import { filterOffers } from '../../../services/offers-service/offers-service';
import { CurrencyInput } from '../../common/formatted-input/formatted-input';

export const TabFandIView = () => {
  const [{ t }] = useContext(TranslationContext);
  const [{ invoiceAmount, invoiceNumber, vin }, { setInvoiceAmount, setInvoiceNumber, setVin }, { validateInvoice, validateVin }] =
    useContext(InputsContext);
  const [{ rewardProgram, offers }] = useContext(CustomerContext);

  return (
    <ThreeColumnRow>
      <DoubleColumn>
        <RewardProgramChoice uniqueID='TabFandIView' />
        <Header title={t('Invoice')} className={utils.section}>
          <ThreeColumnRow>
            <Column className={utils.noPadding}>
              <InputWithLabel
                label={t('Invoice Number')}
                validator={validateInvoice}
                id='saleNumber'
                value={invoiceNumber}
                maxLength={6}
                onChange={e => setInvoiceNumber(e.target.value.replace(/[^0-9]/g, ''))}
              />
            </Column>
            <Column className={cx(utils.centerPadding)}>
              <CurrencyInput
                label={t('Net Plan Amount')}
                id='netPlanAmount'
                value={invoiceAmount}
                setValue={setInvoiceAmount}
                className={utils.currencyWidth}
              />
            </Column>
            <Column className={utils.noPadding}>
              <VinInput label='VIN' value={vin} setValue={setVin} validator={validateVin} />
            </Column>
          </ThreeColumnRow>
        </Header>

        <PointsSection />

        <Header title={t('Points Offers')} icon='tags' className={cx(utils.marginTop50)}>
          <OffersRedeemableSection offers={filterOffers(offers?.[rewardProgram].dro, 'XF')} />
        </Header>
        <OffersSection
          title={`${rewardProgram == 'F' ? 'Ford ' : 'Lincoln'} ${t('Offers')}`}
          offers={filterOffers(offers?.[rewardProgram].drc, 'XF')}
        />
      </DoubleColumn>

      <Column>
        <ShoppingCartSection title={t('Net Plan Amount')} amount={invoiceAmount} validators={[validateInvoice, validateVin]} />
      </Column>
    </ThreeColumnRow>
  );
};
