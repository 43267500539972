import { useContext, useEffect, useState } from 'react';
import { TranslationContext } from '../../../context/translations-context';

interface Props {
  data: number;
  currency?: boolean;
  dollarSign?: boolean;
  steps?: number;
}

let timeoutID = -1;

export function Odometer({ data, currency = false, dollarSign = true, steps = 9 }: Props) {
  const [odometer, setOdometer] = useState(0);
  const [{ formatPoints, formatCurrency }] = useContext(TranslationContext);

  useEffect(() => {
    if (data != null) doOdometer(odometer, (data - odometer) / steps);
    return () => {
      clearTimeout(timeoutID);
    };
  }, [data]);

  const doOdometer = (val: number, inc: number) => {
    val += inc;

    setOdometer(val);
    if ((inc > 0 && val < data) || (inc < 0 && val > data)) timeoutID = window.setTimeout(() => doOdometer(val, inc), 80);
    else setOdometer(data);
  };

  if (currency) return dollarSign ? formatCurrency(odometer) : formatCurrency(odometer).replace('$', '');
  else return formatPoints(odometer);
}
