import ServiceHandler from '../service-handler';
import { APIHelper } from '../../models/api-helper';
import { post } from '../generic-service';

export default class GoodwillPointsService {
  public async request(data: any, apiHelper: APIHelper) {
    data['partnerCode'] = apiHelper.siteCode;
    data['partnerUserId'] = ServiceHandler.AuthenticationService.getDealerUserName();
    return await post('GOODWILL_POINTS', null, {}, data, null, apiHelper);
  }
}
