// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tw4bjjF8WHWNy8QngQxe{position:relative}.tw4bjjF8WHWNy8QngQxe select{cursor:pointer;width:320px;padding:10px 20px;border-radius:var(--radius-SM, 8px);border:1px solid #4d4d4d;color:#4d4d4d;font-size:16px;letter-spacing:1px;margin-bottom:10px}.W4CnBhcF7_hcSpEk1e1X{color:#00095b}.vpo2z9pjbGVyODkrDTC7{border:solid 2px red;outline:none !important}`, "",{"version":3,"sources":["webpack://./src/components/tabs/tab-connected-services-view/components/garage/select-vehicle.module.scss"],"names":[],"mappings":"AACA,sBACE,iBAAA,CACA,6BACE,cAAA,CACA,WAAA,CACA,iBAAA,CACA,mCAAA,CACA,wBAAA,CACA,aAAA,CACA,cAAA,CACA,kBAAA,CACA,kBAAA,CAIJ,sBACE,aAAA,CAGF,sBACE,oBAAA,CACA,uBAAA","sourcesContent":["@import \"main.scss\";\n.vehicleSelectContainer {\n  position: relative;\n  select {\n    cursor: pointer;\n    width: 320px;\n    padding: 10px 20px;\n    border-radius: var(--radius-SM, 8px);\n    border: 1px solid #4d4d4d;\n    color: #4d4d4d;\n    font-size: 16px;\n    letter-spacing: 1px;\n    margin-bottom: 10px;\n  }\n}\n\n.selectVehicleSelected {\n  color: #00095b;\n}\n\n.errorSelect {\n  border: solid 2px red;\n  outline: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"vehicleSelectContainer": `tw4bjjF8WHWNy8QngQxe`,
	"selectVehicleSelected": `W4CnBhcF7_hcSpEk1e1X`,
	"errorSelect": `vpo2z9pjbGVyODkrDTC7`
};
export default ___CSS_LOADER_EXPORT___;
