import React, { useContext } from 'react';
import { CustomerContext } from '../../context/customer-context';
import { TranslationContext } from '../../context/translations-context';

import styles from './financing-form-view.module.scss';
import { Field } from './underline-field';
import { CtaButton } from '../../components/common/cta/cta';
import { InputsContext } from '../../context/inputs-context';
import { SiteContext } from '../../context/site-context';

import cx from '../../utils/classnames';
// import { ShoppingCartContext } from '../../context/shopping-cart-context';
import { useNavigate } from 'react-router';

export const FinFormView = () => {
  const [{ t, formatDate, languageCountry }] = useContext(TranslationContext);
  const [{ userProfile, rewardProgram }, { doSearch }] = useContext(CustomerContext);
  const [{ vin, saleDate }] = useContext(InputsContext);
  const [{ isCanada }] = useContext(SiteContext);
  // const [{}, { clearCart }] = useContext(ShoppingCartContext);

  const navigate = useNavigate();
  const isLAR = () => rewardProgram == 'L';

  const tLAR = (id: string) => t(`${id}${isLAR() ? '-L' : ''}`);
  const tCANLAR = (id: string) => t(`${id}${isCanada() ? '-CAN' : ''}${isLAR() ? '-L' : ''}`);

  const country = isCanada() ? 'ca' : 'us';

  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <img
          src={`https://www.globalowneraem.ford.com/content/dam/loyalty/${
            isLAR()
              ? `lincoln/${country}/${languageCountry().toLowerCase()}/images/lincoln-rewards-logo.png`
              : `ford/${country}/${languageCountry().toLowerCase()}/images/fordPass-rewards.png`
          }`}
        />
        <div className={styles.title}>{t('Customer Authorization Form – Connected Services')}</div>
        <div className={styles.header}>
          {t('Vehicle Information')}
          <Field label={t('VIN')} data={vin} />
        </div>
        <div className={styles.header}>{t('Customer Information')}</div>
        <div className={styles.header}>{t('Dealer Information')}</div>
        <div className={styles.header}>{t('Authorizing Dealer Employee')}</div>
        <div className={styles.header}>{t('Signature')}</div>

        <div className={styles.dualboxes}>
          <div>
            <div>{tCANLAR('P2')}</div>
            <div className={styles.fields}>
              <Field label={t('FIRST_NAME')} data={userProfile?.firstName} />
              <Field label={t('P3')} data={userProfile?.middletName?.[0].toUpperCase()} width={100} />
              <Field label={tLAR('LAST_NAME')} data={userProfile?.lastName} />
            </div>
            <div>{t('P4')}</div>
            <div className={styles.fields}>
              <Field label={t('P5')} data={vin} width={340} />
              <Field label={t('P6')} data={formatDate(saleDate || new Date())} />
            </div>
          </div>
          <div>
            <div>{t('P7')}</div>
            <div className={styles.fields}>
              <Field label={tCANLAR('P8')} width={180} />
              <Field label={t('MEMBER_ID')} data={userProfile?.memberId} width={180} />
              <Field label={t('P9')} width={180} />
            </div>
          </div>
        </div>
        <br />
        <br />
        <div className={styles.fontLargeBold}>{tCANLAR('P10')}</div>
        <div className={styles.fontMedBold}>{t('P11')}</div>
        {!isCanada() && <div className={styles.fontMedLeft} dangerouslySetInnerHTML={{ __html: tLAR('P12-DISCLAIMER') }} />}
        <div className={styles.lincolnDual}>
          <div className={cx(styles.singlebox, isCanada() ? styles.noBorder : '')}>
            <div dangerouslySetInnerHTML={{ __html: tCANLAR('P13') }} />
            {!isCanada() && (
              <>
                <ol>
                  <li>{t('P14')}</li>
                  <li>{tLAR('P15')}</li>
                  <li>{tLAR('P16')} </li>
                </ol>

                <br />
                <br />
              </>
            )}
            <div className={styles.fields}>
              <Field label={t('P17')} width={300} />
              <Field label={t('DATE')} />
            </div>
            <div dangerouslySetInnerHTML={{ __html: tCANLAR('P18') }} />
            <div className={styles.fields}>
              <Field label={t('P19')} />
              <Field label={t('DATE')} />
            </div>
          </div>
          {isLAR() && !isCanada() && (
            <div className={styles.singlebox}>
              <div>{t('P13B-L')}</div>

              <ol>
                <li>{t('P14')}</li>
                <li>{tLAR('P15')}</li>
                <li>{t('P16B-L')} </li>
              </ol>
              <br />
              <br />
              <div className={styles.fields}>
                <Field label={t('P17')} width={300} />
                <Field label={t('DATE')} />
              </div>
              <div>{tLAR('P18')}</div>
              <div className={styles.fields}>
                <Field label={t('P19')} />
                <Field label={t('DATE')} />
              </div>
            </div>
          )}
        </div>
        <div className={styles.fontMedLeft}>{tCANLAR('PCOPYWRITE')}</div>
      </div>
      <div className={styles.buttonBar}>
        <CtaButton label={t('Print')} onClick={() => print()} />
        <CtaButton
          label={t('Close')}
          onClick={() => {
            // clearCart();
            doSearch(userProfile.memberId, false);
            setTimeout(() => navigate('/'), 100);
          }}
        />
      </div>
    </div>
  );
};
