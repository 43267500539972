import amplitude from 'amplitude-js';
import { APIHelper } from '../../models/api-helper';
import { encryptFormData } from '../../utils/url.utils';
import { get, post } from '../generic-service';
import ServiceHandler from '../service-handler';

export const filterOffers = (offers: any, couponTypes: string) =>
  offers?.filter((o: any) => couponTypes.includes(o.couponTypeAttributes.find((a: any) => a.code == 'pfsTransactionType')?.value)) || [];

export const filterCatalog = (offers: any, couponTypes: string) =>
  offers?.filter((o: any) => couponTypes.includes(o.attributes.find((a: any) => a.code == 'pfsTransactionType')?.value)) || [];

export default class OffersService {
  public async requestOffers(user: any, category: string, rewardProgram: string, apiHelper: APIHelper) {
    return await get('OFFERS', user.lighthouseGuid, { guid: user.lighthouseGuid, rewardProgram: rewardProgram, category: category }, apiHelper);
  }

  public async requestRewardsCatalog(user: any, rewardProgram: string, apiHelper: APIHelper) {
    return await get(
      'REWARD_CATALOG',
      user.lighthouseGuid,
      { rewardProgram: rewardProgram, partnerCategory: 'D', rewardCategory: `REDEEMABLE_OFFERS_${rewardProgram == 'F' ? 'FPR' : 'LAR'}` },
      apiHelper,
      user.lighthouseGuid
    );
  }
  public async redeemOffer(formData: any, apiHelper: APIHelper, setShowAlert: any, t: any, refresh: any) {
    formData.append('dealerSiteCode', apiHelper.siteCode);
    formData.append('dealerUsername', ServiceHandler.AuthenticationService.getDealerUserName().replace('\\', '-'));

    await post('REDEEM_OFFER', null, {}, formData, null, apiHelper).then(response => {
      if (response?.submitPrivateOffer?.errorMessage) {
        setShowAlert({ title: t('ERROR'), message: response.submitPrivateOffer.errorMessage });
        amplitude.getInstance().logEvent('pfs redeemoffer error', {
          response: response.submitPrivateOffer,
          form: encryptFormData(formData),
          barcode: formData.get('couponBarcode'),
        });
        return false;
      } else if (response?.submitPrivateOffer) {
        amplitude.getInstance().logEvent('pfs redeemoffer', { barcode: formData.get('couponBarcode') });

        setShowAlert({ title: t('SUCCESS'), message: t('Offer successfully redeemed') });
        refresh();
        return true;
      } else {
        setShowAlert({ title: t('ERROR'), message: t('Something went wrong. Please try again after sometime!') });
        amplitude.getInstance().logEvent('pfs redeemoffer error', { form: encryptFormData(formData), barcode: formData.get('couponBarcode') });
        return false;
      }
    });
  }

  public async redeemServiceOffer(user: any, params: any, formData: any, apiHelper: APIHelper) {
    return post('REDEEM_SERVICE_OFFER', user.lighthouseGuid, params, formData, { 'Content-Type': 'multipart/form-data' }, apiHelper);
  }

  public async redeemSalesOffer(user: any, params: any, formData: any, apiHelper: APIHelper) {
    return post('REDEEM_SALES_OFFER', user.lighthouseGuid, params, formData, { 'Content-Type': 'multipart/form-data' }, apiHelper);
  }

  public getError(response, t, sales) {
    const err = response.data?.error?.message || '';

    let msg = t('Something went wrong. Please try again after sometime!');
    if (err.includes('is invalid')) msg = t(err);
    if (err.includes('[TRN_TRANSACTION_ALREADY_EXISTS]'))
      msg = sales == 'sales' ? t('TRN_TRANSACTION_ALREADY_EXISTS_SALES') : t('TRN_TRANSACTION_ALREADY_EXISTS_SERVICE');

    if (err.includes('[TRN_REDEMPTION_BLOCKED_FOR_CUSTOMER]')) msg = t('TRN_REDEMPTION_BLOCKED_FOR_CUSTOMER');
    if (err.includes('[LATE_REWARD_CHOICE_NOT_ALLOWED_SALE_TYPE]')) msg = t('LATE_REWARD_CHOICE_NOT_ALLOWED_SALE_TYPE');
    if (err.includes('[CPO_PMP_BEFORE_START_DATE]')) msg = t('CPO_PMP_BEFORE_START_DATE');
    if (err.includes('[TRN_CANCELED_DUE_TO_BUSINESS_ERROR]')) msg = t('TRN_CANCELED_DUE_TO_BUSINESS_ERROR');
    if (err.includes('[MULTIPLE_SALE_TRANSACTION_MATCH]')) msg = t('MULTIPLE_SALE_TRANSACTION_MATCH');
    if (err.includes('[LRC_EARN_TYPE_MISMATCH]')) msg = t('LRC_EARN_TYPE_MISMATCH');
    if (err.includes('Customer is not eligible to redeem')) msg = t('Customer is not eligible to redeem');
    if (err.includes('<h2>Bad Request</h2>')) msg = 'Unknown authentication Issue.  Please logout, log back in, and retry.';

    if (err.includes('[TRN_NOT_ENOUGH_POINTS]')) msg = err.split(']')[1];
    if (err.includes('[MULTIPLE_REMOTE_EXPERIENCE_COUPONS_NOT_ALLOWED]')) msg = t('MULTIPLE_COUPONS_NOT_ALLOWED');
    if (err.includes('[REWARD_CHOICE_INCORRECT_PROGRAM]')) msg = t('INCORRECT_PROGRAM');

    return msg;
  }
}
