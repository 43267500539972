import React, { useContext, useEffect, useState } from "react";
import utils from "../../../utils/utils.module.scss";
import { CtaButton, CtaSecondaryButton } from "../../common/cta/cta";
import { Column, TwoColumnRow } from "../../common/column/column";
import { Header } from "../../common/header/header";
import { RewardProgramChoice } from "../../sections/choice-reward-program/choice-reward-program";
import { PointsDollarsEntry } from "../../sections/points-dollars-entry/points-dollars-entry";
import { CustomerContext } from "../../../context/customer-context";

import GoodwillPointsService from "../../../services/goodwill-points-service/goodwill-points-service";
import { APIHelperContext } from "../../../context/api-helper-context";
import { AlertDialogContext } from "../../../context/alert-dialog-context";
import {
  Body,
  Buttons,
  ModalDialog,
} from "../../common/modal-dialog/modal-dialog";
import { TranslationContext } from "../../../context/translations-context";
import { InputsContext } from "../../../context/inputs-context";

import { useAmplitude } from "../../../hooks/useAmplitude";
import { AuthContext } from "../../../context/auth-context";
import styles from "./goodwill-points-dialog.module.scss";
import { ErrorText } from "../../common/error-text/error-text";
import { SiteContext } from "../../../context/site-context";

const COMMENT_MAX = 250;

interface Props {
  onClose?(): void;
}
export const GoodwillPointsDialog = ({ onClose }: Props) => {
  const [{ t }] = useContext(TranslationContext);
  const [apiHelper] = useContext(APIHelperContext);
  const [logAmplitudeEvent] = useAmplitude();
  const [translation] = useContext(TranslationContext);
  const [{ isCanada, siteCode }] = useContext(SiteContext);

  const [{ setShowAlert }] = useContext(AlertDialogContext);
  const { isOk2Submit } = useContext(AuthContext);

  const [goodwillDollars, setGoodwillDollars] = useState(0);
  const [goodwillPoints, setGoodwillPoints] = useState(0);
  const [loading, setLoading] = useState(false);

  const [{ userProfile, rewardProgram }, { doSearch }] =
    useContext(CustomerContext);
  // eslint-disable-next-line no-empty-pattern
  const [{}, { clearInputs }] = useContext(InputsContext);
  const [agree, setAgree] = useState(false);
  const [comment, setComment] = useState("");
  const [reasonCode, setReasonCode] = useState("");

  useEffect(() => {
    setAgree(false);
    clearInputs();
  }, []);

  const doClose = () => {
    onClose();
    setAgree(false);
    clearInputs();
  };

  const doSubmit = () => {
    if (loading) return;
    const data = {
      description: comment || "  ",
      reasonCode: reasonCode,
      memberGuid: userProfile.lighthouseGuid,
      pointAmount: goodwillPoints,
      rewardProgram,
    };

    setLoading(true);
    const goodwillService = new GoodwillPointsService();
    goodwillService.request(data, apiHelper).then((result: any) => {
      setLoading(false);
      doClose();
      if (result.status == "error") {
        logAmplitudeEvent("goodwill error", translation, { result });
        let msg =
          result.data?.errorMessage || result.data?.error?.message || "";
        if (msg.includes("[DGP_PTS_LIMIT_REACHED]"))
          msg = t("DGP_PTS_LIMIT_REACHED");
        if (msg.includes("[DGP_SSH_LIMIT_REACHED]"))
          msg = t("DGP_SSH_LIMIT_REACHED");
        setShowAlert({
          title: t("ERROR"),
          message: msg || "Unable to grant points",
        });
      } else {
        logAmplitudeEvent("goodwill success", translation, {
          points: +result.pointBalance[0].points,
        });
        doSearch(userProfile.memberId, false);

        setShowAlert({
          title: `${t("SUCCESS")}!`,
          message: `${(+result.pointData[0].points).toLocaleString()} ${t(
            "Goodwill Points have been awarded successfully!"
          )}`,
        });
      }
    });
  };
  const isHotlineError = () => {
    if (reasonCode != "DGP_SoftwareServiceHotline") return false;
    return goodwillPoints != 15000;
  };

  return (
    <ModalDialog title={t("GOODWILL_POINTS")} width={"660px"} doClose={doClose}>
      <Body>
        <div className={utils.bottomSpacerLarge}>
          <RewardProgramChoice uniqueID="goodwill" />
        </div>

        <Header title={t("Points")} icon="star">
          <TwoColumnRow>
            <PointsDollarsEntry
              isGoodWillDialog={true}
              inputs={[
                goodwillPoints,
                goodwillDollars,
                setGoodwillPoints,
                setGoodwillDollars,
              ]}
            />
          </TwoColumnRow>

          {isHotlineError() && (
            <ErrorText
              className={styles.error}
              text={t("SERVICEHOTLINEERROR")}
            />
          )}
        </Header>
        {goodwillPoints > 0 && (
          <>
            <TwoColumnRow>
              <div className={styles.reason}>
                <div>
                  {t("Reason")} <i>({t("required")}):</i>
                </div>
                <select
                  onChange={(e) => {
                    setReasonCode(e.target.value);
                    if (e.target.value == "DGP_SoftwareServiceHotline") {
                      setGoodwillPoints(15000);
                      setGoodwillDollars(75);
                    }
                  }}
                >
                  <option value="">{t("Select a reason")}</option>
                  <option value="DGP_SalesMissedRewards">{t("GW-1")}</option>
                  <option value="DGP_ServiceMissedRewards">{t("GW-2")}</option>
                  <option value="DGP_OilChange">{t("GW-3")}</option>
                  <option value="DGP_SalesGuestSatisfactionIssue">
                    {t("GW-4")}
                  </option>
                  <option value="DGP_ServiceGuestSatisfactionIssue">
                    {t("GW-5")}
                  </option>
                  <option value="DGP_SalesPointsRefund">{t("GW-6")}</option>
                  <option value="DGP_ServicePointsRefund">{t("GW-7")}</option>
                  <option value="DGP_SalesSurpriseDelight">{t("GW-8")}</option>
                  <option value="DGP_ServiceSurpriseDelight">
                    {t("GW-9")}
                  </option>
                  {!isCanada() && (
                    <option value="DGP_SoftwareServiceHotline">
                      {t("GW-10")}
                    </option>
                  )}
                </select>
              </div>

              <div className={styles.commentBox}>
                <div>
                  {t("Comments")} <i>({t("optional")}):</i>
                </div>
                <textarea
                  id="commentBox"
                  required
                  name="commentBox"
                  maxLength={COMMENT_MAX}
                  value={comment}
                  onChange={(e: any) =>
                    setComment(e.target.value.replace(/[<>*{}()|~/]/g, ""))
                  }
                />
                <span>
                  {COMMENT_MAX - comment.length} {t("Characters Remaining")}
                </span>
              </div>
            </TwoColumnRow>

            <Header title={t("Acknowledgement")}>
              {/* {formatPoints(goodwillPoints)} {t(" Points will be applied to Member's account")} */}
              <div className={styles.areyousure}>
                <input
                  id="goodwill"
                  type="checkbox"
                  onChange={(e) =>
                    setAgree((e.target as HTMLInputElement).checked)
                  }
                  aria-label={t("R_U_SURE_GOODWILL")}
                />
                <label htmlFor="goodwill">
                  {t("R_U_SURE_GOODWILL")}
                  <a href="https://web.cmi.dealerconnection.com/web/ext/fpracademy/downloads/FPR_MemberPortalGuide_JobAid.pdf">
                    ({t("Goodwill Policy")})
                  </a>
                </label>
              </div>
            </Header>
          </>
        )}
      </Body>
      <Buttons>
        {!loading && (
          <CtaSecondaryButton label={t("CANCEL")} onClick={doClose} />
        )}
        <CtaButton
          label={t("Apply")}
          onClick={doSubmit}
          disabled={
            !agree ||
            goodwillPoints == 0 ||
            goodwillPoints % 2 == 1 ||
            !reasonCode ||
            !isOk2Submit ||
            isHotlineError()
          }
          loading={loading}
        />
      </Buttons>
    </ModalDialog>
  );
};
