import React, { useContext, useEffect, useState } from 'react';
import styles from './tab-connected-services-view.module.scss';
import utils from '../../../utils/utils.module.scss';
import cx from '../../../utils/classnames';
import { ConnectedServicesContext } from '../../../context/connected-services-context';
import { TranslationContext } from '../../../context/translations-context';
import { CustomerContext } from '../../../context/customer-context';
import { DataGrid } from '../../common/data-grid/data-grid';
import StarsIdService from '../../../services/stars-id-service/stars-id-service';
import { SelectStars } from './components/stars/select-stars-id';
import { SelectVehicle } from './components/garage/select-vehicle';
import { CtaLink, CtaSecondaryButton } from '../../common/cta/cta';
import { ActivityIndicator } from '../../common/activity-indicator/activity-indicator';
import { ConnectedServiceDetailsDialog } from '../../dialogs/connected-services-details/connected-services-details-dialog';
import { ConnectedServiceLinkDialog } from '../../dialogs/connected-services-link/connected-services-link-dialog';
import { APIHelperContext } from '../../../context/api-helper-context';
import ServiceHandler from '../../../services/service-handler';
import { AddToGarageDialog } from '../../dialogs/add-to-garage/add-to-garage-dialog';
import { AuthorizeModemDialog } from '../../dialogs/auth-modem/auth-modem-dialog';
import { PrivacyPolicy } from '../../common/privacy-policy/privacy-policy';
import { SmsTCsDialog } from '../../dialogs/text-msg-tc/text-msg-tc-dialog';
import { SiteContext } from '../../../context/site-context';
import { AltErrorText } from '../../common/error-text/error-text-alt';
import amplitude from 'amplitude-js';
// import { FinFormView } from '../../../views/financing-form-view/financing-form-view';

export const TabConnectedServicesView = () => {
  const [{ garage, vehicle, refreshGarage, paidSubscriptions, freeSubscriptions, loadSubscriptionOffers, selectedStarsId, hasModem }] =
    useContext(ConnectedServicesContext);

  const [{ t, formatDate }] = useContext(TranslationContext);
  const [userProfiles] = useContext(CustomerContext);
  const [starsIds, setStarsIds] = useState({});
  // const [showFinForm, setShowFinForm] = useState<null | any>(null);
  const [showDetails, setShowDetails] = useState<null | any>(null);
  const [showLink, setShowLink] = useState<null | any>(null);
  const [showAddVehicle, setShowAddVehicle] = useState<null | string>(null);
  const [showAuthModem, setShowAuthModem] = useState<null | string>(null);
  const [showSmsTCs, setShowSmsTCs] = useState<null | string>(null);
  const [sortedStarsIds, setSortedStarsIds] = useState<null | any>(null);
  const [apiHelper] = useContext(APIHelperContext);
  const [{ isCanada }] = useContext(SiteContext);
  const [{ userProfile }] = useContext(CustomerContext);
  const firstName = userProfiles.userProfile.firstName;


  useEffect(() => {
    if (apiHelper) {
      const selectedStarsId = new StarsIdService();
      selectedStarsId.request(apiHelper).then((response: any) => {
        if (response.status == 'error') return setSortedStarsIds('error');
        if (response.employees) setSortedStarsIds([...response.employees].sort((a, b) => a.firstName.localeCompare(b.firstName)));
      });
    }
  }, [apiHelper]);

  useEffect(() => {
    if (vehicle) {
      loadSubscriptionOffers(vehicle);
    }
  }, [vehicle]);

  useEffect(() => {
    if (userProfile) {
      const starsIdService = new StarsIdService();
      starsIdService.request(apiHelper).then((response: any) => {
        setStarsIds(response);
      });
      refreshGarage()
    }
  }, [userProfile]);


  const handleRefreshGarage = () => {
    refreshGarage()
  };

  const renderDate = (dt: string) => {
    if (dt == 'MultipleOptions') return t('Multiple Options');
    if (dt == 'N/A') return '';
    return formatDate(new Date(dt.replace(/-/g, '/')));
  };

  // const renderFinFormBtn = (unused: string, dataItem: any) => {
  //   return dataItem?.financeable == false ? (
  //     'Not Available'
  //   ) : (
  //     <a
  //       onClick={() => {
  //         window.open('/fin-auth-form');
  //       }}
  //     >
  //       {t('Authorization Form')}
  //     </a>
  //   );
  // };

  const renderDetailBtn = (unused: string, dataItem: any) => {
    return dataItem?.productDetailsLinkDisplayFlag == false ? (
      ''
    ) : (
      <a
        onClick={() => {
          setShowDetails(dataItem);
        }}
      >
        {t('Product Details')}{' '}
      </a>
    );
  };

  const renderStatus = (status: string) => {
    return <b className={styles[status.replace(/ /g, '')]}>{status}</b>;
  };

  const renderLink = (unused: string, dataItem: any) => {
    return dataItem.activationLinkDisplayFlag == true ? (
      <button
        className={cx(utils.buttonPrimary, styles.button)}
        onClick={() => {
          setShowLink(dataItem);

          amplitude.getInstance().logEvent('pfs SSP dialog', {
            offerSku: dataItem.productSku,
            offerName: dataItem.name,
            classification: dataItem.classification,
          });
        }}
      >
        {t('Activation Link')}
      </button>
    ) : (
      ''
    );
  };

  const freeColumns = [
    { id: 'name', title: t('NAME') },
    { id: 'status', title: t('STATUS'), render: renderStatus },
    { id: 'expirationDate', title: t('Expiration Date'), render: renderDate },
    { id: 'name', title: t('SSP_DESC'), render: renderDetailBtn },
    { id: 'name', title: '', render: renderLink },
  ];

  const paidColumns = [
    { id: 'name', title: t('NAME') },
    { id: 'status', title: t('STATUS'), render: renderStatus },
    { id: 'expirationDate', title: t('Expiration Date'), render: renderDate },
    { id: 'name', title: t('SSP_DESC'), render: renderDetailBtn },
    { id: 'name', title: '', render: renderLink },
  ];

  return (
    <>
      <div className={styles.connectedServicesContainer}>
        {showDetails && <ConnectedServiceDetailsDialog offer={showDetails} doClose={() => setShowDetails(null)} />}
        {showLink && <ConnectedServiceLinkDialog offer={showLink} doClose={() => setShowLink(null)} />}
        {showAddVehicle && <AddToGarageDialog make={showAddVehicle} doClose={() => setShowAddVehicle(null)} />}
        {showAuthModem && <AuthorizeModemDialog make={showAuthModem} doClose={() => setShowAuthModem(null)} />}
        {showSmsTCs && <SmsTCsDialog doClose={() => setShowSmsTCs(null)} />}

        {sortedStarsIds != null ? (
          sortedStarsIds == 'error' ? (
            <AltErrorText text={t('CS_UNAVAILABLE')} subtext={t('CS_UNAVAILABLE_SUBTEXT')} />
          ) : (
            <>
              <div className={styles.idAndGarageText}>STARS ID</div>
              <div>
                <SelectStars options={sortedStarsIds} />
              </div>
            </>
          )
        ) : (
          <ActivityIndicator />
        )}

        <br />
        {selectedStarsId && (
          <div className={styles.garageColumns}>
            <div>
              <div className={styles.idAndGarageText}>{t('MEMBER_GARAGE').replace('{firstName}', firstName)}</div>
              <SelectVehicle vehicles={garage || []} />
            </div>
            <div>
              <div className={styles.howToAdd}>
                {t('HOW_TO_GARAGE')}
                <CtaLink label={'Ford'} onClick={() => setShowAddVehicle('F')} />
                {' or '}
                <CtaLink label={'Lincoln'} onClick={() => setShowAddVehicle('L')} />
              </div>
              <CtaSecondaryButton onClick={handleRefreshGarage} className={styles.refreshBtn}>
                {t('Refresh Garage')}
              </CtaSecondaryButton>
            </div>
            <div></div>
          </div>
        )}

        <br />


        {vehicle && !hasModem && (
          <div className={styles.modemUnauth}>
            {vehicle.modelYear} {vehicle.make} {vehicle.modelName} {t('NO_MODEM')}
            <br />
            To continue, please have the customer authorize their modem then <CtaLink onClick={handleRefreshGarage}>refresh the Garage</CtaLink>.
            <br />
            <CtaLink onClick={setShowAuthModem}>{t('AUTH_MODEM')}</CtaLink>
          </div>
        )}


        {((vehicle && hasModem && paidSubscriptions !== false) ||
          (vehicle && hasModem && (paidSubscriptions?.length === 0 || paidSubscriptions === false))) && (
          <div className={styles.vehicleDesc}>
            <div>{t('Connected Services for:')} </div>
            <div>
              <img
                src={
                  // vehicle.vehicleImageUrl ? vehicle.vehicleImageUrl :   // commented out temporarily for backend
                  `${ServiceHandler.ConfigService.AEM_BASE_URL}/content/dam/loyalty/common/images/NoVehicle_Placeholder.png`
                }
              />
            </div>
            <div className={styles.selectedVehicle}>
              {vehicle.modelYear} {vehicle.make} {vehicle.modelName}
              <br />
              {vehicle.vin}
            </div>
          </div>
        )}

        {vehicle && hasModem && (freeSubscriptions?.length === 0 || freeSubscriptions === false) && (
          <>
            <br />
            <br />
            <br />
            <br />
            <h2 className={styles.tableHeading}>{t('Free Trials')}</h2>
            <div className={styles.emptyTable}>
              <div className={styles.noOffers}>{t('NO_FREE')}</div>
            </div>
          </>
        )}

        {vehicle && freeSubscriptions?.length > 0 && hasModem && (
          <>
            <br />
            <br />
            <br />
            <br />
            <br />
            <h2 className={styles.tableHeading}>
              {t('Free Trials')}
              <CtaSecondaryButton onClick={() => loadSubscriptionOffers(vehicle)} className={styles.refreshFreePaidBtn}>
                {t('Refresh Product Status')}
              </CtaSecondaryButton>
            </h2>
            <DataGrid data={freeSubscriptions} columns={freeColumns} sortable={false} />
            <div className={styles.disclaimer}>{t('SSP_DISCLAIMER')}</div>
          </>
        )}

        {vehicle && hasModem && (paidSubscriptions?.length === 0 || paidSubscriptions === false) ? (
          <>
            <br />
            <br />

            <h2 className={styles.tableHeading}>{t('Paid Services')}</h2>
            <div className={styles.emptyTable}>
              <div className={styles.noOffers}>{t('NO_PAID')}</div>
            </div>
          </>
          ) : (
          <>{vehicle && hasModem && paidSubscriptions?.length == undefined && <ActivityIndicator />}</>
        )}

        {vehicle && paidSubscriptions?.length > 0 && paidSubscriptions !== false && hasModem && (
          <>
            <br />
            <br />
            <br />
            <h2 className={styles.tableHeading}>
              {t('Paid Services')}
              <CtaSecondaryButton onClick={() => loadSubscriptionOffers(vehicle)} className={styles.refreshFreePaidBtn}>
                {t('Refresh Product Status')}
              </CtaSecondaryButton>
            </h2>
            <DataGrid data={paidSubscriptions} columns={paidColumns} sortable={false} />
            <div className={styles.disclaimer}>{t('SSP_DISCLAIMER')}</div>
          </>
        )}
        <div className={styles.privacy}>
          {t('Ford and Lincoln Privacy Notice: ')}
          <PrivacyPolicy />
        </div>
        {!isCanada() && (
          <>
            <div className={styles.textTCs}>
              {t('Text Messaging Terms and Conditions: ')}
              <CtaLink className={styles.textTCs} onClick={setShowSmsTCs}>
                {t('Ford and Lincoln SMS T&Cs')}
              </CtaLink>
            </div>
          </>
        )}
      </div>
    </>
  )
};
