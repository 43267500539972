import React from 'react';
import cx from '../../../utils/classnames';
import utils from '../../../utils/utils.module.scss';

interface Props {
  children?: React.ReactNode;
  className?: any;
}

export function TwoColumnRow({ children, className }: Props) {
  return <div className={cx(utils.twoColumn, className)}>{children}</div>;
}

export function ThreeColumnRow({ children, className }: Props) {
  return <div className={cx(utils.threeColumn, className)}>{children}</div>;
}
export function DoubleColumn({ children, className }: Props) {
  return <div className={cx(className, utils.column, utils.doubleColumn)}>{children}</div>;
}

export function Column({ children, className }: Props) {
  return <div className={cx(className, utils.column)}>{children}</div>;
}

export function Section({ children, className }: Props) {
  return <div className={cx(className, utils.section)}>{children}</div>;
}
