import React, { useContext, useEffect, useState } from 'react';
import { APIHelperContext } from '../../../context/api-helper-context';
import { CustomerContext } from '../../../context/customer-context';
import { TranslationContext } from '../../../context/translations-context';
import RewardsHistoryService from '../../../services/rewards-history-service/rewards-history-service';
import { ActivityIndicator } from '../../common/activity-indicator/activity-indicator';
import { CtaButton } from '../../common/cta/cta';
import { DataGrid } from '../../common/data-grid/data-grid';
import { ModalDialog, Body, Buttons } from '../../common/modal-dialog/modal-dialog';

import styles from './rewards-history-dialog.module.scss';
interface Props {
  data: any;
  rewardProgram: string;
  onClose?(): void;
}

export const RewardsHistoryDialog = ({ onClose, rewardProgram }: Props) => {
  const [{ t, formatDate }] = useContext(TranslationContext);
  const [apiHelper] = useContext(APIHelperContext);
  const [{ userProfile }] = useContext(CustomerContext);
  const [data, setData] = useState(null);

  const renderRedeemPoints = (pointData: any) => {
    const total = pointData?.reduce((x: any, t: any) => x + t.points, 0);
    return total < 0 ? (total * -1).toLocaleString() : '';
  };
  const renderEarnPoints = (pointData: any) => {
    if (pointData?.length && pointData[0].projectedPointsMessage) return pointData[0].projectedPointsMessage;
    const total = pointData?.reduce((x: any, t: any) => x + t.points, 0);
    return total > 0 ? total.toLocaleString() : '';
  };
  const renderDate = (dt: string) => formatDate(new Date(dt));

  const columns = [
    { id: 'date', title: t('DATE'), render: renderDate },
    { id: 'description', title: t('DESCRIPTION')},
    { id: 'invoiceID', title: t('INVOICE_#') },
    { id: 'pointData', title: t('Points Redeemed'), render: renderRedeemPoints },
    { id: 'pointData', title: t('Points Earned'), render: renderEarnPoints },
    { id: 'partnerUserID', title: t('Dealer Employee ID') },
  ];
  

  useEffect(() => {
    const rewardHistoryService = new RewardsHistoryService();
    rewardHistoryService.request({ rewardProgram, userGuid: userProfile.lighthouseGuid }, apiHelper).then((response: any) => {
      setData(response.transactions.filter(t => t.pointData.length));
    });
  }, []);

  return (
    <ModalDialog title={t('REWARDS_HISTORY')} width='85vw' doClose={onClose}>
      <Body>
        {!data ? (
          <ActivityIndicator />
        ) : data?.length ? (
          <DataGrid data={data} columns={columns} className={styles.fixed_header} initialSort='date' />
        ) : (
          <div className={styles.noData}>This member has no Rewards History</div>
        )}
      </Body>
      <Buttons>
        <CtaButton label={t('Close')} onClick={onClose} />
      </Buttons>
    </ModalDialog>
  );
};
