/* eslint-disable @typescript-eslint/no-namespace */
import React, { useContext, useState } from 'react';
import { Body, Buttons, ModalDialog } from '../../common/modal-dialog/modal-dialog';
import { CtaButton } from '../../common/cta/cta';
import { RadioItem } from '../../common/radio-item/radio-item';

import styles from './choose-customer-dialog.module.scss'; // eslint-disable-line no-redeclare
import { CustomerContext } from '../../../context/customer-context';
import { formatPhone } from '../../sections/member-search/member-search';
import { TranslationContext } from '../../../context/translations-context';

interface Props {
  customers: Array<any>;
  doClose(): void;
}
export const ChooseCustomerDialog = ({ customers, doClose }: Props) => {
  const [{ t }] = useContext(TranslationContext);
  // eslint-disable-next-line no-empty-pattern
  const [{}, { doSearch }] = useContext(CustomerContext);
  const [chosen, setChosen] = useState<any>(null);

  const doSubmit = () => {
    if (chosen) {
      doSearch(chosen, true);
    }
    doClose();
  };

  return (
    <>
      <ModalDialog title={t('Select Member')} width={'48vw'} doClose={doClose}>
        <Body>
          <p className={styles.heading}>{t('MULTIPLE_MEMBERS')}</p>
          <div className={styles.radios} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const selectedCustomer = customers.find(c => c.email === e.target.id);
            setChosen(selectedCustomer?.memberId);
          }}>
            {customers.map((c, i) => (
              <RadioItem key={`customer${i}`} name='customer' id={c.email}>
                <div className={styles.record}>
                  <div>
                    <b>
                      {c.firstName} {c.lastName}
                    </b>
                    {c.phoneNumber && (
                      <span>
                        <b>{t('PHONE')}:</b> {formatPhone(c.phoneNumber)}
                      </span>
                    )}
                  </div>

                  <div>{c.email?.toLowerCase()} </div>
                  {c.address1 && (
                    <div>
                      {c.address1}, {c.city} {c.state}, {c.zip}
                    </div>
                  )}
                  {c.memberId && (
                    <div>
                      {t('MEMBER_ID')}: {c.memberId}
                    </div>
                  )}
                </div>
              </RadioItem>
            ))}
          </div>
        </Body>
        <Buttons>
          <CtaButton className={styles.button} label={t('Select')} onClick={doSubmit} />
        </Buttons>
      </ModalDialog>
    </>
  );
};
