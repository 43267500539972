/* eslint-disable no-useless-escape */
import React, { useContext } from 'react';
import { TranslationContext } from '../../../context/translations-context';

export const currencyToFloat = (value: string) => {
  return value
    ? Number.parseFloat(
        value
          .toString()
          .replace(/[^0-9\.,]/g, '')
          .replace(/,(\d\d)$/, '.$1')
          .replace(/,/g, '')
      )
    : 0;
};

export function Currency({ data }: any) {
  const [{ formatCurrency }] = useContext(TranslationContext);
  return <>{formatCurrency(data)}</>;
}

export function CurrencyNoDollar({ data }: any) {
  const [{ formatCurrency }] = useContext(TranslationContext);
  if (!data) return '-.--';
  return formatCurrency(data).replace('$', '');
}
