import React, { useContext } from 'react';
import { AlertDialogContext } from '../../../context/alert-dialog-context';
import { TranslationContext } from '../../../context/translations-context';
import { CtaButton } from '../../common/cta/cta';
import { Body, Buttons, ModalDialog } from '../../common/modal-dialog/modal-dialog';

export const AlertDialog = () => {
  const [{ showAlert, setShowAlert }] = useContext(AlertDialogContext);
  const [{ t }] = useContext(TranslationContext);

  return (
    <>
      {showAlert && (
        <ModalDialog title={showAlert.title}>
          <Body>{showAlert.message}</Body>
          <Buttons>
            <CtaButton label={t('OK')} onClick={() => setShowAlert(null)} />
          </Buttons>
        </ModalDialog>
      )}
    </>
  );
};
