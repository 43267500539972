import React from 'react';
import cx from '../../../utils/classnames';
import utils from '../../../utils/utils.module.scss';
import styles from './labeled-data.module.scss';

interface Props {
  title?: string;
  className?: string;
  data?: React.ReactNode;
  children?: React.ReactNode;
}

export function LabeledData({ title, className, data, children }: Props) {
  return (
    <div className={cx(utils.bottomSpacer, className)}>
      {title && <div className={styles.label}>{title}</div>}
      <>{data || children || '--'}</>
    </div>
  );
}
