import React from 'react';
import styles from './activity-indicator.module.scss';
import utils from '../../../utils/utils.module.scss';
import cx from '../../../utils/classnames';

interface Props {
  white?: boolean;
  size?: 'L' | 'S';
  className?: string;
}
export const ActivityIndicatorSmall = ({ className }: Props) => {
  return <ActivityIndicator size='S' className={className} />;
};

export const ActivityIndicator = ({ white, size = 'L', className }: Props) => {
  const wrapperClass = cx(styles.wrapper, utils.mT3, { [styles.white]: white, [styles.small]: size === 'S' }, className);
  return (
    <div className={wrapperClass} role='progressbar' id='progressbar' title='progress bar'>
      <div className={styles.line}>
        <svg className={styles.lineSvg} viewBox='25 25 50 50'>
          <circle className={'activity-indicator ' + styles.lineIndeterminate} cx='50' cy='50' r='23' fill='none' strokeMiterlimit='10' />
        </svg>
      </div>
    </div>
  );
};
