import React, { useState, useContext, useEffect } from 'react';
import SearchService from '../services/search-service/search-service';
import OffersService from '../services/offers-service/offers-service';
import { APIHelperContext } from './api-helper-context';
import { InputsContext, REWARD_CHOICE_DEFAULTED } from './inputs-context';
import { AuthContext } from './auth-context';
import { SEARCH_STATE } from '../components/sections/member-search/member-search';
import { useAmplitude } from '../hooks/useAmplitude';
import { TranslationContext } from './translations-context';

export const CustomerContext = React.createContext<any>({
  points: null,
  transactions: null,
  userProfiles: null,
  doSearch: null,
});

interface Props {
  children: React.ReactNode;
}
export const CustomerContextProvider = ({ children }: Props) => {
  const { isLocal } = useContext(AuthContext);
  const [points, setPoints] = useState(null);

  const [transactions, setTransactions] = useState(null);
  const [userProfile, setUserProfile] = useState(null);

  const [offers, setOffers] = useState(null);
  const [loading, setLoading] = useState(false);
  const [notFound, setNotFound] = useState<boolean | Array<any>>(false);
  const [apiHelper] = useContext(APIHelperContext);
  const [rewardProgram, setRewardProgram] = useState('F');

  const [{ rewardChoiceDefault, rewardChoiceDefaultPoints }] = useContext(InputsContext);
  const [searchOption, setSearchOption] = useState(SEARCH_STATE.MEMBER);
  const [logAmplitudeEvent] = useAmplitude();
  const [translation] = useContext(TranslationContext);

  const refreshOffers = () => {
    const consolodateSingleUseOffers = (offers: any) => {
      if (!offers) return [];
      const regularOffers = offers.filter((f: any) => !f.couponTypeAttributes.find((a: any) => a.code == 'maxUsagePerTransaction'));
      const singleOffers = offers.filter((f: any) => f.couponTypeAttributes.find((a: any) => a.code == 'maxUsagePerTransaction'));
      const uniqueTypes = Array.from(new Set(singleOffers.map((f: any) => f.type)));
      const oneOfferPerType = uniqueTypes.map(f => {
        const offersByType = singleOffers.filter((e: any) => e.type == f);
        offersByType[0].couponTypeName += ` (1 of ${offersByType.length})`;
        return offersByType[0];
      });

      return [...regularOffers, ...oneOfferPerType];
    };

    if (userProfile && points) {
      const offerService = new OffersService();
      /* tslint:disable-next-line */
      const _offers = { F: {}, L: {} };

      if (points.eligibility?.F.status == 'ELIGIBLE') {
        offerService.requestOffers(userProfile, 'DRC', 'F', apiHelper).then((payload: any) => {
          _offers.F['drc'] = consolodateSingleUseOffers(payload.offers);

          _offers.F['drc'].forEach(offer => {
            offer.memberDiscount = +offer.couponTypeAttributes.find((a: any) => a.code == 'memberDiscount')?.value;
          });
          setOffers({ ..._offers });
        });

        offerService.requestRewardsCatalog(userProfile, 'F', apiHelper).then((payload: any) => {
          if (payload.rewards?.length) {
            payload.rewards.forEach(offer => {
              offer.memberDiscount = +offer.attributes.find((a: any) => a.code == 'memberDiscount')?.value;
              offer.barcode = offer.code;
              offer.couponTypeName = offer.rewardName;
            });

            _offers.F['rewards'] = payload.rewards;
            setOffers({ ..._offers });
          }
        });

        offerService.requestOffers(userProfile, 'DRO', 'F', apiHelper).then((payload: any) => {
          _offers.F['dro'] = payload.offers;
          setOffers({ ..._offers });
        });
      }
      if (points.eligibility?.L.status == 'ELIGIBLE') {
        offerService.requestOffers(userProfile, 'DRC', 'L', apiHelper).then((payload: any) => {
          _offers.L['drc'] = consolodateSingleUseOffers(payload.offers);
          _offers.L['drc'].forEach(offer => {
            offer.memberDiscount = +offer.couponTypeAttributes.find((a: any) => a.code == 'memberDiscount')?.value;
          });

          setOffers({ ..._offers });
        });

        offerService.requestOffers(userProfile, 'DRO', 'L', apiHelper).then((payload: any) => {
          payload.offers?.forEach(o => {
            o['remaining'] = o.partnerAttributes?.find(o => o.code == 'remaining')?.value;
          });

          _offers.L['dro'] = payload.offers;

          setOffers({ ..._offers });
        });

        offerService.requestRewardsCatalog(userProfile, 'L', apiHelper).then((payload: any) => {
          if (payload.rewards?.length) {
            payload.rewards.forEach(offer => {
              offer.memberDiscount = +offer.attributes.find((a: any) => a.code == 'memberDiscount')?.value;
              offer.barcode = offer.code;
              offer.couponTypeName = offer.rewardName;
            });
            _offers.L['rewards'] = payload.rewards;
            setOffers({ ..._offers });
          }
        });

        if (points.eligibility?.F.status != 'ELIGIBLE') setRewardProgram('L');
      }
    }
  };

  useEffect(() => {
    refreshOffers();
  }, [userProfile]);

  const clearSearch = () => {
    setNotFound(false);
    setOffers(null);
    setPoints(null);
    setUserProfile(null);
    setRewardProgram('F');
    setSearchOption(SEARCH_STATE.MEMBER);
  };

  function doSearch(searchTerm: string, loading = true, searchOption = SEARCH_STATE.MEMBER) {
    if (!searchTerm && !isLocal) return setNotFound(true);
    if (loading) {
      setLoading(true);
      clearSearch();
    }
    setOffers(null);
    setNotFound(false);

    //const isnum = /^\d+$/.test(searchTerm);
    let opt = SEARCH_STATE.MEMBER;
    if (searchOption == SEARCH_STATE.PHONE) opt = SEARCH_STATE.PHONE;
    else if (searchTerm?.includes('@')) opt = SEARCH_STATE.EMAIL;
    else if (searchTerm?.length == 17) opt = SEARCH_STATE.VIN;

    setSearchOption(opt);

    const searchService = new SearchService();
    searchService.request(searchTerm || (isLocal ? '1505093017' : ''), opt, apiHelper).then((payload: any) => {
      if (payload) {
        if (payload?.status == 'error' || payload?.userProfiles.userProfiles.length == 0) {
          setLoading(false);
          logAmplitudeEvent('search fail', translation, { type: opt });
          return setNotFound(true);
        }

        if (payload?.userProfiles.userProfiles.length > 1) {
          logAmplitudeEvent('search', translation, {
            //ver: global['pfs_app_version'],
            type: opt,
            resultCount: payload.userProfiles.userProfiles.length,
          });

          setLoading(false);
          return setNotFound(payload.userProfiles.userProfiles);
        }

        if (loading)
          logAmplitudeEvent('search', translation, {
            //ver: global['pfs_app_version'],

            type: opt,
            guid: payload?.userProfiles.userProfiles[0]?.lighthouseGuid || 'none',
          });
        setLoading(false);

        setPoints(payload?.points);

        const transactions = payload?.transactions?.transactions;
        transactions?.forEach((t: any) => {
          t.pointsEarned = t.pointBalances[0]?.points > 0 ? t.pointBalances.reduce((a, b) => (a + b.pointCategory != 'M' ? b.points : 0), 0) : 0;
          t.pointsRedeemed = t.pointBalances[0]?.points < 0 ? t.pointBalances.reduce((a, b) => a + b?.points || 0, 0) * -1 : 0;
        });
        setTransactions(transactions);

        payload.userProfiles.userProfiles[0].city = payload.userProfiles.userProfiles[0].city
          .replace(/ã©/g, 'é')
          // eslint-disable-next-line no-irregular-whitespace
          .replace(/ã /g, 'à')
          .replace(/ã¨/g, 'è');

        setUserProfile(payload?.userProfiles.userProfiles[0]);
      } else {
        setNotFound(true);
      }
      setLoading(false);
    });
  }

  const availablePoints = () =>
    +points?.pointsTotals?.[rewardProgram]?.points - (rewardChoiceDefault === REWARD_CHOICE_DEFAULTED.SWITCH_PMP ? rewardChoiceDefaultPoints : 0);

  const setAvailablePoints = val => {
    points.pointsTotals[rewardProgram].points = Math.max(0, val);
    setPoints({ ...points });
  };

  return (
    <CustomerContext.Provider
      value={[
        { userProfile, points, availablePoints, transactions, loading, notFound, offers, rewardProgram, searchOption },
        {
          setRewardProgram,
          doSearch,
          setPoints,
          setAvailablePoints,
          clearSearch,
          refreshOffers,
          setNotFound,
          setSearchOption,
        },
      ]}
    >
      {children}
    </CustomerContext.Provider>
  );
};
