import React, { useContext } from 'react';
import { ShoppingCartContext } from '../../../context/shopping-cart-context';
import cx from '../../../utils/classnames';
import { ErrorText } from '../error-text/error-text';
import styles from './input-with-label.module.scss';

interface Props {
  id?: string;
  label: string;
  value?: string;
  className?: string;
  minLength?: number;
  maxLength?: number;
  onChange?: (evnt: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (evnt: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (evnt: React.FocusEvent<HTMLInputElement>) => void;
  onClick?: (evnt: React.MouseEvent<HTMLInputElement>) => void;
  children?: React.ReactNode;
  disabled?: boolean;
  validator?(): string;
  setter?(val: string): void;
}

export const InputWithLabel = ({
  id,
  value,
  label,
  className,
  onChange,
  onBlur,
  onFocus,
  onClick,
  minLength,
  maxLength,
  disabled,
  children,
  validator,
  setter,
}: Props) => {
  const [{ showValidationErrors }, { setShowValidationErrors }] = useContext(ShoppingCartContext);

  return (
    <div className={cx(styles.inputGroup, className)}>
      <input
        id={id}
        disabled={disabled}
        type='text'
        className={styles.inputControl}
        placeholder='*'
        pattern='.*\S+.*'
        onChange={setter ? e => setter(e.target.value.trim()) : onChange}
        onBlur={e => {
          setShowValidationErrors(true);
          if (onBlur) onBlur(e);
        }}
        onFocus={onFocus}
        onClick={onClick}
        minLength={minLength}
        maxLength={maxLength}
        value={value}
      />
      <label htmlFor={id} className={styles.inputLabel}>
        {label}
      </label>
      {children}
      {showValidationErrors && validator && validator() && <ErrorText text={validator()} />}
    </div>
  );
};
