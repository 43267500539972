// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hbmxtF1ffM6YeipegtJK{font-size:30px;font-weight:bolder;width:50px !important;padding:0 11px !important;margin:6px 0px !important}.MPfdHuVNlAA7mGoKgbg8{max-width:175px}.oViMHAOswNMTQY9GPO4K{max-width:250px}.quA1DwyAigqGBRMWOfIw{max-width:260px;padding-bottom:30px}`, "",{"version":3,"sources":["webpack://./src/components/sections/points-dollars-entry/points-input.module.scss"],"names":[],"mappings":"AACA,sBACE,cAAA,CACA,kBAAA,CACA,qBAAA,CACA,yBAAA,CACA,yBAAA,CAEF,sBACE,eAAA,CAEF,sBACE,eAAA,CAEF,sBACE,eAAA,CACA,mBAAA","sourcesContent":["@import \"main.scss\";\n.equalsign {\n  font-size: 30px;\n  font-weight: bolder;\n  width: 50px !important;\n  padding: 0 11px !important;\n  margin: 6px 0px !important;\n}\n.dollarsToRedeem {\n  max-width: 175px;\n}\n.pointsToRedeem {\n  max-width: 250px;\n}\n.error {\n  max-width: 260px;\n  padding-bottom: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"equalsign": `hbmxtF1ffM6YeipegtJK`,
	"dollarsToRedeem": `MPfdHuVNlAA7mGoKgbg8`,
	"pointsToRedeem": `oViMHAOswNMTQY9GPO4K`,
	"error": `quA1DwyAigqGBRMWOfIw`
};
export default ___CSS_LOADER_EXPORT___;
