import amplitude from 'amplitude-js';
import React, { useContext } from 'react';
import { ActivityIndicator } from '../../components/common/activity-indicator/activity-indicator';
import { AuthContext } from '../../context/auth-context';

export const Logout = props => {
  const { authService } = useContext(AuthContext);
  amplitude.getInstance().logEvent('pfs logout', { props });
  authService.logout();
  return <ActivityIndicator />;
};
