import React from 'react';
import { Link } from 'react-router-dom';
import { CtaProps } from '../../../models/cta-props';
import cx from '../../../utils/classnames';
import utils from '../../../utils/utils.module.scss';
import { ActivityIndicatorSmall } from '../activity-indicator/activity-indicator';

export const CtaLink = (propsCta: CtaProps) => {
  return <Cta {...propsCta} className={cx(utils.link, propsCta.className)} />;
};

export const CtaButton = (propsCta: CtaProps) => {
  return <Cta {...propsCta} className={cx(utils.buttonPrimary, propsCta.className)} />;
};

export const CtaSecondaryButton = (propsCta: CtaProps) => {
  return <Cta {...propsCta} className={cx(utils.buttonOutlinePrimary, propsCta.className)} />;
};

export const Cta = (propsCta: CtaProps) => {
  return (
    <>
      {!propsCta.href ? (
        <Link
          onClick={propsCta.onClick}
          to={{
            pathname: propsCta.link,
            search: window.location.search,
          }}
          className={cx(propsCta.className, { [utils.buttonDisabled]: propsCta.disabled })}
          aria-disabled={propsCta.disabled}
          aria-label={propsCta.ariaLabel || propsCta.label}
        >
          {propsCta.loading ? (
            <ActivityIndicatorSmall className={utils.buttonLoading} />
          ) : propsCta.children ? (
            propsCta.children
          ) : (
            <span>{propsCta.label}</span>
          )}
        </Link>
      ) : (
        <a
          href={propsCta.href}
          target={propsCta.target}
          className={propsCta.className}
          aria-label={propsCta.ariaLabel || propsCta.label}
          aria-disabled={propsCta.disabled}
        >
          {propsCta.loading ? <ActivityIndicatorSmall /> : propsCta.children ? propsCta.children : <span>{propsCta.label}</span>}
        </a>
      )}
    </>
  );
};
