// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.F4HWlhwwel7cCe4KOW8n{font-size:30px}.acb3kReeap_X_ktyZ4zH{border-right:solid 2px #eee;font-size:16px;padding-right:40px !important;margin-right:40px !important;min-width:200px;white-space:nowrap;height:10%}.SviaBh8AJt2UrxghLqzP{max-width:260px}`, "",{"version":3,"sources":["webpack://./src/components/sections/points-section/points-section.module.scss"],"names":[],"mappings":"AACA,sBACE,cAAA,CAEF,sBACE,2BAAA,CACA,cAAA,CACA,6BAAA,CACA,4BAAA,CACA,eAAA,CACA,kBAAA,CACA,UAAA,CAEF,sBACE,eAAA","sourcesContent":["@import \"main.scss\";\n.points {\n  font-size: 30px;\n}\n.rightBorder {\n  border-right: solid 2px #eee;\n  font-size: 16px;\n  padding-right: 40px !important;\n  margin-right: 40px !important;\n  min-width: 200px;\n  white-space: nowrap;\n  height: 10%;\n}\n.inputs {\n  max-width: 260px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"points": `F4HWlhwwel7cCe4KOW8n`,
	"rightBorder": `acb3kReeap_X_ktyZ4zH`,
	"inputs": `SviaBh8AJt2UrxghLqzP`
};
export default ___CSS_LOADER_EXPORT___;
