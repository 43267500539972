// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.atPTGiS0uk3jsg5PkriD{opacity:.3;pointer-events:none}.iR8IxZB1kwu41jvTnWTQ{display:inline-block;position:absolute;width:70px;transform:translate(-36px, -7px)}`, "",{"version":3,"sources":["webpack://./src/components/tabs/tab-sales-view/tab-sales-view.module.scss"],"names":[],"mappings":"AACA,sBACE,UAAA,CACA,mBAAA,CAEF,sBACE,oBAAA,CACA,iBAAA,CACA,UAAA,CACA,gCAAA","sourcesContent":["@import \"main.scss\";\n.hideRewardChoice {\n  opacity: 0.3;\n  pointer-events: none;\n}\n.vinActivityIndicator {\n  display: inline-block;\n  position: absolute;\n  width: 70px;\n  transform: translate(-36px, -7px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hideRewardChoice": `atPTGiS0uk3jsg5PkriD`,
	"vinActivityIndicator": `iR8IxZB1kwu41jvTnWTQ`
};
export default ___CSS_LOADER_EXPORT___;
