import React from 'react';
// { useState, useRef } 
import styles from './financing-form-view.module.scss';
// import ReactDatePicker from 'react-datepicker';
// import './date-picker.scss';
// import { Chevron } from '../../components/common/chevron/chevron';



interface Props {
  label: string;
  data?: string;
  width?: number;
}

// export interface SubmitParams {
//   type?: string;
//   navigateTo: any;
//   chosenDealer?: any;
//   vin?: string;
//   invoiceNumber?: string;
//   purchaseDate?: Date;
//   pending: boolean;
//   lessThan30?: boolean;
//   retry?: boolean;
//   setDisabled: (value: boolean) => void;
// }

// export const Field = ({ label, data, width = 230 }: Props) => {
//   return (
//     <span className={styles.field} style={{ width: `${width}px` }}>
//       <div className={styles.fieldData}>{data}</div>
//       <div className={styles.fieldLabel}>{label}</div>
//     </span>
//   );
// };

// export const SelectDealership = ({ setChosenDealer, chosenDealer, validate }: any) => {
//   const [{ dealers }] = useContext(PointsAssistContext);
//   const handleClick = (item: any) => {
//     setChosenDealer(item.value);
//   };

//   const OTHER = {
//     address: { address1: '', city: '', state: '', postalCode: '' },
//     countryCode: 'USA',
//     name: 'Other',
//     paCode: '0',
//     partnerCategory: 'D',
//     search: ' OTHER',
//   };

//   const newDealers = dealers?.map((dealer: any) => {
//     return {
//       value: dealer,
//       label: `${dealer?.name} - ${dealer?.address?.address1}, ${dealer?.address?.city}, ${dealer?.address?.state}, ${dealer?.address?.postalCode}`,
//     };
//   });
//   newDealers.push({ value: OTHER, label: 'Other' });

//   return (
//     <Select
//       placeholder='Select Dealership'
//       onChange={e => handleClick(e)}
//       styles={{
//         ...SelectStyles,
//         control: (baseStyles) => ({
//           ...baseStyles,
//           border: (validate && !chosenDealer) || chosenDealer?.label === 'Other'
//             ? '1px solid #d62d0a' 
//             : '1px solid #4d4d4d' 
//         }),
//       }}
//       options={newDealers}
//     />
//   );
// };


export const Field = ({ label, data, width = 230 }: Props) => {
  return (
    <span className={styles.field} style={{ width: `${width}px` }}>
      <input
        maxLength={6}
        className={styles?.fieldData}
        type='text'
        placeholder={label}
        // onFocus={() => setShowInvoiceLabel(true)}
        // onBlur={() => setShowInvoiceLabel(false)}
        // onChange={(evnt: any) => setInvoiceNumber(evnt.target.value?.toUpperCase().replace(/[^A-Z0-9]/g, ''))}
        // value={invoiceNumber}
     / >{data}
     <br/>
    </span>
  );
};

// export const SelectVehicle: any = ({
//   setOptionSelectedService,
//   setOptionSelectedPurchase,
//   optionSelectedService,
//   optionSelectedPurchase,
//   setShowServiceVIN,
//   setShowPurchaseVIN,
//   setVin,
//   type,
//   validateService,
//   setValidateService,
//   validatePurchase,
//   setValidatePurchase,
//   vin,
// }: any) => {
//   const [{ vehicles }] = useContext(PointsAssistContext);
//   const [error, setError] = useState(false);
//   const [{ getValueByTitle }] = useContent('pointsassist/points-assist');

//   const handleChange = (vin: any, value: string) => {
//     if (type === 'service') {
//       setOptionSelectedService(true);
//       setValidateService(true);
//     } else if (type === 'purchase') {
//       setOptionSelectedPurchase(true);
//       setValidatePurchase(true);
//     }

//     if (value === '' && (validateService || validatePurchase)) {
//       setError(true);
//     } else if (value !== '' && (validateService || validatePurchase)) {
//       setError(false);
//     }

//     if (vin === 'other') {
//       setVin('');

//       if (type === 'service') {
//         setShowServiceVIN(true);
//       }
//       if (type === 'purchase') {
//         setShowPurchaseVIN(true);
//       }
//     } else {
//       if (type === 'service') {
//         setShowServiceVIN(false);
//       }
//       if (type === 'purchase') {
//         setShowPurchaseVIN(false);
//       }

//       setVin(vin);
//     }
//   };

//   return (
//     <div>
//       <div className={styles.vehicleSelectContainer}>
//         <select
//           id='vehicleSelect'
//           className={`${styles.selectVehicle} ${(optionSelectedService || optionSelectedPurchase) && styles.selectVehicleSelected} ${
//             (!optionSelectedService || !optionSelectedPurchase) && validateService && validatePurchase && vin?.length < 8 && styles.fprInputError
//           }`}
//           onChange={e => handleChange(e.target.value, e.target.value)}
//         >
//           <option value=''>{getValueByTitle('which-vehicle-serviced')}</option>
//           {vehicles?.map((item: any, index: any) => (
//             <option value={item?.vin} key={index}>{`${item?.modelName} ${item?.modelYear}`}</option>
//           ))}
//           <option value='other'>Other</option>
//         </select>
//         <div className={styles.chevron}>
//           <FdsChevron direction='down' />
//         </div>
//       </div>
//       {error && <div className='fpr-inputError'>{getValueByTitle('required')}</div>}
//     </div>
//   );
// };

// export const InputVIN = ({ setShowVINLabel, setVin, vin, validate }: any) => {
//   return (
//     <input
//       onChange={(evnt: any) => setVin(evnt.target.value?.toUpperCase().replace(/[^A-Z0-9]/g, ''))}
//       onFocus={() => setShowVINLabel(true)}
//       onBlur={() => setShowVINLabel(false)}
//       className={`${styles.vinInput} ${validate && vin?.length < 8 && styles.inputError}`}
//       type='text'
//       maxLength={8}
//       placeholder='Last 8 Digits of VIN'
//       value={vin}
//     />
//   );
// };

// export const DatePicker = ({ setShowDateLabel, setPurchaseDate, validate, purchaseDate }: any) => {
//   const oneYearInPast = new Date();
//   oneYearInPast.setDate(oneYearInPast.getDate() - 364);
//   const [monthChanged, setMonthChanged] = useState(false);
//   const ref = useRef<ReactDatePicker>(null);

//   const changeMonth = (callChangeMonth: any) => {
//     setMonthChanged(true);
//     callChangeMonth();
//   };

//   const onFocus = () => {
//     setMonthChanged(true);
//     setShowDateLabel(true);
//   };

//   const onBlur = () => {
//     setShowDateLabel(false);
//     setMonthChanged(false);
//   };

//   const deskTopHeader = ({ date, increaseMonth, decreaseMonth, nextMonthButtonDisabled, prevMonthButtonDisabled }: any) => (
//     <div className='react-datepicker__current-month'>
//       {date.toLocaleString('default', { month: 'long' })}
//       <span> {date.getFullYear()}</span>
//       <button
//         type='button'
//         className='calenderChevronLeft'
//         aria-label='Previous Month'
//         onClick={() => changeMonth(decreaseMonth)}
//         disabled={prevMonthButtonDisabled}
//       >
//         <Chevron className='chevronSize' direction='left' />
//       </button>
//       <button
//         type='button'
//         className='calenderChevronRight'
//         aria-label='Next Month'
//         onClick={() => changeMonth(increaseMonth)}
//         disabled={nextMonthButtonDisabled}
//       >
//         <Chevron className='chevronSize' direction='right' />
//       </button>
//     </div>
//   );

//   return (
//     <ReactDatePicker
//       ref={ref}
//       placeholderText={purchaseDate ? purchaseDate : 'Purchase Date (mm/dd/yyyy)'}
//       className={`${monthChanged && 'transparent'} fpr-datePicker ${purchaseDate && 'datePickerLabel'} ${
//         validate && !purchaseDate && 'fpr-datePickerError'
//       }`}
//       onChange={(date: any) => {
//         setPurchaseDate(date);
//         setMonthChanged(false);
//       }}
//       onFocus={() => onFocus()}
//       onBlur={() => onBlur()}
//       dateFormat='MM/dd/yyyy'
//       locale='en-US'
//       value={purchaseDate}
//       formatWeekDay={(nameOfDay: any) => nameOfDay.substring(0, 1)}
//       selected={purchaseDate}
//       minDate={oneYearInPast}
//       maxDate={new Date()}
//       renderCustomHeader={({ date, increaseMonth, decreaseMonth, nextMonthButtonDisabled, prevMonthButtonDisabled }: any) =>
//         deskTopHeader({ date, increaseMonth, decreaseMonth, nextMonthButtonDisabled, prevMonthButtonDisabled })
//       }
//     />
//   );
// };
