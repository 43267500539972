import React from 'react';
import { AuthContextProvider } from './auth-context';
import { CustomerContextProvider } from './customer-context';
import { APIHelperContextProvider } from './api-helper-context';
import { InputsContextProvider } from './inputs-context';
import { ShoppingCartContextProvider } from './shopping-cart-context';
import { AlertDialogContextProvider } from './alert-dialog-context';
import { DealerContextProvider } from './dealer-context';
import { TranslationContextProvider } from './translations-context';
import { SiteContextProvider } from './site-context';
import { SubmitInvoiceContextProvider } from './submit-invoice-context';
import { ConnectedServicesContextProvider } from './connected-services-context';

interface Props {
  children: React.ReactNode;
}

export const ContextProviders = ({ children }: Props) => {
  return (
    <AuthContextProvider>
      <SiteContextProvider>
        <TranslationContextProvider>
          <AlertDialogContextProvider>
            <APIHelperContextProvider>
              <DealerContextProvider>
                <InputsContextProvider>
                  <CustomerContextProvider>
                    <ShoppingCartContextProvider>
                      <ConnectedServicesContextProvider>
                        <SubmitInvoiceContextProvider>{children}</SubmitInvoiceContextProvider>
                      </ConnectedServicesContextProvider>
                    </ShoppingCartContextProvider>
                  </CustomerContextProvider>
                </InputsContextProvider>
              </DealerContextProvider>
            </APIHelperContextProvider>
          </AlertDialogContextProvider>
        </TranslationContextProvider>
      </SiteContextProvider>
    </AuthContextProvider>
  );
};
