import React, { useContext, useState } from 'react';
import { currencyToFloat } from '../currency/currency';
import { ActivityIndicator } from '../activity-indicator/activity-indicator';

import cx from '../../../utils/classnames';
import styles from './data-grid.module.scss';
import { FontAwesome } from '../font-awesome/font-awesome';
import { TranslationContext } from '../../../context/translations-context';

interface Props {
  data: any;
  columns: any;
  filter?(data: any): void;
  className?: string;
  onSelect?(obj: any): void;
  onTwist?(obj: any, all?: number): void;
  twistState?(obj: any): number;
  renderTwist?(obj: any, func: any, className: string): any;
  sortable?: boolean;
  initialSort?: string;
  setFilteredPageSize?(size: number): void;
}

export const getDataGridValue = (base: any, ids: string) => {
  const _get = (base: any, ids: string) => {
    const splits = ids.split('.');
    let val = base[splits[0]];
    for (let i = 1; i <= splits.length - 1; ++i) {
      val = splits[i] ? val?.[splits[i]] : 0;
    }
    return val ? val : '';
  };

  if (ids.includes('|')) {
    return ids
      .split('|')
      .map(e => _get(base, e))
      .find(e => e);
  }

  if (ids.includes(' ')) {
    return ids
      .split(' ')
      .map(e => _get(base, e))
      .join(' ');
  }

  if (ids.includes(':')) {
    return ids
      .split(' ')
      .map(e => _get(base, e))
      .join(' ');
  }

  return _get(base, ids);
};

export const DataGrid = ({
  data,
  columns,
  filter,
  className,
  sortable = true,
  onSelect,
  onTwist,
  twistState,
  renderTwist,
  initialSort = 'createdDate',
  setFilteredPageSize,
}: Props) => {
  const [sortby, setSortBy] = useState(initialSort);
  const [reverseSort, setReverseSort] = useState(true);
  const [allTwist, setAllTwist] = useState(true);
  const [{ t }] = useContext(TranslationContext);

  const setSort = (id: string) => {
    if (sortby == id) setReverseSort(!reverseSort);
    else {
      setReverseSort(false);
      setSortBy(id);
    }
  };

  const sort = (data: any) => {
    return data.sort((a: string, b: string) => {
      if (!isNaN(+getDataGridValue(a, sortby))) {
        if (getDataGridValue(a, sortby) == getDataGridValue(b, sortby)) return 0;
        return currencyToFloat(getDataGridValue(a, sortby)) > currencyToFloat(getDataGridValue(b, sortby)) ? -1 : 1;
      }
      return getDataGridValue(a, sortby)?.localeCompare(getDataGridValue(b, sortby) || '');
    });
  };

  const onAllTwist = () => {
    const _allTwist = !allTwist;
    setAllTwist(_allTwist);
    onTwist(null, _allTwist ? 1 : -1);
  };

  const sortedData = filter && data ? filter(data) : data;
  if (setFilteredPageSize) setFilteredPageSize(sortedData?.length);

  return (
    <table className={cx(styles.fixed_header, className)}>
      <thead>
        <tr aria-hidden='true'>
          {(onSelect || onTwist) && <th>{onTwist && <FontAwesome icon={allTwist ? 'plus' : 'minus'} onClick={() => onAllTwist()} />}</th>}
          {columns.map((col: any, i) => (
            <th
              key={col.id + i}
              onClick={
                sortable
                  ? () => {
                      setSort(col.id);
                      data = sort(data);
                      if (reverseSort) data.reverse();
                    }
                  : undefined
              }
            >
              {sortable && <FontAwesome icon={`sort${sortby == col.id ? (reverseSort ? '-down' : '-up') : ''}`} />}
              {col.title}
            </th>
          ))}
        </tr>
      </thead>
      {sortedData?.length ? (
        <tbody>
          {sortedData.map((dataItem: any, i: number) => (
            <React.Fragment key={`${dataItem.invoiceNumber}-${i}`}>
              <tr
                onClick={(e: any) => {
                  const o = e.target.parentElement.firstChild.firstChild;
                  if (o) o.checked = true;
                  if (onSelect) onSelect(dataItem);
                  if (onTwist) onTwist(dataItem);
                }}
                className={cx({ [styles.twistOpen]: twistState && twistState(dataItem) < 0 })}
              >
                {onSelect && (
                  <td>
                    <input type='radio' id={`row${i}`} name='datagrid' onChange={() => onSelect(dataItem)} />
                  </td>
                )}
                {onTwist && (
                  <td>
                    {!!twistState(dataItem) && <FontAwesome icon={twistState(dataItem) > 0 ? 'plus' : 'minus'} onClick={() => onTwist(dataItem)} />}
                  </td>
                )}
                {columns.map((column: any, j: number) =>
                  column.color ? (
                    <td
                      key={`td-${i}-${j}`}
                      className={cx(
                        (column.render(getDataGridValue(dataItem, column.id), dataItem) || '').startsWith('(') ? styles.colorNegatives : ''
                      )}
                    >
                      {column.render ? column.render(getDataGridValue(dataItem, column.id), dataItem) : getDataGridValue(dataItem, column.id)}
                    </td>
                  ) : (
                    <td key={`td-${i}-${j}`}>
                      {column.render ? column.render(getDataGridValue(dataItem, column.id), dataItem) : getDataGridValue(dataItem, column.id)}
                    </td>
                  )
                )}
              </tr>
              {onTwist && twistState(dataItem) < 0 && renderTwist(dataItem, getDataGridValue, styles.subitem)}
            </React.Fragment>
          ))}
        </tbody>
      ) : (
        <>
          {sortedData && (
            <tbody>
              <tr className={styles.ActivityIndicator}>
                <td colSpan={100}>{t('NO_RECORDS_FOUND')}</td>
              </tr>
            </tbody>
          )}
        </>
      )}
      {!data && (
        <tbody>
          <tr className={styles.ActivityIndicator}>
            <td colSpan={100}>
              <ActivityIndicator />
            </td>
          </tr>
        </tbody>
      )}
    </table>
  );
};
