import React, { useContext } from 'react';
import styles from './transactions-view.module.scss';
import { CtaButton } from '../../components/common/cta/cta';
import { FontAwesome } from '../../components/common/font-awesome/font-awesome';
import { DatePickerWithLabel } from '../../components/common/datepicker-with-label/datepicker-with-label';
import { TranslationContext } from '../../context/translations-context';
import cx from '../../utils/classnames';
import { getDataGridValue } from '../../components/common/data-grid/data-grid';

export const TransactionViewFooter = ({ transactions, columns, subColumns, startDate, endDate, setStartDate, setEndDate, setEditingCalendar }) => {
  const [{ t }] = useContext(TranslationContext);

  function monthDiff(dateFrom, dateTo) {
    return dateTo.getMonth() - dateFrom.getMonth() + 12 * (dateTo.getFullYear() - dateFrom.getFullYear());
  }

  const downloadCsv = () => {
    function arrayToCSV(array) {
      const str = `${columns.map(o => `"${o.title}"`).join(',')}` + '\r\n';

      return array.reduce((str, next) => {
        str +=
          `${columns
            .map(column => `"${column.render ? column.render(getDataGridValue(next, column.id)) : getDataGridValue(next, column.id)}"`)
            .join(',')}` + '\r\n';

        next.transactionItems.forEach(t => (str += subColumns.map(sub => `"${sub?.render(getDataGridValue(t, sub.id)) || ''}"`).join(',') + '\r\n'));

        return str;
      }, str);
    }

    const CSV = arrayToCSV(transactions);

    const blob = new Blob([CSV], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'transactions_export.csv');
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  };

  return (
    <footer className={styles.pageFooter}>
      <div className={cx(styles.print, styles.excludeFromPrint)}>
        <CtaButton onClick={downloadCsv}>
          <div>
            {t('Export CSV (Excel)')} <FontAwesome icon='file-csv' />
          </div>
        </CtaButton>
      </div>
      <div className={cx(styles.print, styles.excludeFromPrint)}>
        <CtaButton onClick={print}>
          <div>
            {t('PRINT')} <FontAwesome icon='print' />
          </div>
        </CtaButton>
      </div>
      <div className={cx(styles.print, styles.excludeFromPrint)}>
        <DatePickerWithLabel
          label={t('End date')}
          setEditingCalendar={setEditingCalendar}
          onChange={date => {
            setEndDate(date);
            if (date.getTime() <= startDate.getTime() || monthDiff(startDate, date) > 3) {
              startDate = new Date(date);
              startDate.setMonth(startDate.getMonth() - 1);
              setStartDate(startDate);
            }
            setEndDate(date);
          }}
          id='toDate'
          selectedDate={endDate}
          maxDate={new Date()}
        />
      </div>
      <div className={cx(styles.print, styles.excludeFromPrint)}>
        <DatePickerWithLabel
          label={t('Start date')}
          setEditingCalendar={setEditingCalendar}
          onChange={date => {
            setStartDate(date);
            if (date.getTime() >= endDate.getTime() || monthDiff(date, endDate) > 3) {
              endDate = new Date(date);
              endDate.setMonth(endDate.getMonth() + 1);
              if (endDate > new Date()) endDate = new Date();
              setEndDate(endDate);
            }
          }}
          id='fromDate'
          selectedDate={startDate}
          maxDate={new Date()}
        />
      </div>
    </footer>
  );
};
