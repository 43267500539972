export function buildQueryParams(params: any) {
  const qs: string[] = [];
  for (const key in params) {
    if (Object.prototype.hasOwnProperty.call(params, key)) {
      qs.push(`${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`);
    }
  }
  return `?${qs.join('&')}`;
}

export function usePascalCase(item: any) {
  const Capitalize = (str: string) => {
    const words = str.split(' ');
    return words
      .map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      })
      .join(' ');
  };
  return Capitalize(item)
}

export function encryptFormData(formdata: FormData) {
  return null;
  const fd = {};
  formdata.forEach((value, key) => (fd[key] = value));
  return btoa(btoa(JSON.stringify(fd)));
}
