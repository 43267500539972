import React, { useContext } from 'react';
import utils from '../../../utils/utils.module.scss';

import { CtaButton } from '../../common/cta/cta';
import { TranslationContext } from '../../../context/translations-context';
import ReportDownloadService from '../../../services/report-download-service/report-download-service';
import { APIHelperContext } from '../../../context/api-helper-context';
import { SiteContext } from '../../../context/site-context';

export const TabReportsInactiveMember = () => {
  const [{ t }] = useContext(TranslationContext);
  const [apiHelper] = useContext(APIHelperContext);
  const [{ siteCode }] = useContext(SiteContext);

  const download = () => {
    const reportDownloadService = new ReportDownloadService();
    const filepath = `reports/InactiveMembers/InactiveMemberReport_${siteCode}_en.xlsx`;

    reportDownloadService.downloadReport(filepath, apiHelper, t('REPORT_NOT_FOUND_FOR_YEAR'));
  };

  return (
    <div>
      <CtaButton label={t('Download Report')} className={utils.max300} onClick={download} />
    </div>
  );
};
