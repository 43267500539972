import React, { useContext } from 'react';
import utils from '../../../utils/utils.module.scss';
import { Header } from '../../common/header/header';
import cx from '../../../utils/classnames';

import { InputsContext } from '../../../context/inputs-context';
import { TranslationContext } from '../../../context/translations-context';
import { SiteContext } from '../../../context/site-context';
import { CustomerContext } from '../../../context/customer-context';

export const SaleTypeChoice = () => {
  const [{ t }] = useContext(TranslationContext);
  const [{ saleType, nonFordVin }, { setSaleType }] = useContext(InputsContext);
  const [{ isCanada }] = useContext(SiteContext);
  const [{ rewardProgram }] = useContext(CustomerContext);

  return (
    <Header title={t('Sale Type')} className={utils.section}>
      <div className={utils.radioItem}>
        <input
          type='radio'
          id='VehicleNew'
          name={'SaleTypeChoice'}
          value='VehicleNew'
          onChange={() => setSaleType('VehicleNew')}
          checked={saleType == 'VehicleNew'}
          disabled={nonFordVin}
        />
        <label className={cx({ [utils.disabledText]: nonFordVin })} htmlFor='VehicleNew'>
          {t('New')}
        </label>
      </div>
      {!isCanada() && (
        <div className={utils.radioItem}>
          <input
            type='radio'
            id='VehicleCPO'
            name={'SaleTypeChoice'}
            value='VehicleCPO'
            onChange={() => setSaleType('VehicleCPO')}
            checked={saleType == 'VehicleCPO'}
            disabled={nonFordVin}
          />
          <label className={cx({ [utils.disabledText]: nonFordVin })} htmlFor='VehicleCPO'>
            {rewardProgram == 'F' ? t('Ford Blue Advantage - Gold') : t('Lincoln CPO')}
          </label>
        </div>
      )}
      {rewardProgram == 'F' && (
        <div className={utils.radioItem}>
          <input
            type='radio'
            id='VehicleCPOLite'
            name={'SaleTypeChoice'}
            value='VehicleCPOLite'
            onChange={() => setSaleType('VehicleCPOLite')}
            checked={saleType == 'VehicleCPOLite'}
          />
          <label htmlFor='VehicleCPOLite'>{isCanada() ? t('Certified Pre-Owned') : t('Ford Blue Advantage - Blue')}</label>
        </div>
      )}
    </Header>
  );
};
