import React, { useContext } from 'react';
import { TranslationContext } from '../../../context/translations-context';
import { CtaButton, CtaSecondaryButton } from '../../common/cta/cta';
import { Body, Buttons, ModalDialog } from '../../common/modal-dialog/modal-dialog';
import { ShoppingCartSummaryContent } from '../shopping-cart-summary-dialog/shopping-cart-summary-content';

import styles from './success-dialog.module.scss';
import { ShoppingCartContext } from '../../../context/shopping-cart-context';

export const SuccessDialog = ({ doClose }) => {
  const [{ t, formatDate }] = useContext(TranslationContext);
  const [{ salesTab }] = useContext(ShoppingCartContext);

  const doPrint = () => {
    const printContents = document.getElementById('successInvoice').innerHTML;
    const originalContents = document.body.innerHTML;
    document.head.innerHTML += `<style>@media print {
      #successOverlay {
        display: none;
      }
    }</style>`;

    document.body.innerHTML = printContents;

    window.print();

    document.body.innerHTML = originalContents;
  };

  return (
    <>
      <ModalDialog title={t('SUCCESS')}>
        <Body>{t('SUCCESSPRINT')}</Body>
        <Buttons>
          <CtaSecondaryButton label={t('PRINT')} onClick={doPrint} />
          <CtaButton label={t('Close')} onClick={doClose} />
        </Buttons>
      </ModalDialog>
      <div id='successInvoice' className={styles.successInvoice}>
        <div style={{ margin: '5vw' }}>
          <div style={{ fontSize: '2.1vw', marginBottom: '10px', textAlign: 'center' }}>
            <b>{salesTab ? 'Sale' : 'Service'} Transaction Summary</b> ({formatDate(new Date())})
          </div>
          <hr style={{ marginBottom: '80px' }} />
          <div style={{ fontSize: '1.2vw' }}>
            <ShoppingCartSummaryContent />
          </div>
        </div>
        <div
          id='successOverlay'
          style={{ position: 'fixed', left: '0', top: '0', width: '100vw', height: '100vh', background: '#aaa', zIndex: '999' }}
        ></div>
      </div>
    </>
  );
};
