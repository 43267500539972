import React, { useContext, useState } from 'react';
import utils from '../../../utils/utils.module.scss';
import { Header } from '../../common/header/header';

import { InputsContext, REWARD_CHOICE_DEFAULTED } from '../../../context/inputs-context';
import { TranslationContext } from '../../../context/translations-context';
import cx from '../../../utils/classnames';
import { SwitchToPmpDialog } from '../../dialogs/switch-to-pmp-dialog/switch-to-pmp-dialog';
import { CtaLink } from '../../common/cta/cta';
import { PmpContractsDialog } from '../../dialogs/pmp-contracts/pmp-contracts';

export enum REWARD_CHOICE {
  PMP = 'pmp',
  POINTS = 'points',
}

export const RewardPurchaseChoice = () => {
  const [{ t }] = useContext(TranslationContext);
  const [{ rewardChoice, rewardChoiceDefault }, { setRewardChoice, setRewardChoiceDefault }] = useContext(InputsContext);
  const [showSwitchToPmp, setShowSwitchToPmp] = useState(false);
  const [showContracts, setShowContracts] = useState(false);

  return (
    <Header title={t('Reward Choice')} className={utils.section}>
      <div className={utils.radioItem}>
        <input
          type='radio'
          id='points'
          name={'RewardPurchaseChoice'}
          value={REWARD_CHOICE.POINTS}
          onChange={() => {
            if (rewardChoiceDefault === REWARD_CHOICE_DEFAULTED.SWITCH_PMP) {
              setRewardChoiceDefault(REWARD_CHOICE_DEFAULTED.DEFAULTPOINTS);
            }
            setRewardChoice(REWARD_CHOICE.POINTS);
          }}
          checked={rewardChoice == REWARD_CHOICE.POINTS}
        />
        <label htmlFor='points'>{t('SALE_AWARD')}</label>
      </div>
      <div className={utils.radioItem}>
        <input
          type='radio'
          id='pmp'
          name={'RewardPurchaseChoice'}
          value={REWARD_CHOICE.PMP}
          onChange={() =>
            rewardChoiceDefault == REWARD_CHOICE_DEFAULTED.DEFAULTPOINTS ? setShowSwitchToPmp(true) : setRewardChoice(REWARD_CHOICE.PMP)
          }
          checked={rewardChoice == REWARD_CHOICE.PMP}
        />
        <label htmlFor='pmp'>
          {t('Discounted Maintenance Plan')} (<CtaLink label={t('Eligible Plans')} onClick={() => setShowContracts(true)} />)
        </label>
      </div>
      <div></div>

      <SwitchToPmpDialog showModal={showSwitchToPmp} close={() => setShowSwitchToPmp(false)} />

      {showContracts && <PmpContractsDialog onClose={() => setShowContracts(false)} />}
    </Header>
  );
};
