import { APIHelper } from '../../models/api-helper';
import { post } from '../generic-service';

export default class RewardsHistoryService {
  public async request(data: any, apiHelper: APIHelper) {
    return await post(
      'REWARDS_HISTORY',
      null,
      { action: 'history' },
      {
        ...data,
        types: 'points',
        tempTransactions: true,
      },
      null,
      apiHelper
    );
  }
}
