import { APIHelper } from '../../models/api-helper';
import { post } from '../generic-service';
import ServiceHandler from '../service-handler';

export default class GarageLookupService {
  public async request(guid: string, apiHelper: APIHelper) {
    const data = {
      userGuid: guid,
      language: apiHelper.languageCountry(),
      partnerCountry: apiHelper.countryCode,
      siteCode: apiHelper.siteCode,
    };
    return await post('GARAGE_LOOKUP', null, null, data, null, apiHelper, ServiceHandler.ConfigService.DSEC_BASE_URL);
  }
}
