import React, { useState, useContext, useEffect } from 'react';
import { HeaderView, HEADER_TAB_STATES } from '../../components/sections/header-bar/header-bar';
import { Header } from '../../components/common/header/header';
import TabBar from '../../components/common/tabbar/tabbar';
import Tab from '../../components/common/tabbar/tab';

import styles from './reports-view.module.scss';
import { TranslationContext } from '../../context/translations-context';
import { SiteContext } from '../../context/site-context';
import { TabReportsReimbursement } from '../../components/reports/reimbursement/reimbursement';
import { TabReportsLiability } from '../../components/reports/liability/liability';
import { TabReportsGoodwill } from '../../components/reports/goodwill/goodwill';
import { TabReportsVehiclePurchase } from '../../components/reports/vehicle-purchase/vehicle-purchase';
import { TabReportsInactiveMember } from '../../components/reports/inactive-members/inactive-members';
import { TabReportsDealerCompensation } from '../../components/reports/dealer-compensation/dealer-compensation';
import { DealerContext } from '../../context/dealer-context';

enum TAB_STATES {
  TAB_REIMBURSEMENT = 'Rewards Monthly Reimbursement',
  TAB_LIABILITY = 'Monthly Dealer Liability',
  TAB_GOODWILL = 'Goodwill',
  TAB_VEHICLE = 'Vehicle',
  TAB_INACTIVE_MEMBERS = 'Inactive Members',
  TAB_DEALER_COMPENSATION = 'Connected Services',
}

export const ReportsView = () => {
  const [{ t }] = useContext(TranslationContext);
  const [{ isCanada }] = useContext(SiteContext);
  const [activeTabId, setActiveTabId] = useState(TAB_STATES.TAB_REIMBURSEMENT as string);
  const [{ dealerDetails, isConnectedServiceDealer }] = useContext(DealerContext);

  const tabContents = {};
  tabContents[TAB_STATES.TAB_REIMBURSEMENT] = TabReportsReimbursement;
  tabContents[TAB_STATES.TAB_LIABILITY] = TabReportsLiability;
  tabContents[TAB_STATES.TAB_GOODWILL] = TabReportsGoodwill;
  tabContents[TAB_STATES.TAB_VEHICLE] = TabReportsVehiclePurchase;
  tabContents[TAB_STATES.TAB_INACTIVE_MEMBERS] = TabReportsInactiveMember;
  tabContents[TAB_STATES.TAB_DEALER_COMPENSATION] = TabReportsDealerCompensation;

  const TabContent = tabContents[activeTabId];

  return (
    <>
      <HeaderView tabID={HEADER_TAB_STATES.TAB_REPORTS} />
      <Header title={t('REPORTS')} className={styles.contentWrapper}>
        <TabBar
          setTab={(id: string) => {
            setActiveTabId(id);
          }}
          active={activeTabId}
        >
          <Tab id={TAB_STATES.TAB_REIMBURSEMENT}>{t(TAB_STATES.TAB_REIMBURSEMENT)}</Tab>
          {isCanada() && <Tab id={TAB_STATES.TAB_LIABILITY}>{t(TAB_STATES.TAB_LIABILITY)}</Tab>}
          <Tab id={TAB_STATES.TAB_GOODWILL}>{t(TAB_STATES.TAB_GOODWILL)}</Tab>
          <Tab id={TAB_STATES.TAB_VEHICLE}>{t(TAB_STATES.TAB_VEHICLE)}</Tab>
          {!isCanada() && <Tab id={TAB_STATES.TAB_INACTIVE_MEMBERS}>{t(TAB_STATES.TAB_INACTIVE_MEMBERS)}</Tab>}
          {(isConnectedServiceDealer || ['DE', 'FL', 'NC', 'MD'].includes(dealerDetails?.dealer.address.state)) && (
            <Tab id={TAB_STATES.TAB_DEALER_COMPENSATION}>{t(TAB_STATES.TAB_DEALER_COMPENSATION)}</Tab>
          )}
        </TabBar>
        <div className={styles.tabContent}>
          <TabContent />
        </div>
      </Header>
    </>
  );
};
