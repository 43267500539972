/* eslint-disable no-console */
import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import ServiceHandler from './services/service-handler';
import { ContextProviders } from './context/context-providers';

import amplitude from 'amplitude-js';
import AppRoutes from './routes';

global['pfs_app_version'] = '%PFS_VERSION%';

const App = () => {
  const cfg = ServiceHandler.ConfigService;
  const auth = ServiceHandler.AuthenticationService;

  useEffect(() => {
    if (auth.getIsAuthenticated()) {
      amplitude.getInstance().setUserId(auth.getSiteCode());
    }
  }, [auth.getIsAuthenticated()]);

  return (
    <Router basename={cfg.APP_BASEPATH}>
      <main style={{ minHeight: '100vh' }}>
        <ContextProviders>
          <AppRoutes />
        </ContextProviders>
      </main>
    </Router>
  );
};

export default App;
