import React, { useContext } from 'react';
import { TranslationContext } from '../../../context/translations-context';
import styles from './contact-us.module.scss';

import { ModalDialog, Body } from '../../common/modal-dialog/modal-dialog';
import { SiteContext } from '../../../context/site-context';

interface Props {
  make: string;
  doClose?(): void;
}

export const ContactUsDialog = ({ doClose }: Props) => {
  const [{ t }] = useContext(TranslationContext);
  const [{ isCanada }] = useContext(SiteContext);
  const rewardsHours = t('REWARDS_HOURS');
  const connectedServicesContact = t('CONNECTED_SERVICES_CONTACT');


  return (
    <ModalDialog title={t('Contact Us')} doClose={doClose} className={styles.modal}>
      <Body>
        <div className={styles.contactUs}>
          <div className={styles.subtext}>{t('CONTACT_SUBHEADER')}</div>
            <div className={styles.rewardsBox}>
              <div className={styles.boxHeader}>{t('Rewards Contact Center')}</div>
              <div className={styles.subtext}>{isCanada() ? t('REWARDS_NUMBER_CAN') : t('REWARDS_NUMBER')}</div>
              <div className={styles.boxHeader}>{t('Hours:')}</div>
              <div className={styles.subtextTimes} dangerouslySetInnerHTML={{ __html: rewardsHours }} />
            </div>
            <div className={styles.connectedServicesBox}>
              <div className={styles.boxHeader}>{t('Connected Services Support')}</div>
              <div className={styles.subtext} dangerouslySetInnerHTML={{ __html: connectedServicesContact }} />
            </div>
          </div>
      </Body>
    </ModalDialog>
  );
};
