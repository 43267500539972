import AuthService from './auth-service';

// NOTE: copied from global-owner
export class AuthenticationService {
  private authService: AuthService;
  private userProfile;

  constructor() {
    this.authService = new AuthService();
    this.userProfile = this.authService.getUserProfile();
  }

  public login = (): void => this.authService.signinSilent();

  public logout = (): void => this.authService.logout();

  public getIsAuthenticated = (): boolean => this.authService.isAuthenticated();

  public getIsDealer = (): boolean => this.authService.isDealer();

  public getIdToken = () => this.authService.getIdToken();

  public getSiteCode = () => this.userProfile?.user_attributes?.fordSiteCode;

  public getDealerUserName = () => this.userProfile?.user_attributes?.unique_name;

  public getUserCountry = () => this.userProfile?.user_attributes?.c || window.sessionStorage.getItem('userCountry') || 'USA';
}
