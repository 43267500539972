import { APIHelper } from '../../models/api-helper';
import { post } from '../generic-service';

export default class DefaultPointsQueryService {
  public async request(data: any, apiHelper: APIHelper) {
    return await post(
      'DEFAULT_POINTS_QUERY',
      null,
      { action: 'points' },
      {
        ...data,
      },
      null,
      apiHelper
    );
  }
}
