import React, { createContext, useState } from 'react';

interface Props {
  children: React.ReactNode;
}

interface FinancingPrefillContextType {
    vin: string | null;
    setVin: React.Dispatch<React.SetStateAction<string | null>>;
    modelYear: string | null;
    setModelYear: React.Dispatch<React.SetStateAction<string | null>>;
    modelName: string | null;
    setModelName: React.Dispatch<React.SetStateAction<string | null>>;
    trim: string | null;
    setTrim: React.Dispatch<React.SetStateAction<string | null>>;
    purchaseDate: Date | null;
    setPurchaseDate: React.Dispatch<React.SetStateAction<Date | null>>;
    firstName: string | null;
    setFirstName: React.Dispatch<React.SetStateAction<string | null>>;
    lastName: string | null;
    setLastName: React.Dispatch<React.SetStateAction<string | null>>;
    email: string | null;
    setEmail: React.Dispatch<React.SetStateAction<string | null>>;
    phoneNumber: string | null;
    setPhoneNumber: React.Dispatch<React.SetStateAction<string | null>>;
    zipCode: string | null;
    setZipCode: React.Dispatch<React.SetStateAction<string | null>>;
    paymentSource: string | null;
    setPaymentSource: React.Dispatch<React.SetStateAction<string | null>>;
    connectedService: string | null;
    setConnectedService: React.Dispatch<React.SetStateAction<string | null>>;
    productDetails: any | null;
    setProductDetails: React.Dispatch<React.SetStateAction<any | null>>;
    ratePlan: string | null;
    setRatePlan: React.Dispatch<React.SetStateAction<string | null>>;
    salePrice: string | null;
    setSalePrice: React.Dispatch<React.SetStateAction<string | null>>;
    price: string | null;
    setPrice: React.Dispatch<React.SetStateAction<string | null>>;
    expiryDate: string | null;
    setExpiryDate: React.Dispatch<React.SetStateAction<string | null>>;
    startDate: string | null;
    setStartDate: React.Dispatch<React.SetStateAction<string | null>>;
    productSku: string | null;
    setProductSku: React.Dispatch<React.SetStateAction<string | null>>;
    ratePlanId: string | null;
    setRatePlanId: React.Dispatch<React.SetStateAction<string | null>>;
    subscriptionType: string | null;
    setSubscriptionType: React.Dispatch<React.SetStateAction<string | null>>;
    dealershipName: string | null;
    setDealershipName: React.Dispatch<React.SetStateAction<string | null>>;
    dealershipPACode: string | null;
    setDealershipPACode: React.Dispatch<React.SetStateAction<string | null>>;
    dealershipState: string | null;
    setDealershipState: React.Dispatch<React.SetStateAction<string | null>>;
    employeeName: string | null;
    setEmployeeName: React.Dispatch<React.SetStateAction<string | null>>;
    employeeTitle: string | null;
    setEmployeeTitle: React.Dispatch<React.SetStateAction<string | null>>;
    employeePhoneNumber: string | null;
    setEmployeePhoneNumber: React.Dispatch<React.SetStateAction<string | null>>;
    employeeEmail: string | null;
    setEmployeeEmail: React.Dispatch<React.SetStateAction<string | null>>;
    customerId: string | null;
    setCustomerId: React.Dispatch<React.SetStateAction<string | null>>;
    customerCity: string | null;
    setCustomerCity: React.Dispatch<React.SetStateAction<string | null>>;
    customerState: string | null;
    setCustomerState: React.Dispatch<React.SetStateAction<string | null>>;
    starsId: string | null;
    setStarsId: React.Dispatch<React.SetStateAction<string | null>>;
}

export const FinancingPrefillContext = createContext<FinancingPrefillContextType | undefined>(undefined);

export const FinancingPrefillContextProvider = ({ children }: Props) => {
    const [vin, setVin] = useState<string | null>(null);
    const [modelYear, setModelYear] = useState<string | null>(null);
    const [modelName, setModelName] = useState<string | null>(null);
    const [trim, setTrim] = useState<any | null>(null);
    const [purchaseDate, setPurchaseDate] = useState<Date | null>(null);
    const [firstName, setFirstName] = useState<string | null>(null);
    const [lastName, setLastName] = useState<string | null>(null);
    const [email, setEmail] = useState<string | null>(null);
    const [phoneNumber, setPhoneNumber] = useState<string | null>(null);
    const [paymentSource, setPaymentSource] = useState<any | null>(null);
    const [zipCode, setZipCode] = useState<string | null>(null);
    const [connectedService, setConnectedService] = useState<any>(null);
    const [productDetails, setProductDetails] = useState<any | null>(null);
    const [ratePlan, setRatePlan] = useState<any | null>(null);
    const [salePrice, setSalePrice] = useState<any | null>(null);
    const [price, setPrice] = useState<any | null>(null)
    const [expiryDate, setExpiryDate] = useState<string | null>(null);
    const [startDate, setStartDate] = useState<string | null>(null);
    const [productSku, setProductSku] = useState<string | null>(null);
    const [ratePlanId, setRatePlanId] = useState<string | null>(null);
    const [subscriptionType, setSubscriptionType] = useState<string | null>(null);
    const [dealershipName, setDealershipName] = useState<string | null>(null);
    const [dealershipPACode, setDealershipPACode] = useState<any | null>(null);
    const [dealershipState, setDealershipState] = useState<string | null>(null);
    const [employeeName, setEmployeeName] = useState<null | any>(null);
    const [employeeTitle, setEmployeeTitle] = useState<null | any>(null);
    const [employeePhoneNumber, setEmployeePhoneNumber] = useState<null | any>(null);
    const [employeeEmail, setEmployeeEmail] = useState<null | any>(null);
    const [customerId, setCustomerId] = useState<null | any>(null);
    const [customerCity, setCustomerCity] = useState<null | any>(null);
    const [customerState, setCustomerState] = useState<null | any>(null);
    const [starsId, setStarsId] = useState<null | any>(null);

  return (
    <FinancingPrefillContext.Provider value={{
        vin, setVin,
        modelYear, setModelYear,
        modelName, setModelName,
        trim, setTrim,
        purchaseDate, setPurchaseDate,
        firstName, setFirstName,
        lastName, setLastName,
        email, setEmail,
        phoneNumber, setPhoneNumber,
        zipCode, setZipCode,
        paymentSource, setPaymentSource,
        productDetails, setProductDetails,
        connectedService, setConnectedService,
        ratePlan, setRatePlan,
        salePrice, setSalePrice,
        price, setPrice,
        expiryDate, setExpiryDate,
        startDate, setStartDate,
        productSku, setProductSku,
        ratePlanId, setRatePlanId,
        subscriptionType, setSubscriptionType,
        dealershipName, setDealershipName,
        dealershipPACode, setDealershipPACode,
        dealershipState, setDealershipState,
        employeeName, setEmployeeName,
        employeeTitle, setEmployeeTitle,
        employeePhoneNumber, setEmployeePhoneNumber,
        employeeEmail, setEmployeeEmail,
        customerId, setCustomerId,
        customerCity, setCustomerCity,
        customerState, setCustomerState,
        starsId, setStarsId
      }
    }>
      {children}
    </FinancingPrefillContext.Provider>
  );
};