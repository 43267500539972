export const IDENTITY_CONFIG = {
  authority: process.env.REACT_APP_AUTH_URL, //(string): The URL of the OIDC provider.
  client_id: process.env.REACT_APP_IDENTITY_CLIENT_ID, //(string): Your client application's identifier as registered with the OIDC provider.
  redirect_uri: process.env.REACT_APP_REDIRECT_URL, //The URI of your client application to receive a response from the OIDC provider.
  login: process.env.REACT_APP_AUTH_URL + '/login',
  automaticSilentRenew: true, //(boolean, default: false): Flag to indicate if there should be an automatic attempt to renew the access token prior to its expiration.
  loadUserInfo: false, //(boolean, default: true): Flag to control if additional identity data is loaded from the user info endpoint in order to populate the user's profile.
  silent_redirect_uri: process.env.REACT_APP_SILENT_REDIRECT_URL, //(string): The URL for the page containing the code handling the silent renew.
  post_logout_redirect_uri: process.env.REACT_APP_LOGOFF_REDIRECT_URL, // (string): The OIDC post-logout redirect URI.
  //audience: 'https://example.com', //is there a way to specific the audience when making the jwt
  response_type: 'code', //(string, default: 'id_token'): The type of response desired from the OIDC provider.
  scope: 'openid allatclaims', //(string, default: 'openid'): The scope being requested from the OIDC provider.
  webAuthResponseType: 'code',
  extraQueryParams: { //your params go here
    resource: process.env.REACT_APP_IDENTITY_RESOURCE_ID,
    grant_type: "authorization_code"
}
};
export const METADATA_OIDC = {
  issuer: process.env.REACT_APP_TOKEN_ISSUER_URL,
  jwks_uri: process.env.REACT_APP_AUTH_URL + '/adfs/discovery/keys',
  authorization_endpoint: process.env.REACT_APP_AUTH_URL + '/adfs/oauth2/authorize',
  token_endpoint: process.env.REACT_APP_TOKEN_ENDPOINT,
  userinfo_endpoint: process.env.REACT_APP_AUTH_URL + '/adfs/userinfo',
  end_session_endpoint: `${process.env.REACT_APP_END_SESSION}?back=${encodeURIComponent(
    `${process.env.REACT_APP_LOGOFF_REDIRECT_URL}`
  )}`,
  check_session_iframe: process.env.REACT_APP_AUTH_URL + '/oauth/checksession',
  revocation_endpoint: process.env.REACT_APP_AUTH_URL + '/oauth/revocation',
  introspection_endpoint: process.env.REACT_APP_AUTH_URL + '/oauth/introspect',
};