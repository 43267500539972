import amplitude from 'amplitude-js';
import React, { useState } from 'react';

export const AlertDialogContext = React.createContext<any>({
  message: '',
});

interface Props {
  children: React.ReactNode;
}
export const AlertDialogContextProvider = ({ children }: Props) => {
  const [showAlert, setShowAlert] = useState(null);
  const _setShowAlert = alert => {
    if (alert?.message) amplitude.getInstance().logEvent('pfs alert', { msg: alert.message.replace(/\d+/g, '#') });
    setShowAlert(alert);
  };

  return <AlertDialogContext.Provider value={[{ showAlert, setShowAlert: _setShowAlert }]}>{children}</AlertDialogContext.Provider>;
};
