import React, { useContext, useEffect, useState } from 'react';
import styles from './dealer-alert-bar.module.scss';
import { FontAwesome } from '../../common/font-awesome/font-awesome';
import DealerAlertService from '../../../services/dealer-alert-service/dealer-alert-service';
import { APIHelperContext } from '../../../context/api-helper-context';

const DEALER_ALERT_SESSSION_FLAG = 'DEALER_ALERT_SESSSION_FLAG';
export const DealerAlertBar = () => {
  const [alert, setAlert] = useState(null);

  const [apiHelper] = useContext(APIHelperContext);

  useEffect(() => {
    if (apiHelper.countryCode) {
      const dealerAlertService = new DealerAlertService();
      dealerAlertService.request(apiHelper).then((payload: any) => {
        if (payload && payload.status != 'error') {
          const json = JSON.parse(payload.json);

          const alert = json?.find(a => payload.uniqueId == a.uniqueID);

          if (alert && (sessionStorage.getItem(DEALER_ALERT_SESSSION_FLAG) !== alert.uniqueID || alert.dontShowX)) {
            if (
              (!alert.startDate || new Date(alert.startDate).getTime() < Date.now()) &&
              (!alert.endDate || new Date(alert.endDate).getTime() > Date.now())
            ) {
              setAlert(alert);
              window.setTimeout(() => (document.getElementById('dealer-alert').style.maxHeight = '500px'), 500);
            }
          }
        }
      });
    }
  }, [apiHelper.countryCode]);

  const hideAlert = () => {
    sessionStorage.setItem(DEALER_ALERT_SESSSION_FLAG, alert.uniqueID);
    setAlert(null);
  };

  return (
    <>
      {alert ? (
        <div id='dealer-alert' className={styles.alertWrapper} data-testid='dealer-alert' style={{ background: alert.background || 'yellow' }}>
          {!alert.dontShowX && (
            <i onClick={hideAlert}>
              <FontAwesome icon={'window-close'} />
            </i>
          )}

          <span style={{ color: alert.color || 'black' }}>
            {alert.iconName && <FontAwesome icon={alert.iconName} />}
            <span dangerouslySetInnerHTML={{ __html: alert.message }} />
            <br />
          </span>
        </div>
      ) : null}
    </>
  );
};
