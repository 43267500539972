import React from 'react';
import styles from './radio-item.module.scss';

interface Props {
  name: string;
  id: string;
  value?: string;
  checked?: boolean;
  children?: React.ReactElement;
}

export function RadioItem({ id, name, value, checked, children }: Props) {
  return (
    <label htmlFor={id}>
      <input
        name={name}
        aria-describedby={id}
        aria-required='true'
        className={styles.radioItem}
        required
        type='radio'
        id={id}
        tabIndex={0}
        defaultChecked={checked}
      />
      {children ? children : <span>{value}</span>}
    </label>
  );
}
