import React, { useEffect, useState, useContext } from 'react';
import ServiceHandler from '../services/service-handler';

export const SiteContext = React.createContext<any>({
  siteCode: null,
  countryCode: null,
});

interface Props {
  children: React.ReactNode;
}

export const SiteContextProvider = ({ children }: Props) => {
  const [siteCode, setSiteCode] = useState(null);
  const [countryCode, setCountryCode] = useState(null);
  const [paCodeDialog, setPaCodeDialog] = useState(false);

  useEffect(() => {
    if (ServiceHandler.AuthenticationService) {
      setCountryCode(ServiceHandler.AuthenticationService.getUserCountry());
      if (ServiceHandler.AuthenticationService.getIsDealer()) setSiteCode(ServiceHandler.AuthenticationService.getSiteCode());
    }
  }, [ServiceHandler.AuthenticationService]);

  const isCanada = () => countryCode === 'CAN';
  const shortCountryCode = () => countryCode.substr(0, 2).toLowerCase();

  return (
    <SiteContext.Provider
      value={[
        {
          siteCode,
          setSiteCode,
          countryCode,
          setCountryCode,
          isCanada,
          paCodeDialog,
          setPaCodeDialog,
          shortCountryCode,
        },
      ]}
    >
      {children}
    </SiteContext.Provider>
  );
};
