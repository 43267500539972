import React, { useContext } from 'react';
import utils from '../../../utils/utils.module.scss';

import { CtaButton } from '../../common/cta/cta';
import { TranslationContext } from '../../../context/translations-context';
import ReportDownloadService from '../../../services/report-download-service/report-download-service';
import { APIHelperContext } from '../../../context/api-helper-context';
import { SiteContext } from '../../../context/site-context';

export const TabReportsVehiclePurchase = () => {
  const [{ t }] = useContext(TranslationContext);
  const [apiHelper] = useContext(APIHelperContext);
  const [{ isCanada }] = useContext(SiteContext);
  const download = () => {
    const reportDownloadService = new ReportDownloadService();
    const country = isCanada() ? 'Ca' : '';
    const filename = `${t('VehiclePurchaseActivity')}_${apiHelper.siteCode}_${apiHelper.language.substr(0, 2)}.xlsx`;
    const filepath = `reports/VehiclePurchaseReports${country}/${filename}`;

    reportDownloadService.downloadReport(filepath, apiHelper, t('REPORT_NOT_FOUND_FOR_YEAR'));
  };

  return (
    <div>
      <div>{t('REPORT_DISCLAIMER')}</div>
      <br />
      <br />
      <CtaButton label={t('Download Report')} className={utils.max300} onClick={download} />
    </div>
  );
};
