import React, { useContext } from 'react';
import { TranslationContext } from '../../../context/translations-context';
import { CtaButton, CtaSecondaryButton } from '../../common/cta/cta';

import { ModalDialog, Body, Buttons } from '../../common/modal-dialog/modal-dialog';
import { ShoppingCartSummaryContent } from './shopping-cart-summary-content';
interface Props {
  data: any;
  onClose?(): void;
  submitTransaction?(): void;
  setUnusedOffers?(value): void;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const f = () => {}; //

export const ShoppingCartSummaryDialog = ({ onClose, submitTransaction, setUnusedOffers = f }: Props) => {
  const [{ t }] = useContext(TranslationContext);

  return (
    <ModalDialog title={t('Rewards Summary')}>
      <Body>
        <ShoppingCartSummaryContent />
      </Body>
      <Buttons>
        <CtaSecondaryButton
          label={t('CANCEL')}
          onClick={() => {
            onClose();
            setUnusedOffers(true);
          }}
        />

        <CtaButton
          onClick={() => {
            onClose();
            setUnusedOffers(true);
            submitTransaction();
          }}
          label={t('Confirm')}
        />
      </Buttons>
    </ModalDialog>
  );
};
