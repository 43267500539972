import React, { useContext, useEffect } from 'react';
import { APIHelperContext } from '../../../context/api-helper-context';
import { CustomerContext } from '../../../context/customer-context';
import { InputsContext, REWARD_CHOICE_DEFAULTED } from '../../../context/inputs-context';
import { TranslationContext } from '../../../context/translations-context';
import DefaultPointsQueryService from '../../../services/default-points-query-service/default-points-query-service';
import { CtaButton } from '../../common/cta/cta';
import { Body, Buttons, ModalDialog } from '../../common/modal-dialog/modal-dialog';
import { REWARD_CHOICE } from '../../sections/choice-purchase-reward/choice-purchase-reward';

export const SwitchToPmpDialog = ({ showModal, close }) => {
  const [{ t, language }] = useContext(TranslationContext);
  const [apiHelper] = useContext(APIHelperContext);
  const [{ availablePoints }] = useContext(CustomerContext);
  const [{ vin, rewardChoiceDefaultPoints }, { setRewardChoice, setRewardChoiceDefault, setRewardChoiceDefaultPoints }, { validateVin }] =
    useContext(InputsContext);
  const [{ userProfile, rewardProgram }] = useContext(CustomerContext);

  useEffect(() => {
    setRewardChoiceDefault(REWARD_CHOICE_DEFAULTED.UNKNOWN);
    if (vin.length == 17 && !validateVin()) {
      setRewardChoiceDefault(REWARD_CHOICE_DEFAULTED.LOADING);
      const queryService = new DefaultPointsQueryService();
      queryService.request({ vin, rewardProgram, userGuid: userProfile.lighthouseGuid }, apiHelper).then((response: any) => {
        let result = REWARD_CHOICE_DEFAULTED.BLANK;

        if (response.transactions?.length) {
          const lastTrans = response.transactions.sort((a, b) => b.date - a.date)[0];
          //const lastTrans = { currentRewardChoice: 'pmp' };
          if (lastTrans.currentRewardChoice === 'default_points') {
            setRewardChoiceDefaultPoints(lastTrans.pointData.find(t => (t.category = rewardProgram))?.points);
            result = REWARD_CHOICE_DEFAULTED.DEFAULTPOINTS;
          }
          if (lastTrans.currentRewardChoice === 'points') {
            result = REWARD_CHOICE_DEFAULTED.SETBYDEALER;
            setRewardChoice(REWARD_CHOICE.POINTS);
          }
          if (lastTrans.currentRewardChoice === 'pmp') {
            result = REWARD_CHOICE_DEFAULTED.SETBYDEALER;
            setRewardChoice(REWARD_CHOICE.PMP);
          }
        }

        setRewardChoiceDefault(result);
      });
    }
  }, [vin]);

  return (
    <>
      {showModal && (
        <ModalDialog title={t('Switch to Discounted Maintenance Plan') + '?'} width='65vw'>
          <Body>
            {t('PMP_DEFAULT').replace('{}', rewardChoiceDefaultPoints.toLocaleString(language, { style: 'decimal' }))}
            <br />
            <br />
            {+rewardChoiceDefaultPoints <= availablePoints() ? (
              <>{t('PMP_DEDUCT_ALL').replace('{}', rewardChoiceDefaultPoints.toLocaleString(language, { style: 'decimal' }))}</>
            ) : (
              <>
                {t('PMP_DEDUCT_SOME')
                  .replace('{0}', (rewardChoiceDefaultPoints - availablePoints()).toLocaleString(language, { style: 'decimal' }))
                  .replace('{1}', availablePoints().toLocaleString(language, { style: 'decimal' }))}
              </>
            )}
            <br />
            <br />
            {t('PMP_HOW_TO_PROCEED')}
            <br />
            <br />
          </Body>
          <Buttons>
            <CtaButton label={t('Leave Points in account')} onClick={close} />
            <CtaButton
              label={t('Switch to Discounted Maintenance Plan')}
              onClick={() => {
                setRewardChoice(REWARD_CHOICE.PMP);
                setRewardChoiceDefault(REWARD_CHOICE_DEFAULTED.SWITCH_PMP);
                close();
              }}
            />
          </Buttons>
        </ModalDialog>
      )}
    </>
  );
};
