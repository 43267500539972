import React, { useContext } from 'react';
import { currencyToFloat } from '../components/common/currency/currency';
import { CustomerContext } from '../context/customer-context';
import { ShoppingCartContext } from '../context/shopping-cart-context';

export const SubmitInvoiceContext = React.createContext<any>({
  message: '',
});

interface Props {
  children: React.ReactNode;
}
export const SubmitInvoiceContextProvider = ({ children }: Props) => {
  const [{ redeemPoints, discount, selectedOffers, selectedRewards }, { clearCart }] = useContext(ShoppingCartContext);
  const [{ userProfile, rewardProgram, points }, { doSearch, setPoints }] = useContext(CustomerContext);

  const doSubmit = (submitTransaction: any) => {
    const formdata = new FormData();

    formdata.append('memberId', userProfile.memberId);

    if (redeemPoints > 0) formdata.append('pointsToRedeem', redeemPoints.toString());
    formdata.append('discount', (+discount).toFixed(2));

    let offerIndex = 0;
    let rewardIndex = 0;
    selectedOffers.forEach(o => {
      if (o.cpnDiscountAmount) {
        formdata.append(`offers[${offerIndex}].barcode`, o.barcode);
        formdata.append(`offers[${offerIndex}].couponName`, o.type);
        formdata.append(`offers[${offerIndex}].cpnDiscountAmount`, o.memberDiscount);
        ++offerIndex;
      } else {
        formdata.append(`rewardOrder.rewards[${rewardIndex}].rewardCode`, o.code);
        formdata.append(`rewardOrder.rewards[${rewardIndex}].rewardName`, o.rewardName);
        formdata.append(`rewardOrder.rewards[${rewardIndex}].pricePlanCode`, o.pricePlan?.[0].code);
        formdata.append(`rewardOrder.rewards[${rewardIndex}].quantity`, '1');
        formdata.append(`rewardOrder.rewards[${rewardIndex}].redeemedPoints`, o.pricePlan?.[0].points);
        formdata.append(`rewardOrder.rewards[${rewardIndex}].discountAmount`, o.memberDiscount.toFixed(2));
        ++rewardIndex;
      }
    });

    const onSuccess = pointBalance => {
      if (pointBalance.length) {
        points.pointsTotals[rewardProgram].points =
          (pointBalance.find(p => p.pointsCategory == rewardProgram)?.points || 0) +
          pointBalance.reduce((acc, p) => acc + (p.pointsCategory == 'D' ? p.points : 0), 0);
        setPoints(points);
      }
    };

    submitTransaction(formdata, onSuccess);
  };
  return <SubmitInvoiceContext.Provider value={[{ doSubmit }]}>{children}</SubmitInvoiceContext.Provider>;
};
