// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RD4fkHyTnDKe4G6NefGG{box-shadow:0 4px 12px -4px gray;background:#ff0;text-align:center;border-top:solid 1px #ccc;max-height:0px;overflow-y:clip;transition:max-height ease-in-out 2s}.RD4fkHyTnDKe4G6NefGG>span{padding:25px 10vw 25px 10vw;display:inline-block;text-align:left}.RD4fkHyTnDKe4G6NefGG>span i{text-indent:-35px;transform:scale(2)}.RD4fkHyTnDKe4G6NefGG>i{float:right;color:#666;padding:10px;cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/components/sections/dealer-alert-bar/dealer-alert-bar.module.scss"],"names":[],"mappings":"AACA,sBACE,+BAAA,CACA,eAAA,CACA,iBAAA,CACA,yBAAA,CACA,cAAA,CAEA,eAAA,CACA,oCAAA,CAEA,2BACE,2BAAA,CAEA,oBAAA,CACA,eAAA,CACA,6BACE,iBAAA,CACA,kBAAA,CAIJ,wBACE,WAAA,CACA,UAAA,CACA,YAAA,CACA,cAAA","sourcesContent":["@import \"main.scss\";\n.alertWrapper {\n  box-shadow: 0 4px 12px -4px grey;\n  background: yellow;\n  text-align: center;\n  border-top: solid 1px #ccc;\n  max-height: 0px;\n\n  overflow-y: clip;\n  transition: max-height ease-in-out 2s;\n\n  > span {\n    padding: 25px 10vw 25px 10vw;\n\n    display: inline-block;\n    text-align: left;\n    i {\n      text-indent: -35px;\n      transform: scale(2);\n    }\n  }\n\n  & > i {\n    float: right;\n    color: #666;\n    padding: 10px;\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alertWrapper": `RD4fkHyTnDKe4G6NefGG`
};
export default ___CSS_LOADER_EXPORT___;
