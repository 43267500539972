// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SqhH5ayGqy6RrrtcDekw{font-size:16px;line-height:24px;padding-bottom:16px}.Tldc_3oF6zkDbka6HuqK{display:grid;grid-template-columns:4fr 1fr 4fr;grid-gap:30px}.MPW4rG_oWotEWkgqe1zy{height:50px}.QFLidcj6VDLzVCXUbsz8{font-size:12px;line-height:16px;margin-top:-10px;color:#4d5b6d}.HWfoApXokWNzAWyo0_O6{padding:16px;font-family:FordAntennaBold,sans-serif;text-align:center;font-weight:800;font-size:25px}.XijFqcV5ddgcnpPlMGZp{text-align:center}`, "",{"version":3,"sources":["webpack://./src/components/dialogs/auth-modem/auth-modem.module.scss","webpack://./src/styles/theme/_fonts.scss"],"names":[],"mappings":"AACA,sBACE,cAAA,CACA,gBAAA,CACA,mBAAA,CAGF,sBACE,YAAA,CACA,iCAAA,CACA,aAAA,CAGF,sBACE,WAAA,CAGF,sBACE,cAAA,CACA,gBAAA,CACA,gBAAA,CACA,aAAA,CAGF,sBACE,YAAA,CACA,sCCtBgB,CDuBhB,iBAAA,CACA,eAAA,CACA,cAAA,CAGF,sBACE,iBAAA","sourcesContent":["@import \"main.scss\";\n.subtext {\n  font-size: 16px;\n  line-height: 24px;\n  padding-bottom: 16px;\n}\n\n.smsContainer {\n  display: grid;\n  grid-template-columns: 4fr 1fr 4fr;\n  grid-gap: 30px;\n}\n\n.smsBtn {\n  height: 50px;\n}\n\n.disclaimer {\n  font-size: 12px;\n  line-height: 16px;\n  margin-top: -10px;\n  color: #4D5B6D;\n}\n\n.or {\n  padding: 16px;\n  font-family: $theme-font-bold;\n  text-align: center;\n  font-weight: 800;\n  font-size: 25px;\n}\n\n.QR {\n  text-align: center;\n}","$theme-font: FordAntenna, sans-serif;\n$theme-font-reg-italic: FordAntennaItalic, sans-serif;\n$theme-font-light: FordAntennaLight, sans-serif;\n$theme-font-medium: FordAntennaMedium, sans-serif;\n$theme-font-bold: FordAntennaBold, sans-serif;\n$theme-font-extra-light: FordAntennaExtraLight, sans-serif;\n$theme-font-condensed-regular: FordAntennaCondensedRegular, sans-serif;\n$theme-font-condensed-bold: FordAntennaCondensedBold, sans-serif;\n$theme-font-condensed-medium: FordAntennaCondensedMedium, sans-serif;\n$theme-font-condensed-light: FordAntennaCondensedLight, sans-serif;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subtext": `SqhH5ayGqy6RrrtcDekw`,
	"smsContainer": `Tldc_3oF6zkDbka6HuqK`,
	"smsBtn": `MPW4rG_oWotEWkgqe1zy`,
	"disclaimer": `QFLidcj6VDLzVCXUbsz8`,
	"or": `HWfoApXokWNzAWyo0_O6`,
	"QR": `XijFqcV5ddgcnpPlMGZp`
};
export default ___CSS_LOADER_EXPORT___;
