import React, { useContext } from 'react';
import { CustomerContext } from '../../../context/customer-context';
import { InputsContext } from '../../../context/inputs-context';
import { ShoppingCartContext } from '../../../context/shopping-cart-context';
import { TranslationContext } from '../../../context/translations-context';
import utils from '../../../utils/utils.module.scss';

import { Currency } from '../../common/currency/currency';
import styles from './shopping-cart-summary.module.scss';

export const ShoppingCartSummaryContent = () => {
  const [{ t, formatDate, formatCurrency }] = useContext(TranslationContext);

  const [{ userProfile }] = useContext(CustomerContext);
  const [{ redeemPoints, discount, selectedOffers, selectedRewards, salesTab }] = useContext(ShoppingCartContext);
  const [{ rewardChoice, saleType, vin, invoiceNumber, invoiceAmount, saleDate }] = useContext(InputsContext);

  return (
    <>
      {userProfile && (
        <div className={styles.container}>
          <div>
            <b>Member: </b>
            {userProfile.firstName} {userProfile.lastName} ({userProfile?.memberId})
          </div>

          <div>
            <b>{salesTab ? t('Vehicle Deal Number') : t('RO Number')}: </b>
            {invoiceNumber}

            {salesTab && (
              <span style={{ marginLeft: '30px' }}>
                <b>VIN: </b> {vin}
              </span>
            )}
            {!salesTab && (
              <span style={{ marginLeft: '30px' }}>
                <b>Invoice Amount: </b> {formatCurrency(invoiceAmount)}
              </span>
            )}
          </div>

          <div>
            <b>{salesTab ? 'Sale Date' : 'Service Date'}: </b>
            {saleDate && formatDate(saleDate)}
          </div>

          <div>{t('SHOPPING_DIALOG')}</div>

          <ul className={utils.margin20}>
            {salesTab && (
              <>
                <li>
                  {t('Reward Choice')}: {rewardChoice == 'points' ? t('SALE_AWARD') : t('Discounted Maintenance Plan')}
                </li>
                <li>
                  {t('Sale Type')}
                  {': '}
                  <>
                    {saleType == 'VehicleNew' && t('New')}
                    {saleType == 'VehicleCPO' && t('Ford Blue Advantage - Gold')}
                    {saleType == 'VehicleCPOLite' && t('Ford Blue Advantage - Blue')}
                  </>
                </li>
              </>
            )}

            {redeemPoints > 0 && (
              <li>
                {t('Redeem')} {redeemPoints} {t('Points')}
              </li>
            )}
            {selectedOffers.map((offer: any) => (
              <li key={offer.barcode}>
                {offer.couponTypeName}
                : <Currency data={offer.memberDiscount} />
              </li>
            ))}
          </ul>
          {+discount > 0 && (
            <>
              <b>{t('Total Discount')}</b>
              {': '}
              <Currency data={discount} />
            </>
          )}
          <br />
          <br />
        </div>
      )}
    </>
  );
};
