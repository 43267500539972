import React, { useContext } from 'react';
import utils from '../../utils/utils.module.scss';
import cx from '../../utils/classnames';
import { TranslationContext } from '../../context/translations-context';
import { AuthContext } from '../../context/auth-context';
import amplitude from 'amplitude-js';

export const LogoutCallback = props => {
  const [{ t }] = useContext(TranslationContext);

  const { authService } = useContext(AuthContext);

  amplitude.getInstance().logEvent('pfs LogoutCallback', { props });
  authService.signoutRedirectCallback();
  return (
    <div className={cx(utils.centerCenter, utils.windowHeight, utils.textPrimary)}>
      <h2>{t('You have successfully logged out of the system.')}</h2>
      <h3>
        {t('Click')} <a href='/'>{t('here')}</a> {t('to login again.')}
      </h3>
    </div>
  );
};
